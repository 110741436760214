import React from 'react';
import { Url } from '@wix/da-url';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';
import useIsCurrentUserProfileOwner from '../../../../../utils/useIsCurrentUserProfileOwner';
import { ShopItemType } from '../../../../../../types/shop';
import UploadMore from '../UploadMore';
import { navigate } from './helpers';

export interface Props {
  itemType: ShopItemType;
  biClickInfo: string;
  view?: 'large' | 'small';
}

export const UploadMoreSubmitButton: React.FC<Props> = ({
  itemType,
  biClickInfo,
  view = 'large',
}) => {
  const isOwner = useIsCurrentUserProfileOwner();
  if (!isOwner) {
    return null;
  }

  return (
    <UploadMore
      itemType={itemType}
      onCreate={() => navigate(Url.studio({ openSubmitForm: true }))}
      biData={BiData<DefaultBiEvent>({
        evid: 2,
        click_info: biClickInfo,
      })}
      view={view}
    />
  );
};
UploadMoreSubmitButton.displayName = 'UploadMoreSubmitButton';

export default UploadMoreSubmitButton;
