import { SagaIterator } from 'redux-saga';
import { isApiError, requestPuppy } from '@wix/da-http-client';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  setMySubmissionsSortBy,
  setMySubmissionsIsLoading,
  removeBenchmarkUser,
  addBenchmarkUser,
  sendQuery,
  resultUserQuery,
  addBenchmarkUserSuccess,
  fetchRecentVisitors,
  fetchRecentVisitorsSuccess,
} from '../actions/statsSection';
import { getProfileGruser, getProfileOwnerUser } from '../selectors/users';
import { MySubmissionsSortBy, StatsTimePeriod } from '../../types/statsSection';
import { requestPuppyGruser } from './gruserHelpers';
import { getModuleByModuleName } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';

function* fetchFromApi(requestUrl, params) {
  return yield call(
    requestPuppy,
    {
      method: 'get',
      url: requestUrl,
      params,
    },
    undefined,
    'dauserprofile'
  );
}

function* updateBenchmarkUsers(data, type: 'add' | 'remove') {
  return yield call(
    requestPuppy,
    {
      method: 'post',
      url: `/stats/comparelist/${type}`,
      data,
    },
    undefined,
    'dauserprofile'
  );
}

export function* removeBenchmarkUserSaga(
  action: ReturnType<typeof removeBenchmarkUser>
) {
  const userId = action.payload;
  yield call(
    updateBenchmarkUsers,
    {
      userid: userId,
    },
    'remove'
  );

  const gruser = yield select(getProfileGruser);
  const module = yield select(state =>
    getModuleByModuleName(state, gruser, 'userstatsDetailed')
  );
  if (!module) {
    return;
  }
  const {
    id: moduleId,
    moduleData: { userstatsDetailed: moduleData },
  } = module;

  const newModuleData = { ...moduleData };
  ['P7D', 'P28D', 'P365D'].forEach(timePeriod => {
    newModuleData.compareYourself.timePeriods[timePeriod] =
      moduleData.compareYourself.timePeriods[timePeriod].filter(
        entry => entry.user.userId !== userId
      );
  });

  yield put(
    updateModule(gruser, {
      id: moduleId,
      moduleData: newModuleData,
    })
  );
}

export function* addBenchmarkUserSaga(
  action: ReturnType<typeof addBenchmarkUser>
) {
  const userId = action.payload;

  const result = yield call(
    updateBenchmarkUsers,
    {
      userid: userId,
    },
    'add'
  );

  const gruser = yield select(getProfileGruser);
  const module = yield select(state =>
    getModuleByModuleName(state, gruser, 'userstatsDetailed')
  );
  if (!module) {
    return;
  }
  const {
    id: moduleId,
    moduleData: { userstatsDetailed: moduleData },
  } = module;

  if (result.success) {
    const newTimePeriods = {};

    // Merge existing and added data
    Object.values(StatsTimePeriod).forEach(period => {
      const existingData = moduleData.compareYourself.timePeriods[period];
      const addedData = result.compareYourself.timePeriods[period];

      if (existingData && addedData) {
        newTimePeriods[period] = existingData.concat(addedData);
      }
    });

    const newModuleData = {
      ...moduleData,
      compareYourself: {
        ...moduleData.compareYourself,
        timePeriods: newTimePeriods,
      },
    };

    yield put(
      updateModule(gruser, {
        id: moduleId,
        moduleData: newModuleData,
      })
    );
    yield put(addBenchmarkUserSuccess());
  }
}

function* fetchFriendsForLetter(search) {
  const result = yield call(requestPuppy, {
    method: 'get',
    url: `/user/friendsforletter`,
    params: {
      search,
      letter: search[0],
      user_type: 'deviant',
      page: 0,
    },
  });
  return result;
}

function* mergeIntoUserstatsDetailedModule(dataToMerge): SagaIterator {
  const gruser = yield select(getProfileGruser);
  const module = yield select(state =>
    getModuleByModuleName(state, gruser, 'userstatsDetailed')
  );
  if (!module) {
    return;
  }
  const {
    id: moduleId,
    moduleData: { userstatsDetailed: moduleData },
  } = module;

  const newModuleData = { ...moduleData, ...dataToMerge };

  yield put(
    updateModule(gruser, {
      id: moduleId,
      moduleData: newModuleData,
    })
  );
}

export function* setMySubmissionsSortBySaga(
  action: ReturnType<typeof setMySubmissionsSortBy>
): SagaIterator {
  const { payload: sortBy } = action;
  const profileOwnerUser = yield select(getProfileOwnerUser);

  yield put(setMySubmissionsIsLoading(true));

  const sortByToSnakeCaseMap = {
    [MySubmissionsSortBy.PublishDate]: 'publish_date',
    [MySubmissionsSortBy.PageViews]: 'page_views',
    [MySubmissionsSortBy.Faves]: 'faves',
    [MySubmissionsSortBy.Comments]: 'comments',
  };

  const params = {
    username: profileOwnerUser.username,
    sort_by: sortByToSnakeCaseMap[sortBy],
  };

  const result = yield call(fetchFromApi, '/stats/my_content', params);

  if (result && !isApiError(result)) {
    const { myContent } = result;

    yield call(mergeIntoUserstatsDetailedModule, { myContent });
    yield put(setMySubmissionsIsLoading(false));
  }
}

function* getUserstatsDetailedModule() {
  const gruser = yield select(getProfileGruser);
  const module = yield select(state =>
    getModuleByModuleName(state, gruser, 'userstatsDetailed')
  );

  if (!module) {
    return;
  }

  const {
    id: moduleId,
    moduleData: { userstatsDetailed: moduleData },
  } = module;

  return { gruser, moduleId, moduleData };
}

export function* fetchRecentVisitorsSaga(
  action: ReturnType<typeof fetchRecentVisitors>
) {
  const offset = action.payload;
  const result = yield call(
    requestPuppyGruser,
    {
      method: 'get',
      url: '/module/visitors',
      params: { offset },
    },
    undefined,
    'gruser'
  );

  if (result && !isApiError(result)) {
    const moduleInfo = yield call(getUserstatsDetailedModule);

    if (!moduleInfo) {
      return;
    }
    const { gruser, moduleId, moduleData } = moduleInfo;

    const timePeriods = Object.values(StatsTimePeriod);

    const newTimePeriods = {};

    // Merge existing and added data
    timePeriods.forEach(period => {
      const existingData = moduleData.recentVisitors.timePeriods[period];
      const addedData = result.timePeriods[period];

      if (existingData && addedData) {
        newTimePeriods[period] = {
          hasMore: addedData.hasMore,
          nextOffset: addedData.nextOffset,
          results: existingData.results.concat(addedData.results),
        };
      }
    });

    const newModuleData = {
      ...moduleData,
      recentVisitors: {
        ...moduleData.recentVisitors,
        timePeriods: newTimePeriods,
      },
    };

    yield put(
      updateModule(gruser, {
        id: moduleId,
        moduleData: newModuleData,
      })
    );
    yield put(fetchRecentVisitorsSuccess(result));
  }
}

export function* sendUserQuery(action: ReturnType<typeof sendQuery>) {
  const query = action.payload;

  if (query.length < 3) {
    yield put(resultUserQuery([]));
    return;
  }

  const result = yield call(fetchFriendsForLetter, query);

  if (result.error || !result.users || result.users.length === 0) {
    return;
  }

  const regexp = new RegExp(`^${query}`, 'i');
  const foundUsers = result.users.filter(user => user.username.match(regexp));

  yield put(
    resultUserQuery(
      // unique
      foundUsers.filter(
        (user, index) =>
          foundUsers.findIndex(u => u.userId === user.userId) === index
      )
    )
  );
}

export default function* statsSectionSaga() {
  yield all([
    takeEvery(setMySubmissionsSortBy, setMySubmissionsSortBySaga),
    takeEvery(removeBenchmarkUser, removeBenchmarkUserSaga),
    takeEvery(addBenchmarkUser, addBenchmarkUserSaga),
    takeEvery(fetchRecentVisitors, fetchRecentVisitorsSaga),
    takeLatest(sendQuery, sendUserQuery),
  ]);
}
