import { connect, MapStateToProps } from 'react-redux';
import { Props } from './GallectionSimpleView';
import { AppState } from '../../../../../types/store';
import {
  getSelectedFolder,
  getShouldHideFolderSlider,
} from '../../../../selectors/gallectionSection';

export type StateProps = Pick<Props, 'currentFolder' | 'hideFolderSlider'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  return {
    currentFolder: getSelectedFolder(state),
    hideFolderSlider: getShouldHideFolderSlider(state),
  };
};

export default connect(mapStateToProps);
