export enum ReportStep {
  StepOne = 'StepOne',
  StepTwo = 'StepTwo',
  StepThree = 'StepThree',
}

export interface ReportReason {
  id: string;
  label: string;
  instructions?: string;
  note?: string;
}

// keep in sync with https://phrac.deviantart.net/source/web/browse/master/vms/lib/admin/moderation/v./profile.php$55
export const REPORT_REASONS: ReportReason[] = [
  {
    id: '1',
    label: 'report.reason.harassment',
    instructions: 'report.reason.harassment.instructions',
    note: 'report.reason.harassment.note',
  },
  {
    id: '2',
    label: 'report.reason.spamming',
    instructions: 'report.reason.spamming.instructions',
    note: 'report.reason.spamming.note',
  },
  {
    id: '3',
    label: 'report.reason.ban_evasion',
    instructions: 'report.reason.ban_evasion.instructions',
  },
  {
    id: '4',
    label: 'report.reason.inappropriate_content',
    instructions: 'report.reason.inappropriate_content.instructions',
    note: 'report.reason.inappropriate_content.note',
  },
  {
    id: '5',
    label: 'report.reason.inappropriate_image',
    instructions: 'report.reason.inappropriate_image.instructions',
  },
  {
    id: '6',
    label: 'report.reason.compromised_account',
    instructions: 'report.reason.compromised_account.instructions',
  },
  {
    id: '7',
    label: 'report.reason.impersonation',
    instructions: 'report.reason.impersonation.instructions',
  },
  {
    id: '8',
    label: 'report.reason.underage',
    instructions: 'report.reason.underage.instructions',
  },
];
