import React from 'react';
import { withRouter } from 'react-router-dom';
import Sticky from '@wix/da-ds/pkg/Sticky';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import SubNavBarMobileInner from './SubNavBarMobileInner';
import { SubNavBarProps } from '../SubNavBar';

import cssVars from '@wix/da-ds/src/styles/variables.scss';
import s from './SubNavBarMobile.scss';

const SubNavBarMobile: React.FC<SubNavBarProps> = ({
  routes,
  biClickProfileNavBar,
}) => {
  const stickyHeight = parseInt(cssVars['site-header-height-mobile']);

  return (
    <div className={s['root']} id="nav">
      <ErrorBoundary debugComponent="SubNavBarMobile">
        <div className={s['sticky-placeholder']}>
          <Sticky zIndex={parseInt(s['z-index'])} marginTop={stickyHeight}>
            {isSticky => (
              <SubNavBarMobileInner
                routes={routes}
                isSticky={isSticky}
                biClickProfileNavBar={biClickProfileNavBar}
              />
            )}
          </Sticky>
        </div>
      </ErrorBoundary>
    </div>
  );
};

SubNavBarMobile.displayName = 'SubNavBarMobile';
export default withRouter(SubNavBarMobile);
