import React, { useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import withSectionFetchSpinner from '../../../../decorators/withSectionFetchSpinner';
import { openCommissionEditModal } from '@wix/da-shared-react/pkg/redux/commissions/actionCreators';
import { useStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { getShopItemsStreamId } from '../../../../streams/shop';
import { ShopItemType } from '../../../../../types/shop';
import EmptyState from '../_partials/EmptyState';
import ShopGrid from '../ShopGrid';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';
import ShopCommissionsControls from './ShopCommissionsControls';
import UploadMore from '../_partials/UploadMore';
import MoneyDeviationPublishedModal from '../../_partials/MoneyDeviationPublishedModal';
import s from '../ShopSection.scss';

export interface Props {
  className?: string;
  isOwner: boolean;
  isOpenForCommissions: boolean;
  onCreate: () => void;
}

const ShopCommissions: React.FC<Props> = ({
  className,
  isOpenForCommissions,
  isOwner,
  onCreate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const streamId = getShopItemsStreamId(ShopItemType.COMMISSIONS);
  const { total, isInitialized } = useStream(streamId);
  const isEmpty = isInitialized && total === 0;

  const isMobile = useContext(MobileContext);
  const [showSuccessModal, setShowSuccessModal] = useState(() => {
    if (typeof window === 'undefined') {
      return false;
    }
    return !!new URLSearchParams(window.location.search).get(
      'commission-publish-success'
    );
  });

  const dismissSuccessModal = useCallback(() => setShowSuccessModal(false), []);
  const openCommissionEditModalFromEmptyState = () => {
    dispatch(openCommissionEditModal('commission_emptystate'));
  };

  if (isEmpty && isOwner) {
    return (
      <EmptyState
        view="wide"
        shopItemType={ShopItemType.COMMISSIONS}
        buttonLabel={t('pages.shop.emptyState.commissions.createCTA')}
        onButtonClick={openCommissionEditModalFromEmptyState}
        biData={BiData<DefaultBiEvent>({
          evid: 2,
          click_info: 'create_commission',
          sectionname: 'commission',
        })}
      />
    );
  }

  return (
    <BiLoggerContextProvider value={{ sectionname: 'Commissions' }}>
      <div className={s['section-with-no-header']}>
        {showSuccessModal && (
          <MoneyDeviationPublishedModal
            type="commission"
            onClose={dismissSuccessModal}
          />
        )}
        <ShopCommissionsControls />
        {(isOpenForCommissions || (isOwner && !isMobile)) && (
          <ShopGrid
            className={className}
            itemType={ShopItemType.COMMISSIONS}
            emptyState={false}
            uploadMore={
              <UploadMore
                itemType={ShopItemType.COMMISSIONS}
                onCreate={onCreate}
                view="small"
                biData={BiData<DefaultBiEvent>({
                  evid: 2,
                  click_info: 'create_commission',
                  sectionname: 'commission',
                })}
              />
            }
          />
        )}
      </div>
    </BiLoggerContextProvider>
  );
};
ShopCommissions.displayName = 'ShopCommissions';

export default withSectionFetchSpinner(
  ShopCommissions
) as React.ComponentType<Props>;
