import { useCurrentUrl } from '@wix/da-react-app/pkg/client/Environment/redux/hooks';
import { Url, USER_PROFILE_SHOP_SUB_PATHS } from '@wix/da-url';

/** links to a shop subsection, adding a devationid if any and the #content hash fragment */
export function useShopContentUrl(
  profileUsername: string,
  subPath: USER_PROFILE_SHOP_SUB_PATHS
): string {
  // userShopPath returns a relative url so we can't use the URL API well (needs host)
  const contentUrl = Url.userShopPath(profileUsername, subPath);

  const currentUrl = useCurrentUrl();
  if (!currentUrl) {
    return `${contentUrl}#content`;
  }

  let transferedSearch = '';
  try {
    const currentParams = new URL(currentUrl).searchParams;
    const deviationidParam = currentParams.get('deviationid');
    if (deviationidParam) {
      transferedSearch = `?deviationid=${deviationidParam}`;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(
      `useShopContentUrl, error parsing url '${currentUrl}':\n${err}`
    );
  }
  return `${contentUrl}${transferedSearch}#content`;
}
