import React, { useMemo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { Url, USER_PROFILE_BACKROOM_SECTIONS } from '@wix/da-url';
import { PapiUser } from '@wix/da-papi-types';
import IconArrowDown from '@wix/da-ds/pkg/Icons/24x24/ArrowDown';
import IconEdit from '@wix/da-ds/pkg/Icons/24x24/Edit';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';

import IconButton from '@wix/da-ds/pkg/Buttons/IconButton';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import TextButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/TextButtonWithContextMenu';

import { GroupNotesFolderType } from '../../../../../../types/backroomSection';

import GroupNotesSearch from '../GroupNotesSearch';

import s from './GroupNotesToolbar.scss';

export interface Props {
  className?: string;
  profileOwnerUser: PapiUser;

  allNoteIds: number[];
  selectedNoteIds: number[];
  setSelectedNoteIds: (noteIds: number[]) => void;

  search: string;
  setSearch: (search: string) => void;

  folder?: string;
  archiveNotes: (noteIds: number[]) => void;
  unarchiveNotes: (noteIds: number[]) => void;
  messageAdmins: (group: PapiUser) => void;
}

const GroupNotesToolbar: React.FC<Props> = ({
  className,
  profileOwnerUser,

  allNoteIds,
  selectedNoteIds,
  setSelectedNoteIds,

  search,
  setSearch,

  folder,
  archiveNotes,
  unarchiveNotes,
  messageAdmins,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useContext(MobileContext);

  const nothingIsSelected = selectedNoteIds.length === 0;

  const selectNone = useCallback(
    () => setSelectedNoteIds([]),
    [setSelectedNoteIds]
  );

  const selectAll = useCallback(
    () => setSelectedNoteIds([...allNoteIds]),
    [allNoteIds, setSelectedNoteIds]
  );

  const selectInvert = useCallback(
    () =>
      setSelectedNoteIds(
        allNoteIds.filter(noteId => !selectedNoteIds.includes(noteId))
      ),
    [selectedNoteIds, setSelectedNoteIds, allNoteIds]
  );

  const archiveSelected = useCallback(
    () => archiveNotes(selectedNoteIds),
    [selectedNoteIds, archiveNotes]
  );

  const unarchiveSelected = useCallback(
    () => unarchiveNotes(selectedNoteIds),
    [selectedNoteIds, unarchiveNotes]
  );

  const items = useMemo(
    () => [
      {
        value: 'select-all',
        label: t('widgets.group_notes.select.item.all'),
        onClick: selectAll,
      },
      {
        value: 'select-none',
        label: t('widgets.group_notes.select.item.none'),
        onClick: selectNone,
      },
      {
        value: 'select-invert',
        label: t('widgets.group_notes.select.item.invert'),
        onClick: selectInvert,
      },
    ],
    [t, selectAll, selectNone, selectInvert]
  );

  const mobileNavigationItems = useMemo(
    () =>
      [
        GroupNotesFolderType.INBOX,
        GroupNotesFolderType.STARRED,
        GroupNotesFolderType.ARCHIVED,
      ].map(key => ({
        value: key,
        selected: folder === key,
        label: t(`widgets.group_notes.sidebar.items.var.${key}`),
        onClick: () =>
          history.push(
            Url.groupBackroomPath(
              profileOwnerUser.username,
              USER_PROFILE_BACKROOM_SECTIONS.NOTES,
              key as string
            )
          ),
      })),
    [t, profileOwnerUser, history, folder]
  );

  return (
    <div className={classnames(s['root'], className)}>
      <div className={s['left']}>
        {isMobile && (
          <div className={s['btn-group']}>
            <TextButtonWithContextMenu
              items={mobileNavigationItems}
              size="large"
              variant={'main'}
              postfix={IconArrowDown}
            >
              {t(`widgets.group_notes.sidebar.items.var.${folder}`)}
            </TextButtonWithContextMenu>
          </div>
        )}
        {!isMobile && (
          <div className={s['btn-group']}>
            <TextButtonWithContextMenu
              items={items}
              size="large"
              variant={'main'}
              postfix={IconArrowDown}
            >
              {t('widgets.group_notes.btn.select')}
            </TextButtonWithContextMenu>
            <TextButton
              size={'large'}
              variant={'main'}
              onClick={
                folder === GroupNotesFolderType.ARCHIVED
                  ? unarchiveSelected
                  : archiveSelected
              }
              disabled={nothingIsSelected}
            >
              {folder === GroupNotesFolderType.ARCHIVED
                ? t('widgets.group_notes.btn.unarchive')
                : t('widgets.group_notes.btn.archive')}
            </TextButton>
          </div>
        )}
      </div>
      <div className={s['right']}>
        <GroupNotesSearch
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </div>
      <div className={s['right']}>
        {isMobile && (
          <IconButton
            icon={IconEdit}
            size={'large'}
            aria-label={t('widgets.group_notes.btn.newNote')}
            onClick={() => messageAdmins(profileOwnerUser)}
          />
        )}
        {!isMobile && (
          <GhostButton
            size={'large'}
            variant={'main'}
            onClick={() => messageAdmins(profileOwnerUser)}
          >
            {t('widgets.group_notes.btn.newNote')}
          </GhostButton>
        )}
      </div>
    </div>
  );
};
GroupNotesToolbar.displayName = 'GroupNotesToolbar';

export default GroupNotesToolbar;
