import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';

import ddt from '@wix/da-ddt';
import { ModuleName } from '@wix/da-gruser-shared/pkg/types/modules';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import Flex from '@wix/da-ds/pkg/Flex';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import MeasuredGrid, { GridElement } from '@wix/da-shared-react/pkg/Grid';

import { ShopItemType } from '../../../../../../types/shop';
import withSectionFetchSpinner from '../../../../../decorators/withSectionFetchSpinner';
import LoadableDuperbrowseContext from '../../../../../contexts/LoadableDuperbrowseContext';
import StreamPagination from '../../../_partials/StreamPagination';
import { DEFAULT_GRID_ELEMENT_HEIGHT } from './constants';
import { RenderCardProps, ShopCardSubject } from './types';
import useShopGridStream from './useShopGridStream';
import UploadMoreSubmitButton from './UploadMoreSubmitButton';
import ShopFilters from './ShopFilters';
import { getBreakpoints } from './breakpoints';
import { isCurrentUserProfileOwner } from '../../../../../selectors/users';
import { useSelector } from 'react-redux';
import { FolderUploadMore } from '../UploadMore/UploadMore';

// TODO: remove with profile_shop_pagination_2
const logger = ddt.createLogger('shopgrid');

export interface Props {
  itemType: ShopItemType;
  moduleName: ModuleName;
  uploadMoreBiClickInfo: string;
  invitationToUpload: React.ComponentType;
  className?: string;
  gridElementHeight?: number;
  renderCard: (props: RenderCardProps) => React.ReactNode;
}

export const ShopGridWithFilters: React.FC<Props> = ({
  itemType,
  moduleName,
  uploadMoreBiClickInfo,
  invitationToUpload: InvitationToUpload,
  gridElementHeight = DEFAULT_GRID_ELEMENT_HEIGHT,
  className,
  renderCard,
}) => {
  const { t } = useTranslation();
  const isDesktop = !useContext(MobileContext);
  const [areFiltersExpanded, setFiltersExpanded] = useState(isDesktop);
  const isOwner = useSelector(isCurrentUserProfileOwner);

  const {
    streamId,
    items: streamItems = [],
    itemsPerFetch,
    total = 0,
    totalPages,
    hasMore,
    hasLess,
  } = useShopGridStream<ShopCardSubject | FolderUploadMore>(itemType);
  let items = streamItems;
  logger.log(JSON.stringify({ items: items?.length, hasMore }));
  logger.log(JSON.stringify({ total, itemsPerFetch, totalPages }));
  const hasProductsForSale = total >= 1;

  let showUploadMoreInGrid = isOwner && !hasLess;

  // on desktop, show on non-empty state
  if (isDesktop) {
    showUploadMoreInGrid = showUploadMoreInGrid && items.length > 0;
  } else {
    // on mobile, show only on commissions page since it's only entry point for mobile
    showUploadMoreInGrid =
      showUploadMoreInGrid &&
      (itemType === ShopItemType.COMMISSIONS || items.length === 0);
  }

  // prefix an "upload more" card at the beginning of the list
  if (showUploadMoreInGrid) {
    items = [
      {
        uploadMore: (
          <UploadMoreSubmitButton
            itemType={itemType}
            biClickInfo={uploadMoreBiClickInfo}
            view={'small'}
          />
        ),
      },
      ...items,
    ];
  }

  const renderElement = useCallback(
    (element: GridElement) => {
      const { index, style } = element;

      if (index === 0 && 'uploadMore' in items[index]) {
        return (
          <div style={{ ...style, height: 'auto' }}>
            {(items[index] as FolderUploadMore).uploadMore}
          </div>
        );
      }
      return renderCard({
        subject: items[element.index] as ShopCardSubject,
        ...element,
      });
    },
    [items, renderCard]
  );

  return (
    <Flex className={className} direction="column" gap={24}>
      {hasProductsForSale && (
        <ErrorBoundary debugComponent="ShopGridWithFilters">
          <ShopFilters
            moduleName={moduleName}
            itemCount={items.length}
            // these are here and not just inside ShopFilters because we
            // often need to know the expanded state to re-calc breakpoints
            // TODO: maybe design will be ok with the same elementcount
            // regardless of expanded or not
            areFiltersExpanded={areFiltersExpanded}
            setFiltersExpanded={setFiltersExpanded}
            itemType={itemType}
          >
            <LoadableDuperbrowseContext
              streamId={streamId}
              parent={{ title: t('duperbrowse.backButton.profile.shop') }}
            >
              <MeasuredGrid
                cookieType={MeasuredCookieType.USER_PROFILE_SHOP}
                breakpoints={getBreakpoints(gridElementHeight)}
                preserveAspectRatio={false}
                enableScrollOptim
                elementCount={items.length}
                renderElement={renderElement}
              />
            </LoadableDuperbrowseContext>

            <StreamPagination streamId={streamId} totalPages={totalPages} />
          </ShopFilters>
        </ErrorBoundary>
      )}

      {!hasProductsForSale && <InvitationToUpload />}
    </Flex>
  );
};

ShopGridWithFilters.displayName = 'ShopGridWithFilters';

const enhance = compose(withSectionFetchSpinner);
export default enhance(ShopGridWithFilters) as React.ComponentType<Props>;
