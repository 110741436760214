import React from 'react';
import { compose } from 'redux';
import classnames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import withFeatureFlags, {
  WithFeatureFlagsProps,
} from '@wix/da-react-context/pkg/flags/withFeatureFlags';
import IconButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/IconButtonWithContextMenu';
import EllipsisIcon from '@wix/da-ds/pkg/Icons/Ellipsis';
import LockIcon from '@wix/da-ds/pkg/Icons/24x24/Lock';
import UnlockIcon from '@wix/da-ds/pkg/Icons/24x24/Unlock';
import EditIcon from '@wix/da-ds/pkg/Icons/24x24/Edit';
import TrashcanIcon from '@wix/da-ds/pkg/Icons/24x24/Trashcan';
import CommissionShopCard, {
  Props as CommissionShopCardProps,
} from '@wix/da-shared-react/pkg/ShopCard/CommissionShopCard';
import {
  withLogBiEvent,
  WithLogBiEventProps,
} from '@wix/da-bi/pkg/hocs/withLogBiEvent';
import {
  default as CommissionConfirmation,
  Props as CommissionConfirmationProps,
} from '@wix/da-shared-react/pkg/CommissionEditPopup/CommissionConfirmation';
import {
  EditDeviationPayload,
  DeleteDeviationPayload,
} from '@wix/da-shared-react/pkg/redux/deviations/actionCreators';
import { getDeviationResourceType } from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import { PapiDeviation } from '@wix/da-papi-types';

import { BiData, MoreMenuClickBiEvent } from '@wix/da-bi/pkg/events';
import s from './CommissionThumbWithMenu.scss';

const SHOP_CARD_WIDTH = 330;

export interface ActionProps {
  className?: string;
  isOwner: boolean;
  isActive?: boolean;
  isDeleted?: boolean;
  editDeviation: (object: EditDeviationPayload) => void;
  toggleCommissionStatus: (object: PapiDeviation) => void;
  deleteDeviation: (object: DeleteDeviationPayload) => void;
}

type NonShopCardProps = CommissionShopCardProps & ActionProps;
export interface Props extends NonShopCardProps {}

export interface State {
  confirmationPopupType?: CommissionConfirmationProps['confirmationType'];
}

export class CommissionThumbWithMenu extends React.Component<
  Props & WithTranslation & WithLogBiEventProps & WithFeatureFlagsProps,
  State
> {
  readonly state: State = {
    confirmationPopupType: undefined,
  };

  openCommissionPauseConfirmation = event => {
    event.preventDefault();
    const { isActive, isDeleted } = this.props;
    if (isDeleted) {
      return;
    }

    this.setState({
      confirmationPopupType: isActive ? 'disable' : 'enable',
    });
  };

  onChangeCommissionStatus = () => {
    const { deviation } = this.props;

    if (!deviation.commission) {
      return;
    }
    this.props.toggleCommissionStatus(deviation);
    this.onCloseConfirmationModal();
  };

  openCommissionDeleteConfirmation = event => {
    event.preventDefault();

    this.setState({
      confirmationPopupType: 'remove',
    });
  };

  onDeleteCommission = () => {
    this.props.deleteDeviation({ deviation: this.props.deviation });
    this.setState({
      confirmationPopupType: undefined,
    });
  };

  onCloseConfirmationModal = () => {
    this.setState({
      confirmationPopupType: undefined,
    });
  };

  openCommissionEditModal = event => {
    const { editDeviation, deviation } = this.props;
    if (!deviation.isTextEditable) {
      return;
    }
    event.preventDefault();

    editDeviation({ deviation });
  };

  onDropdownOpen = () => {
    const { logBiEvent, deviation } = this.props;
    logBiEvent(
      BiData<MoreMenuClickBiEvent>({
        evid: 417,
        typeid: getDeviationResourceType(deviation),
        itemid: deviation.deviationId,
      })
    );
  };

  render() {
    const { t, isOwner, deviation, className, isActive, allowedIndicators } =
      this.props;
    const { confirmationPopupType } = this.state;

    return (
      <div className={classnames(s['root'], className)}>
        <CommissionShopCard
          width={SHOP_CARD_WIDTH}
          deviation={deviation}
          withAuthorDetails={false}
          allowedIndicators={allowedIndicators}
          withDescriptionBlock={false}
        />
        {isOwner && (
          <div className={s['dropdown-wrapper']}>
            <IconButtonWithContextMenu
              icon={EllipsisIcon}
              size="medium"
              aria-label={t('common.more')}
              className={s['dropdown']}
              popperManagerProps={{
                onOpen: this.onDropdownOpen,
              }}
              items={[
                {
                  // safe zone
                  items: [
                    deviation.isTextEditable && {
                      icon: <EditIcon />,
                      label: t('common.edit'),
                      onClick: this.openCommissionEditModal,
                    },
                    !deviation.isTextEditable && {
                      icon: <EditIcon />,
                      label: t('common.edit'),
                      title: t('common.commissions.legacyNotEditable'),
                      disabled: true,
                    },
                    {
                      icon: isActive ? <UnlockIcon /> : <LockIcon />,
                      label: isActive ? t('common.close') : t('common.open'),
                      onClick: this.openCommissionPauseConfirmation,
                    },
                  ],
                },
                {
                  // danger zone
                  items: [
                    {
                      icon: <TrashcanIcon />,
                      label: t(`common.delete`),
                      onClick: this.openCommissionDeleteConfirmation,
                      danger: true,
                    },
                  ],
                },
              ]}
              withBackground
            />
          </div>
        )}
        {confirmationPopupType && (
          <CommissionConfirmation
            isOpen
            confirmationType={confirmationPopupType}
            onCancel={this.onCloseConfirmationModal}
            onConfirm={
              confirmationPopupType === 'remove'
                ? this.onDeleteCommission
                : this.onChangeCommissionStatus
            }
          />
        )}
      </div>
    );
  }
}

const enhance = compose(withTranslation(), withLogBiEvent, withFeatureFlags);

export default enhance(CommissionThumbWithMenu);
