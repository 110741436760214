import React from 'react';
import cn from 'classnames';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import ArrowRight from '@wix/da-ds/pkg/Icons/16x16/ArrowRight';

import s from './ChartPanelSkeleton.scss';

interface Props {
  className?: string;
  rootRef?: React.RefObject<HTMLElement | null>;
  totalTitle: string;
  total: string;
  manageTitle: string;
  manageHref: string;
  children?: React.ReactNode;
}

const ChartPanelSkeleton: React.FC<Props> = ({
  className,
  rootRef,
  totalTitle,
  total,
  manageTitle,
  manageHref,
  children,
}) => {
  return (
    <div ref={rootRef as any} className={cn(s['root'], className)}>
      <div className={s['above']}>
        <div className={s['total']}>
          {totalTitle}
          <strong>{total}</strong>
        </div>
        <TextButton
          className={s['manage-btn']}
          size="small"
          variant="main"
          href={manageHref}
          postfix={ArrowRight}
        >
          {manageTitle}
        </TextButton>
      </div>
      <div className={s['chart']}>{children}</div>
    </div>
  );
};
ChartPanelSkeleton.displayName = 'ChartPanelSkeleton';

export default ChartPanelSkeleton;
