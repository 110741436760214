import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleTooltip from '@wix/da-ds/pkg/tooltips/dsPreset/SimpleTooltip';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';

function placeCommasInNumber(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export interface Props {
  className?: string;
  countClassName?: string;
  value: number;
  stat: string;
}

export const ProfileStatItemNumeric: React.FC<Props> = ({
  className,
  countClassName,
  value = 0,
  stat,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const formattedCount = shortenNumber(value);
  const isTooltipNeeded = Number(formattedCount) !== value;
  return (
    <span key={stat} className={className}>
      {t(`profileStats.${stat}`, {
        postProcess: ['reactPostprocessor', 'featureBrancher'],
        count: value,
        counter:
          !isMobile && isTooltipNeeded ? (
            <SimpleTooltip
              key={stat}
              placement="top"
              text={placeCommasInNumber(value)}
            >
              {formattedCount}
            </SimpleTooltip>
          ) : (
            <span className={countClassName}>{formattedCount}</span>
          ),
        stat,
      })}
    </span>
  );
};

ProfileStatItemNumeric.displayName = 'ProfileStatItemNumeric';

export default ProfileStatItemNumeric;
