import { handleActions } from 'redux-actions';
import {
  reportProfileSubmit,
  reportProfileSubmitSuccess,
  resetReportProfile,
} from '../actions/reportProfile';
import { ReportProfileState } from '../../types/reportProfile';

const initialState: ReportProfileState = {
  reportSubmitted: false,
  isSubmitting: false,
};

export default handleActions<ReportProfileState, any>(
  {
    [reportProfileSubmit.toString()]: state => ({
      ...state,
      isSubmitting: true,
    }),
    [reportProfileSubmitSuccess.toString()]: state => ({
      ...state,
      reportSubmitted: true,
      isSubmitting: false,
    }),
    [resetReportProfile.toString()]: () => initialState,
  },
  initialState
);
