import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import WidgetBoxWide, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBoxWide';
import { useNormalizedUserStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';

import { GROUP_MEMBERS_IDS_STREAM } from '../../../streams';
import { getCanInviteToGroup } from '../../../selectors/privs';
import { inviteToGroup } from '../../../actions/groups';
import useProfileOwnerUser from '../../../../utils/hooks/useProfileOwnerUser';

import EmptyState from '../../EmptyState';
import UserStream from '../../_partials/UserStream';

interface Props {
  moduleId: number;
  widgetBoxProps?: WidgetBoxProps;
}

const streamId = GROUP_MEMBERS_IDS_STREAM;

const GroupMembersBoxWide: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const { items, isFetching, hasMore, fetchNext } =
    useNormalizedUserStream(streamId);
  const isEmpty = !isFetching && !items?.length;
  const canInvite = useSelector(getCanInviteToGroup);
  const profileOwnerUser = useProfileOwnerUser();

  const dispatch = useDispatch();
  const dispatchInviteAction = useCallback(
    () => dispatch(inviteToGroup(profileOwnerUser)),
    [dispatch, profileOwnerUser]
  );

  return (
    <WidgetBoxWide
      id="group_members"
      header={t(`widgets.group_members.header`)}
      moduleId={moduleId}
      hasMore={hasMore}
      isFetching={isFetching}
      fetchMore={fetchNext}
      {...widgetBoxProps}
    >
      {isEmpty && canInvite && (
        <EmptyState
          view="small"
          title={t(`widgets.group_members.empty.title.owner`)}
          subtitle={t(`widgets.group_members.empty.subtitle.owner`)}
          buttonLabel={t(`widgets.group_members.empty.CTALabel.owner`)}
          withButton={true}
          onButtonClick={dispatchInviteAction}
        />
      )}
      {isEmpty && !canInvite && (
        <EmptyState
          view="small"
          title={t(`widgets.group_members.empty.title.visitor`, {
            username: profileOwnerUser.username,
          })}
          subtitle={t(`widgets.group_members.empty.subtitle.visitor`, {
            username: profileOwnerUser.username,
          })}
        />
      )}

      {!isEmpty && <UserStream streamId={streamId} />}
    </WidgetBoxWide>
  );
};
GroupMembersBoxWide.displayName = 'GroupMembersBoxWide';

export default GroupMembersBoxWide;
