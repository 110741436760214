import React from 'react';
import { useParams } from 'react-router-dom';
import c from 'classnames';
import StandardDropdown from '@wix/da-ds/pkg/Dropdown/Preset/StandardDropdown';
import { SubSubNavBarItem } from '../../../../SubSubNavBar';
import { USER_PROFILE_SHOP_SUB_PATHS } from '@wix/da-url';

import s from './MobileNavigationDropdown.scss';

interface Props {
  items: (SubSubNavBarItem & { value: string })[];
  className?: string;
}

const MobileNavigationDropdown: React.FC<Props> = ({ className, items }) => {
  const { shopSection } = useParams();

  // filter out "All" and items with count 0
  const itemsFiltered = items.filter(
    item => item.value !== USER_PROFILE_SHOP_SUB_PATHS.ALL && item.count > 0
  );

  if (itemsFiltered.length === 0) {
    return null;
  }

  return (
    <div className={c(s['root'], className)}>
      <StandardDropdown
        role="menu"
        toggleClassName={s['toggle']}
        arrowClassName={s['arrow']}
        menuClassName={s['menu']}
        popperClassName={s['popper']}
        placement="bottom-start"
        selectedValue={shopSection}
        items={itemsFiltered.map(item => ({
          label: (
            <>
              {item.label}
              <span className={s['count']}>{item.count}</span>
            </>
          ),
          value: item.value,
          onClick: () => {
            window.location.href = Array.isArray(item.path)
              ? item.path[0]
              : item.path;
          },
          selected: Array.isArray(item.path)
            ? item.path.includes(shopSection)
            : item.path === shopSection,
        }))}
      />
    </div>
  );
};
MobileNavigationDropdown.displayName = 'MobileNavigationDropdown';

export default MobileNavigationDropdown;
