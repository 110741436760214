import React, { useCallback } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { PapiGroupnote } from '../../../../../../types/papi';
import IconButton from '@wix/da-ds/pkg/Buttons/IconButton';
import FavStarIcon from '@wix/da-ds/pkg/Icons/16x16/FavStar';
import FavStarFilledIcon from '@wix/da-ds/pkg/Icons/16x16/FavStarFill';

import s from './GroupNotesListItemActions.scss';

export interface Props {
  className?: string;
  selected?: boolean;
  item: PapiGroupnote;

  starNotes: (noteids) => void;
  unstarNotes: (noteids) => void;
}

const GroupNotesListItemActions: React.FC<Props> = ({
  className,
  item,
  starNotes,
  unstarNotes,
}) => {
  const { t } = useTranslation();
  const toggleIsStarred = useCallback(() => {
    if (item.isStarred) {
      unstarNotes([item.noteId]);
    } else {
      starNotes([item.noteId]);
    }
  }, [item, starNotes, unstarNotes]);
  return (
    <div className={classnames(s['root'], className)}>
      <IconButton
        className={s['close-button']}
        size="large"
        icon={item.isStarred ? FavStarFilledIcon : FavStarIcon}
        onClick={toggleIsStarred}
        aria-label={t('groupCreation.modal.closeButton.a11y')}
      />
      <div
        className={classnames(
          s['replies-count'],
          !item.replies && s['replies-count-unread']
        )}
      >
        {item.replies}
      </div>
    </div>
  );
};
GroupNotesListItemActions.displayName = 'GroupNotesListItemActions';

export default GroupNotesListItemActions;
