import { AppState } from '../../types/store';
import { getPagingMode } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { getFolderById, getNonEmptyFolders } from './folders';
import { getCanCreateGalleryFolder } from './privs';
import { createSelector } from 'reselect';

export const getGallectionSectionType = (state: AppState) =>
  state.gallectionSection.type;

export const getSelectedFolderId = (state: AppState) =>
  state.gallectionSection.selectedFolderId;

export const getSelectedFolder = (state: AppState) => {
  const selectedFolderId = getSelectedFolderId(state);
  const gallectionType = getGallectionSectionType(state);
  return typeof selectedFolderId === 'number'
    ? getFolderById(state, gallectionType, selectedFolderId)
    : undefined;
};

export const getIsPagedMode = (state: AppState) =>
  getPagingMode(state) === 'page';

export const getSelectedSubfolderId = (state: AppState) =>
  state.gallectionSection.selectedSubfolderId;

export const getSelectedSubfolder = (state: AppState) => {
  const selectedSubfolderId = getSelectedSubfolderId(state);
  const gallectionType = getGallectionSectionType(state);
  return typeof selectedSubfolderId === 'number'
    ? getFolderById(state, gallectionType, selectedSubfolderId)
    : undefined;
};

export const getHasOnlyDefaultFolders = (state: AppState) =>
  getNonEmptyFolders(state).length === 2; // All and Featured (these folders can't be removed)

export const getShouldHideFolderSlider = (state: AppState) => {
  const canCreateFolder = getCanCreateGalleryFolder(state);
  const hasOnlyDefaultFolders = getHasOnlyDefaultFolders(state);
  return !canCreateFolder && hasOnlyDefaultFolders;
};

export const getSortOrder = (state: AppState) =>
  state.gallectionSection.sortOrder;

export const getSearchInputValue = (state: AppState) =>
  state.gallectionSection.searchInputValue;

export const getSearchQuery = (state: AppState) =>
  state.gallectionSection.searchQuery;

export const getSubFolderFetchState = createSelector(
  state => state,
  (_, type) => type,
  (_, __, parentId) => parentId,
  (state: AppState, type, parentId) => {
    return state.gallectionSection.subFolderFetchState[`${type}-${parentId}`];
  }
);
