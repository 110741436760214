import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ChangeSkin16 from '@wix/da-ds/pkg/Icons/16x16/ChangeSkin';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import IconButton from '@wix/da-ds/pkg/Buttons/IconButton';
import {
  closeSkinSelector,
  openSkinSelector,
} from '../../../../../../actions/profileSkins';
import {
  getProfileSkin,
  isSkinSelectorShown as getIsSkinSelectorShown,
} from '../../../../../../selectors/profileSkins';
import {
  BiContextProvidedValue,
  BiData,
  ChangeBackgroundClickBiEvent,
} from '@wix/da-bi/pkg/events';

interface Props {
  iconOnly?: boolean;
  className?: string;
}

const ProfileSkinsButton: React.FC<Props> = ({ iconOnly, className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profileSkin = useSelector(getProfileSkin);
  const isSkinSelectorShown = useSelector(getIsSkinSelectorShown);

  const biData = BiData<ChangeBackgroundClickBiEvent>({
    evid: 370,
    background_name: profileSkin?.skinId ?? 'original',
    typeid: BiContextProvidedValue,
    itemid: BiContextProvidedValue,
    core_only: profileSkin?.isCore ? 1 : 0,
  });

  const onClick = () =>
    dispatch(isSkinSelectorShown ? closeSkinSelector() : openSkinSelector());

  return iconOnly ? (
    <IconButton
      icon={ChangeSkin16}
      variant="edit-purple"
      size="medium"
      className={className}
      biData={biData}
      onClick={onClick}
      aria-label={t('navigation.subnavigation.profileSkins')}
    />
  ) : (
    <TextButton
      icon={ChangeSkin16}
      variant={'edit-purple'}
      size={'medium'}
      className={className}
      biData={biData}
      onClick={onClick}
    >
      {t('navigation.subnavigation.profileSkins')}
    </TextButton>
  );
};
ProfileSkinsButton.displayName = 'ProfileSkinsButton';

export default ProfileSkinsButton;
