import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ddt from '@wix/da-ddt';
import { PapiDeviation, PapiGallection } from '@wix/da-papi-types';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import { GridElement, StandardGrid } from '@wix/da-shared-react/pkg/Grid';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import { ShopThumbType } from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Shop';
import { AllowedIndicators } from '@wix/da-shared-react/pkg/DeviationViews/Thumb/legos/DeviationThumbIndicators';
import CommissionShopCard from '@wix/da-shared-react/pkg/ShopCard/CommissionShopCard';
import PCPShopCard from '@wix/da-shared-react/pkg/ShopCard/PCPShopCard';
import FolderShopCard from '@wix/da-shared-react/pkg/ShopCard/FolderShopCard';
import AdoptableShopCard from '@wix/da-shared-react/pkg/ShopCard/AdoptableShopCard';
import { useStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import { ShopItemType } from '../../../../../types/shop';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import LoadableDuperbrowseContext from '../../../../contexts/LoadableDuperbrowseContext';
import StreamPagination from '../../_partials/StreamPagination';
import EmptyState from '../_partials/EmptyState';
import { FolderUploadMore } from '../_partials/UploadMore/UploadMore';

// TODO: remove with profile_shop_pagination_2
const logger = ddt.createLogger('shopgrid');

function getBreakpoints(type) {
  let elementHeight;
  switch (type) {
    case ShopItemType.COMMISSIONS:
      elementHeight = 338;
      break;
    case ShopItemType.PREMIUM_CONTENT:
      elementHeight = 308;
      break;
    case ShopItemType.PREMIUM_FOLDERS:
      elementHeight = 310;
      break;
    case ShopItemType.ADOPTABLES:
      elementHeight = 308;
      break;
    default:
      elementHeight = 308;
  }

  return [
    {
      maxWidth: 735,
      elementsPerRow: 1,
      elementHeight,
    },
    {
      maxWidth: 1280,
      elementsPerRow: 3,
      elementHeight,
    },
    {
      maxWidth: 1536,
      elementsPerRow: 4,
      elementHeight,
    },
    {
      maxWidth: 1920,
      elementsPerRow: 5,
      elementHeight,
    },
    {
      maxWidth: 9999,
      elementsPerRow: 6,
      elementHeight,
    },
  ];
}

export const itemTypeToThumbTypeMap = {
  [ShopItemType.PREMIUM_CONTENT]: ShopThumbType.PCP,
  [ShopItemType.COMMISSIONS]: ShopThumbType.COMMISSION,
};

const itemTypeToBiWidgetMap = {
  [ShopItemType.PREMIUM_CONTENT]: 'downloads',
  [ShopItemType.COMMISSIONS]: 'commissions',
};

export interface Props {
  streamId: string;
  className?: string;
  items: (PapiGallection | PapiDeviation | FolderUploadMore)[];
  itemType: ShopItemType;
  hasMore: boolean;
  isFetching: boolean;
  fetchMore: () => void;
  emptyState?: React.ReactNode;
  uploadMore?: React.ReactNode;
  isOwner: boolean;
  isInfiniteScroll: boolean;
}

export const ShopGrid: React.FC<Props> = ({
  streamId,
  items,
  itemType,
  hasMore,
  isFetching,
  fetchMore,
  emptyState,
  uploadMore,
  isOwner,
}) => {
  const isMobile = useContext(MobileContext);

  logger.log(JSON.stringify({ items: items?.length, hasMore }));
  const { t } = useTranslation();

  const { total, itemsPerFetch, hasLess } = useStream(streamId);
  const totalPages =
    total && itemsPerFetch ? Math.ceil(total / itemsPerFetch) : 0;
  logger.log(JSON.stringify({ total, itemsPerFetch, totalPages }));

  let showUploadMoreInGrid = isOwner && !hasLess;

  // on desktop, show on non-empty state
  if (!isMobile) {
    showUploadMoreInGrid = showUploadMoreInGrid && items.length > 0;
  } else {
    // on mobile, show only on commissions page since it's only entry point for mobile
    showUploadMoreInGrid =
      showUploadMoreInGrid &&
      (itemType === ShopItemType.COMMISSIONS || items.length === 0);
  }

  // inject an "upload more" card at the beginning of the list
  if (showUploadMoreInGrid) {
    items = [{ uploadMore }, ...items];
  }

  const renderElement = useCallback(
    (element: GridElement) => {
      const { index, width, style } = element;

      let thumb;

      if (index === 0 && 'uploadMore' in items[index]) {
        thumb = (items[index] as FolderUploadMore).uploadMore;
      } else if (itemType === ShopItemType.COMMISSIONS) {
        thumb = (
          <CommissionShopCard
            key={index}
            deviation={items[index] as PapiDeviation}
            width={width}
            withAuthorDetails={false}
            allowedIndicators={AllowedIndicators.None}
            withDescriptionBlock={false}
          />
        );
      } else if (itemType === ShopItemType.PREMIUM_CONTENT) {
        thumb = (
          <PCPShopCard
            key={index}
            deviation={items[index] as PapiDeviation}
            width={width}
            withAuthorDetails={false}
            allowedIndicators={AllowedIndicators.None}
            withDreamupLabel={false}
          />
        );
      } else if (itemType === ShopItemType.PREMIUM_FOLDERS) {
        thumb = (
          <FolderShopCard
            key={index}
            folder={items[index] as PapiGallection}
            width={width}
            withAuthorDetails={false}
            withIndicators={false}
          />
        );
      } else if (itemType === ShopItemType.ADOPTABLES) {
        thumb = (
          <AdoptableShopCard
            key={index}
            deviation={items[index] as PapiDeviation}
            width={width}
            withAuthorDetails={false}
            allowedIndicators={AllowedIndicators.None}
            withOwnerDetails
          />
        );
      }
      return <div style={{ ...style, height: 'auto' }}>{thumb}</div>;
    },
    [itemType, items]
  );

  const getItemId = useCallback(
    (index: number) => {
      if (itemType === ShopItemType.PREMIUM_FOLDERS) {
        const item = items[index] as PapiGallection;
        return `${index}-${item.folderId}`;
      } else {
        const item = items[index] as PapiDeviation;
        return `${index}-${item.deviationId}`;
      }
    },
    [itemType, items]
  );

  if (!items.length) {
    if (emptyState === false) {
      return null;
    }
    if (emptyState) {
      return <>{emptyState}</>;
    }

    return <EmptyState view="large" shopItemType={itemType} />;
  }

  return (
    <ErrorBoundary debugComponent="ShopGrid">
      <LoadableDuperbrowseContext
        streamId={streamId}
        parent={{ title: t('duperbrowse.backButton.profile.shop') }}
      >
        <BiLoggerContextProvider
          value={{ widgetname: itemTypeToBiWidgetMap[itemType] }}
        >
          <StandardGrid
            elementCount={items.length}
            cookieType={MeasuredCookieType.USER_PROFILE_SHOP}
            getElementId={getItemId}
            breakpoints={getBreakpoints(itemType)}
            preserveAspectRatio={false}
            enableScrollOptim
          >
            {renderElement}
          </StandardGrid>
        </BiLoggerContextProvider>
      </LoadableDuperbrowseContext>

      <StreamPagination streamId={streamId} totalPages={totalPages} />
    </ErrorBoundary>
  );
};
ShopGrid.displayName = 'ShopGrid';

export default ShopGrid;
