import React from 'react';
import { PapiGallection } from '@wix/da-papi-types';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import FoldersHeader from '../FoldersHeader';
import FolderSlider from '../FolderSlider';
import FolderContent from '../FolderContent';

import s from './GallectionSimpleView.scss';

export interface Props {
  currentFolder?: PapiGallection;
  hideFolderSlider?: boolean;
}

const DesktopGallectionSimpleView: React.FC<Props> = ({
  currentFolder,
  hideFolderSlider,
}) => {
  const renderSliderArea = () => {
    return (
      <>
        <FoldersHeader />
        {!hideFolderSlider && <FolderSlider />}
      </>
    );
  };
  return (
    <div id="content" className={s['root']}>
      <ErrorBoundary debugComponent="DesktopGallectionSimpleView">
        {renderSliderArea()}
        <div id="sub-folder-gallery">
          {currentFolder && (
            <FolderContent
              key={currentFolder?.folderId}
              folderId={currentFolder?.folderId || 0}
            />
          )}
        </div>
      </ErrorBoundary>
    </div>
  );
};
DesktopGallectionSimpleView.displayName = 'DesktopGallectionSimpleView';

export default DesktopGallectionSimpleView;
