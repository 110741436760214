import { GallectionType } from '../../types/gallection';

export const FOLDER_DEVIATIONS_STREAM_GROUP_ID = 'folder-deviations';
export const DEVIATIONS_PER_FETCH_COUNT = 24;
export const STREAM_PARAM_TYPE_FETCHING_PAUSED = 'fetching_paused';
export const DEVIATION_SEARCH_RESULTS_STREAM_ID = 'deviation_search_results';
export const FAVOURITES_SEARCH_RESULTS_STREAM_ID = 'favourites_search_results';
export const PRIVATE_COLLECTION_SEARCH_RESULTS_STREAM_ID =
  'private_collection_search_results';

export const getFolderDeviationsStreamId = (
  gallectionType: GallectionType,
  folderId
) => {
  const params = [gallectionType, folderId];
  return `${FOLDER_DEVIATIONS_STREAM_GROUP_ID}-${params.join('-')}`;
};
