import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import escapeTextForBrowser from '@wix/da-shared-react/pkg/utils/xss/escapeTextForBrowser';
import { isApiError, requestPuppy } from '@wix/da-http-client';
import i18next from 'i18next';
import { putErrorNotification } from '@wix/da-shared-react/pkg/utils/saga';
import {
  ReportProfilePayload,
  ReportProfileResponse,
} from '../../types/reportProfile';
import {
  reportProfileSubmit,
  reportProfileSubmitSuccess,
} from '../actions/reportProfile';

function* handleSubmitReport(action: Action<ReportProfilePayload>) {
  const { userId, reason, details } = action.payload;

  try {
    const result: ReportProfileResponse = yield call(
      requestPuppy,
      {
        method: 'POST',
        url: 'user/report/save',
        data: {
          userid: userId,
          choice: reason,
          text: escapeTextForBrowser(details),
        },
      },
      undefined,
      'dashared'
    );

    if (result && !isApiError(result)) {
      yield put(reportProfileSubmitSuccess(result));
    } else {
      yield putErrorNotification(i18next.t('report.failure'));
    }
  } catch (error) {
    yield putErrorNotification(i18next.t('report.failure'));
  }
}

export default function* saga() {
  yield all([takeLatest(reportProfileSubmit, handleSubmitReport)]);
}
