import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { renderRoutes } from 'react-router-config';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import { PapiUser } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { Url, USER_PROFILE_SHOP_SUB_PATHS } from '@wix/da-url';
import SubSubNavBar from '../../SubSubNavBar';
import ShopAll from './ShopAll';
import ShopCommissions from './ShopCommissions';
import ShopPremiumContent from './ShopPremiumContent';
import ShopPremiumFolders from './ShopPremiumFolders';
import ShopAdoptables from './ShopAdoptables';
import MobileNavigationDropdown from './_partials/MobileNavigationDropdown';

import s from './ShopSection.scss';

export interface Props {
  profileOwnerUser: PapiUser;
  isOwner: boolean;
  isLoggedIn: boolean;
  isVerified: boolean;
  total: {
    all: number;
    adoptables: number;
    commissions: number;
    premiumContent: number;
    premiumFolders: number;
  };
  hasSingularContentType: boolean;
  restrictLoggedOut(): void;
  openCommissionModal(): void;
}

const ShopSection: React.FC<Props> = ({
  profileOwnerUser,
  isLoggedIn,
  isVerified,
  isOwner,
  total,
  hasSingularContentType,
  restrictLoggedOut,
  openCommissionModal,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const isSingularContentTypeView = hasSingularContentType && !isOwner;

  const { shopSection } = useParams();
  const isAllTab = !shopSection;

  useEffect(() => {
    if (window.location.search.includes('new_commission')) {
      if (!isLoggedIn) {
        restrictLoggedOut();
      }
      if (isVerified && isOwner) {
        openCommissionModal();
      }
    }
  }, [isLoggedIn, isVerified, isOwner, restrictLoggedOut, openCommissionModal]);

  const getShopRoutes = useCallback(() => {
    const { username: ownerUsername } = profileOwnerUser;
    const username = ownerUsername.toLowerCase();
    return [
      {
        label: t('common.all'),
        value: USER_PROFILE_SHOP_SUB_PATHS.ALL,
        count: total.all,
        path: Url.userShopPath(username, USER_PROFILE_SHOP_SUB_PATHS.ALL),
        component: ShopAll,
        exact: true,
        bi_item_label: 'all',
      },
      {
        label: t('common.adoptables'),
        value: USER_PROFILE_SHOP_SUB_PATHS.EXCLUSIVES,
        count: total.adoptables,
        path: [
          Url.userShopPath(username, USER_PROFILE_SHOP_SUB_PATHS.EXCLUSIVES),
          Url.userShopPath(username, USER_PROFILE_SHOP_SUB_PATHS.ADOPTABLES),
        ],
        component: ShopAdoptables,
        bi_item_label: USER_PROFILE_SHOP_SUB_PATHS.EXCLUSIVES,
      },
      {
        label: t('common.premiumFolders'),
        value: USER_PROFILE_SHOP_SUB_PATHS.PREMIUM_FOLDERS,
        count: total.premiumFolders,
        path: Url.userShopPath(
          username,
          USER_PROFILE_SHOP_SUB_PATHS.PREMIUM_FOLDERS
        ),
        component: ShopPremiumFolders,
        bi_item_label: USER_PROFILE_SHOP_SUB_PATHS.PREMIUM_FOLDERS,
      },
      {
        label: t('common.commissions_other'),
        value: USER_PROFILE_SHOP_SUB_PATHS.COMMISSIONS,
        count: total.commissions,
        path: Url.userShopPath(
          username,
          USER_PROFILE_SHOP_SUB_PATHS.COMMISSIONS
        ),
        component: ShopCommissions,
        bi_item_label: USER_PROFILE_SHOP_SUB_PATHS.COMMISSIONS,
      },
      {
        label: t('common.premiumContent'),
        value: USER_PROFILE_SHOP_SUB_PATHS.PREMIUM_CONTENT,
        count: total.premiumContent,
        path: Url.userShopPath(
          username,
          USER_PROFILE_SHOP_SUB_PATHS.PREMIUM_CONTENT
        ),
        component: ShopPremiumContent,
        bi_item_label: USER_PROFILE_SHOP_SUB_PATHS.PREMIUM_CONTENT,
      },
    ].filter(i => !!i && (isOwner || i.bi_item_label === 'all' || i.count > 0));
  }, [t, profileOwnerUser, isOwner, total]);

  return (
    <div
      className={classnames(
        s['root'],
        isSingularContentTypeView && s['singular-content-type']
      )}
    >
      {!isMobile && !isSingularContentTypeView ? (
        <SubSubNavBar
          className={classnames(
            isAllTab && s['section-nav-all-tab'],
            s['section-nav']
          )}
          items={getShopRoutes()}
        />
      ) : null}
      <div id="content" className={s['content-wrapper']}>
        {isMobile && !isAllTab && !isSingularContentTypeView && (
          <MobileNavigationDropdown
            items={getShopRoutes()}
            className={s['nav-dropdown']}
          />
        )}
        {renderRoutes(getShopRoutes())}
      </div>
    </div>
  );
};
ShopSection.displayName = 'ShopSection';

export default ShopSection;
