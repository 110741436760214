import { createAction } from 'redux-actions';
import {
  ReportProfilePayload,
  ReportProfileResponse,
} from '../../types/reportProfile';

// Actions
export const reportProfileSubmit = createAction(
  'REPORT_PROFILE.SUBMIT',
  (payload: ReportProfilePayload) => payload
);

export const reportProfileSubmitSuccess = createAction(
  'REPORT_PROFILE.SUBMIT_SUCCESS',
  (response: ReportProfileResponse) => response
);

export const resetReportProfile = createAction(
  'REPORT_PROFILE.RESET',
  () => ({})
);
