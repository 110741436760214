import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../../../../../../types/store';
import { messageAdmins } from '@wix/da-shared-react/pkg/redux/groups/actions';
import { PapiUser } from '@wix/da-papi-types';
import { clickWatch } from '@wix/da-shared-react/pkg/WatchButton/redux/actionCreators';
import { getUserWatchState } from '@wix/da-shared-react/pkg/WatchButton/redux/selectors';
import { getIsGroupMember } from '../../../../../../../selectors/groups';
import {
  getCanInviteToGroup,
  getCanChangeFounder,
  getCanLeaveGroup,
  getCanCloseGroup,
  getCanMessageAdmins,
  getCanRespondToNotifications,
} from '../../../../../../../selectors/privs';
import {
  inviteToGroup,
  requestCloseGroup,
  requestChangeFounder,
  requestFounderLeaveGroup,
} from '../../../../../../../actions/groups';
import { getProfileOwnerUser } from '../../../../../../../selectors/users';

import { Props } from './GroupMoreMenu';

export type StateProps = Pick<
  Props,
  | 'profileOwnerUser'
  | 'isGroupMember'
  | 'isWatching'
  | 'canInviteToGroup'
  | 'canMessageAdmins'
  | 'canChangeFounder'
  | 'canLeaveGroup'
  | 'canCloseGroup'
  | 'canRespondToGroupNotifications'
>;
export type DispatchProps = Pick<
  Props,
  | 'requestLeaveGroup'
  | 'requestCloseGroup'
  | 'requestChangeFounder'
  | 'toggleWatchGroup'
  | 'inviteToGroup'
  | 'messageAdmins'
>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const profileOwnerUser = getProfileOwnerUser(state);
  const { isWatching } = getUserWatchState(state, profileOwnerUser);
  return {
    profileOwnerUser,
    isWatching,
    isGroupMember: getIsGroupMember(state),
    canInviteToGroup: getCanInviteToGroup(state),
    canChangeFounder: getCanChangeFounder(state),
    canLeaveGroup: getCanLeaveGroup(state),
    canCloseGroup: getCanCloseGroup(state),
    canMessageAdmins: getCanMessageAdmins(state),
    canRespondToGroupNotifications: getCanRespondToNotifications(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => ({
  inviteToGroup: (group: PapiUser) => dispatch(inviteToGroup(group)),
  requestCloseGroup: (group: PapiUser) => dispatch(requestCloseGroup(group)),
  requestLeaveGroup: (group: PapiUser) =>
    dispatch(requestFounderLeaveGroup(group)),
  requestChangeFounder: (group: PapiUser) =>
    dispatch(requestChangeFounder(group)),
  toggleWatchGroup: (group: PapiUser, isWatching: boolean) => {
    dispatch(clickWatch(group, isWatching));
  },
  messageAdmins: (group: PapiUser) => dispatch(messageAdmins(group)),
});

export default connect(mapStateToProps, mapDispatchToProps);
