import React, { useState } from 'react';
import { useLayoutEffectWithoutSSRComplaining } from '@wix/da-hooks/pkg/useLayoutEffectWithoutSSRComplaining';
import throttle from 'lodash/throttle';
import { CollapsingElement, CollapsingElementId } from './types';

const RESIZE_THROTTLE_TIMEOUT = 250;

type Params = {
  fullWidthContainerRef: React.RefObject<HTMLElement | null>;
  contentContainerRef: React.RefObject<HTMLElement | null>;
  onCollapsedIdsChange?: (collapsedIds: Array<CollapsingElementId>) => void;
};

export function useCollapsingElements(
  elements: Array<CollapsingElement>,
  { fullWidthContainerRef, contentContainerRef, onCollapsedIdsChange }: Params
) {
  type CollapsedIds = Array<CollapsingElementId>;
  const [collapsedIds, setCollapsedIds] = useState<CollapsedIds>([]);

  type CollapsedWidths = Partial<{
    [key in CollapsingElementId]: number;
  }>;
  const [collapsedWidths, setcollapsedWidths] = useState<CollapsedWidths>({});

  const isCollapsed = (id: CollapsingElementId) => collapsedIds.includes(id);

  const handleCollapsing = throttle(
    () => {
      const availableWidth = fullWidthContainerRef?.current?.offsetWidth || 0;
      const contentWidth = contentContainerRef?.current?.offsetWidth || 0;

      const collapse = () => {
        const newCollapsedIds: CollapsedIds = [...collapsedIds];
        const newCollapsedWidths: CollapsedWidths = {
          ...collapsedWidths,
        };
        let totalCollapsedBtnsWidth = 0;
        elements.forEach(collapsingButton => {
          const { id, ref } = collapsingButton;
          if (isCollapsed(id)) {
            return;
          }
          if (availableWidth >= contentWidth - totalCollapsedBtnsWidth) {
            // no more buttons need to be collapsed
            return;
          }
          const button = ref.current;
          if (!button) {
            return;
          }
          const buttonWidth = ref.current?.offsetWidth || 0;
          newCollapsedIds.push(id);
          newCollapsedWidths[id] = buttonWidth;
          totalCollapsedBtnsWidth += buttonWidth;
        });
        setCollapsedIds(newCollapsedIds);
        onCollapsedIdsChange?.(newCollapsedIds);
        setcollapsedWidths(newCollapsedWidths);
      };

      const uncollapse = () => {
        const uncollapsedIds: Array<CollapsingElementId> = [];
        let uncollapsedWidth = 0;
        // go through collapsingButtons in reverse order
        elements
          .slice()
          .reverse()
          .forEach(collapsingButton => {
            const { id } = collapsingButton;
            if (!collapsedIds.includes(id)) {
              return;
            }
            const buttonWidth = collapsedWidths[id] ?? 0;

            // is enough width to fit the button?
            if (
              availableWidth >=
              contentWidth + uncollapsedWidth + buttonWidth
            ) {
              uncollapsedIds.push(id);
              uncollapsedWidth += buttonWidth;
            }
          });
        const newCollapsedIds = collapsedIds.filter(
          id => !uncollapsedIds.includes(id)
        );
        setCollapsedIds(newCollapsedIds);
        onCollapsedIdsChange?.(newCollapsedIds);
      };

      if (availableWidth < contentWidth) {
        collapse();
      } else {
        if (collapsedIds.length) {
          uncollapse();
        }
      }
    },
    RESIZE_THROTTLE_TIMEOUT,
    { trailing: true, leading: true }
  );

  // run on mount
  useLayoutEffectWithoutSSRComplaining(handleCollapsing, []); // eslint-disable-line react-hooks/exhaustive-deps
  // run on resize
  useLayoutEffectWithoutSSRComplaining(() => {
    window.addEventListener('resize', handleCollapsing);
    return () => {
      window.removeEventListener('resize', handleCollapsing);
    };
  }, [collapsedIds, collapsedWidths]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    collapsedIds,
    isCollapsed,
    handleCollapsing,
  };
}
