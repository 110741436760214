import {
  put,
  call,
  all,
  takeEvery,
  select,
  takeLatest,
} from 'redux-saga/effects';
import history from '@wix/da-shared-react/pkg/redux/routing/history';
import { ALL_FOLDER_ID, Url } from '@wix/da-url';
import {
  unsetSelectedFolderId,
  setSelectedFolderId,
  clearSearch,
  setSearchQuery,
  setSearchInputValue,
  setSortOrder,
  setSelectedSubfolderId,
  unsetSelectedSubfolderId,
  fetchSubFolders,
  fetchSubFoldersDone,
  fetchSubFoldersError,
  fetchSubFoldersStart,
} from '../actions/gallectionSection';
import {
  getSearchInputValue,
  getGallectionSectionType,
  getSortOrder,
  getSearchQuery,
  getSelectedSubfolder,
  getSubFolderFetchState,
  getSelectedFolder,
} from '../selectors/gallectionSection';
import { getFolderById } from '../selectors/folders';
import { getProfileOwnerUser, getIsPagedMode } from '../selectors/users';
import { isSectionLoading } from '../selectors/sections';
import { DEFAULT_GALLECTION_SORT_ORDER } from '../components/Sections/GallectionsSection/consts';
import { pageViewHandler, logSearchSubmit } from './biLogger';
import { isApiError, requestPuppy } from '@wix/da-http-client';
import {
  PapiDaSharedGallectionFolders,
  PapiGallection,
  PapiRequestDaSharedGallectionFolders,
} from '@wix/da-papi-types';
import { normalizeGallectionList } from '../utils/gallection';
import { putEntities } from '@wix/da-shared-react/pkg/redux/entities/actions';
import { putErrorNotification } from '@wix/da-shared-react/pkg/utils/saga';

export function* updateURLSaga() {
  if (typeof window === 'undefined') {
    return;
  }
  const isLoading: ReturnType<typeof isSectionLoading> = yield select(
    isSectionLoading
  );
  if (isLoading) {
    return;
  }
  const user: ReturnType<typeof getProfileOwnerUser> = yield select(
    getProfileOwnerUser
  );
  const gallectionType: ReturnType<typeof getGallectionSectionType> =
    yield select(getGallectionSectionType);
  const selectedFolder: ReturnType<typeof getSelectedFolder> = yield select(
    getSelectedFolder
  );
  const selectedSubfolder: ReturnType<typeof getSelectedSubfolder> =
    yield select(getSelectedSubfolder);
  const searchQuery: ReturnType<typeof getSearchQuery> = yield select(
    getSearchQuery
  );
  const sortOrder: ReturnType<typeof getSortOrder> = yield select(getSortOrder);

  const currentFolderOrSubfolder = selectedSubfolder || selectedFolder;

  const includeSortOrder = sortOrder !== DEFAULT_GALLECTION_SORT_ORDER;

  const newUrl = Url.userGalleryPath({
    username: user.username,
    gallectionType,
    folderId: currentFolderOrSubfolder?.folderId,
    folderName: currentFolderOrSubfolder?.name,
    searchQuery,
    sortOrder: includeSortOrder ? sortOrder : undefined,
  });

  const isPaged = yield select(getIsPagedMode);
  if (isPaged) {
    // FOLDER_DEVIATIONS module is only loaded on init/gallection
    // And we need its totalPageCount prop in order to show the pagination correctly
    if (typeof window !== 'undefined') {
      window.location.href = newUrl;
    }
  } else {
    history.push(newUrl);
  }
}

export function* setSelectedFolderIdSaga(
  action: ReturnType<typeof setSelectedFolderId>
) {
  const searchInputValue = yield select(getSearchInputValue);
  if (searchInputValue) {
    yield all([put(setSearchQuery('')), put(setSearchInputValue(''))]);
  }
  yield put(unsetSelectedSubfolderId());
}

export function* setSearchQuerySaga(action: ReturnType<typeof setSearchQuery>) {
  if (action.payload) {
    // if not empty search query
    yield all([
      put(unsetSelectedFolderId()),
      call(logPageViewIfNotLoading),
      call(logSearchSubmit, action.payload),
    ]);
  }
}

export function* clearSearchSaga(action: ReturnType<typeof clearSearch>) {
  yield all([
    put(setSearchQuery('')),
    put(setSearchInputValue('')),
    put(setSelectedFolderId(ALL_FOLDER_ID)),
  ]);
}

export function* logPageViewIfNotLoading() {
  const isLoading = yield select(isSectionLoading);
  if (isLoading) {
    return;
  }
  yield call(pageViewHandler);
}

export function* handleFetchSubFolders(
  action: ReturnType<typeof fetchSubFolders>
) {
  const folder: PapiGallection = yield select(
    getFolderById,
    action.payload.type,
    action.payload.parentId
  );
  const { username } = yield select(getProfileOwnerUser);
  const fetchState = yield select(
    getSubFolderFetchState,
    action.payload.type,
    action.payload.parentId
  );
  const subFolderCount = folder?.subfolders?.length ?? 0;

  if (
    fetchState?.isFetching ||
    fetchState?.isDone ||
    !folder.hasSubfolders ||
    subFolderCount > 0
  ) {
    // Skip fetching if we already doing something, have error, or the folder has no sub folders
    return;
  }

  yield put(fetchSubFoldersStart(action.payload.type, action.payload.parentId));

  const params: PapiRequestDaSharedGallectionFolders = {
    type: action.payload.type as any,
    folderid: action.payload.parentId,
    username,
  };

  const result: PapiDaSharedGallectionFolders = yield call(
    requestPuppy,
    {
      method: 'get',
      url: '/gallection/folders',
      params,
    },
    undefined
  );

  if (!result || isApiError(result)) {
    yield put(
      fetchSubFoldersError(action.payload.type, action.payload.parentId)
    );
    yield putErrorNotification('Failed to fetch sub folders for this gallery');
    return;
  }

  // Update the folder entities
  folder.subfolders = result.results.filter(g => g.folderId > 0);
  const { entities } = normalizeGallectionList([...result.results, folder]);
  yield put(putEntities({ entities }));

  yield put(fetchSubFoldersDone(action.payload.type, action.payload.parentId));
}

export default [
  takeEvery(setSelectedFolderId, setSelectedFolderIdSaga),
  takeEvery(setSearchQuery, setSearchQuerySaga),
  takeEvery(clearSearch, clearSearchSaga),
  takeLatest(
    [
      unsetSelectedFolderId,
      unsetSelectedSubfolderId,
      setSelectedSubfolderId,
      setSearchQuery,
      setSortOrder,
    ],
    updateURLSaga
  ),
  takeLatest([setSelectedSubfolderId], logPageViewIfNotLoading),
  takeEvery(fetchSubFolders, handleFetchSubFolders),
];
