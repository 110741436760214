import React from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { Url, CHECKOUT_IDS } from '@wix/da-url';
import { PapiUser } from '@wix/da-papi-types';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import IconButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/IconButtonWithContextMenu';
import { RestrictLoggedOutPayload } from '@wix/da-shared-react/pkg/redux/loggedout/actionCreators';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import GiftIcon16 from '@wix/da-ds/pkg/Icons/16x16/Gift';
import AnimatedGiftIcon from '@wix/da-ds/pkg/Icons/GiftAnimated';
import CoreSymbol from '@wix/da-ds/pkg/Indicators/CoreSymbol';
import LlamaIcon from '@wix/da-ds/pkg/Icons/Llama';
import CakeIcon from '@wix/da-ds/pkg/Icons/24x24/Cake';
import PointsIcon from '@wix/da-ds/pkg/Icons/Points';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import { ProfileOwnerGiveState } from '../../../../../../../../types/profileOwner';
import {
  BiData,
  GiftClickBiEvent,
  GiftSelectClickBiEvent,
} from '@wix/da-bi/pkg/events';

import s from './GiveButton.scss';

export interface Props {
  className?: string;
  isLoggedIn?: boolean;
  profileOwnerUser: PapiUser;
  hasPublicBirthday: boolean;
  giveState?: ProfileOwnerGiveState;
  restrictLoggedOut: (payload: RestrictLoggedOutPayload) => void;
  onLlamaBadgeSent: () => void;
  initGiveState: () => void;
  pushBadgeModal: typeof modalActions.pushBadgeModal;
  pushPointsModal: (username: string, title: string) => void;
}

const GiveButton: React.FC<Props> = ({
  className,
  isLoggedIn,
  giveState,
  initGiveState,
  onLlamaBadgeSent,
  profileOwnerUser: { username, userId, useridUuid, isBirthweek },
  pushBadgeModal,
  pushPointsModal,
  restrictLoggedOut,
  hasPublicBirthday,
}) => {
  const { t } = useTranslation();

  const showAnimatedBirthdayIcon = hasPublicBirthday && isBirthweek;

  const getGiveMenuItems = () => {
    if (!giveState) {
      return [];
    }

    const coreLabel = giveState.canBeGivenCore
      ? t('navigation.subnavigation.giveCore')
      : t('navigation.subnavigation.hasCore');
    const pointsLabel = t('navigation.subnavigation.givePoints');
    const lamaLabel = giveState.canBeGivenLlama
      ? t('navigation.subnavigation.giveLlamaBadge')
      : t('navigation.subnavigation.hasGotLlamaBadge');
    const cakeLabel = giveState.canBeGivenCake
      ? t('navigation.subnavigation.giveCakeBadge')
      : t('navigation.subnavigation.hasGotCakeBadge');
    const biData = BiData<GiftSelectClickBiEvent>({
      evid: 275,
      recipientid: useridUuid,
    } as any);
    const giveCoreUrl = Url.giveCoreMembership(username);

    return [
      {
        icon: giveState.isLoading ? (
          <LoadingIndicator />
        ) : (
          <CoreSymbol size={IconSize.SMALL} />
        ),
        iconClassName: c(
          s['core-icon'],
          giveState.isLoading && s['loading-spinner']
        ),
        label: coreLabel,
        biData: {
          ...biData,
          menu_item_name: coreLabel,
          link_url: giveCoreUrl,
        },
        onClick: () => window.location.assign(giveCoreUrl),
        disabled: !giveState.canBeGivenCore,
      },
      {
        icon: <PointsIcon />,
        label: pointsLabel,
        biData: { ...biData, menu_item_name: pointsLabel },
        onClick: () => {
          pushPointsModal(username, pointsLabel);
        },
      },
      {
        icon: giveState.isLoading ? <LoadingIndicator /> : <LlamaIcon />,
        iconClassName: c(giveState.isLoading && s['loading-spinner']),
        label: lamaLabel,
        biData: { ...biData, menu_item_name: lamaLabel },
        onClick: () => {
          pushBadgeModal(username, onLlamaBadgeSent);
        },
        disabled: !giveState.canBeGivenLlama,
      },
      {
        icon: giveState.isLoading ? <LoadingIndicator /> : <CakeIcon />,
        iconClassName: c(giveState.isLoading && s['loading-spinner']),
        label: cakeLabel,
        biData: { ...biData, menu_item_name: cakeLabel },
        onClick: () => {
          window.location.assign(
            `${Url.checkoutLink(CHECKOUT_IDS.CAKE)}&foruserid=${userId}`
          );
        },
        disabled: !giveState.canBeGivenCake,
      },
    ];
  };

  return (
    <IconButtonWithContextMenu
      size="medium"
      iconSizeOverride={showAnimatedBirthdayIcon ? IconSize.LARGE : undefined}
      icon={showAnimatedBirthdayIcon ? AnimatedGiftIcon : GiftIcon16}
      aria-label={t('common.give')}
      tooltipText={
        showAnimatedBirthdayIcon
          ? t('common.giveBirthday', {
              username,
            })
          : t('common.give')
      }
      className={className}
      items={getGiveMenuItems()}
      popperManagerProps={{
        onOpen: isLoggedIn
          ? initGiveState
          : () =>
              restrictLoggedOut({ restrictedActionName: ReferralType.GIVE }),
      }}
      biData={BiData<GiftClickBiEvent>({
        evid: 274,
        recipientid: useridUuid,
      })}
    />
  );
};
GiveButton.displayName = 'GiveButton';

export default GiveButton;
