import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Props } from './FolderBar';
import { unsetSelectedFolderId } from '../../../../actions/gallectionSection';
import { AppState } from '../../../../../types/store';
import {
  getGallectionSectionType,
  getSelectedFolderId,
} from '../../../../selectors/gallectionSection';
import { getFolderById } from '../../../../selectors/folders';
import {
  getProfileOwnerUser,
  isCurrentUserProfileOwner,
} from '../../../../selectors/users';

type StateProps = Pick<Props, 'profileOwner' | 'isOwner'>;
type DispatchProps = Pick<Props, 'unsetSelectedFolderId'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps
) => {
  const selectedFolderId = getSelectedFolderId(state);
  const currentFolder =
    ownProps.currentFolder ||
    (typeof selectedFolderId === 'number'
      ? getFolderById(state, getGallectionSectionType(state), selectedFolderId)
      : undefined);

  return {
    currentFolder,
    profileOwner: getProfileOwnerUser(state),
    isOwner: isCurrentUserProfileOwner(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  unsetSelectedFolderId,
};

export default connect(mapStateToProps, mapDispatchToProps);
