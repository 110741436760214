import React, { useCallback, useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import noop from '@wix/da-shared-react/pkg/utils/noop';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import RefreshIcon from '@wix/da-ds/pkg/Icons/24x24/Refresh';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import IconButtonWithBackground from '@wix/da-ds/pkg/Buttons/IconButton/IconButtonWithBackground';
import { AvatarUploadPayload } from '../../../../../actions/avatar';
import AvatarEditPopup from './AvatarEditPopup';
import {
  AVATAR_WIDTH,
  AVATAR_HEIGHT,
  GROUP_AVATAR_WIDTH,
  GROUP_AVATAR_HEIGHT,
} from './constants';
import s from './AvatarEdit.scss';

export interface Props {
  className?: string;
  isOpen?: boolean;
  isGroup?: boolean;
  errorMessage?: string;
  setIsEditorOpen: (isOpen: boolean) => void;
  setErrorMessage: (errorMessage?: string) => void;
  uploadAvatar?: (payload: AvatarUploadPayload) => void;
}

const AvatarEdit: React.FC<Props> = ({
  className,
  isGroup,
  isOpen = false,
  errorMessage,
  setIsEditorOpen = noop,
  setErrorMessage = noop,
  uploadAvatar = noop,
}) => {
  const { t } = useTranslation();
  const openEditor = useCallback(() => {
    setIsEditorOpen(true);
  }, [setIsEditorOpen]);
  const isMobile = useContext(MobileContext);

  return (
    <div className={classnames(s['root'], isGroup && s['group'], className)}>
      {isMobile && (
        <IconButtonWithBackground
          className={s['btn-edit']}
          onClick={openEditor}
          data-role="edit-control"
          size="small"
          variant="edit-purple"
          icon={RefreshIcon}
          aria-label={t('widgets.avatarEdit.changeIconTitle.full')}
        />
      )}
      {!isMobile && (
        <button
          className={s['btn-edit']}
          onClick={openEditor}
          data-role="edit-control"
        >
          <RefreshIcon
            className={s['btn-icon']}
            size={IconSize.MEDIUM}
            condensed
          />
          <span className={s['btn-title']}>
            {t('common.change')}
            <VisuallyHidden>
              {t('widgets.avatarEdit.changeIconTitle')}
            </VisuallyHidden>
          </span>
        </button>
      )}

      {/* don't pass isOpen into modal - instead mount a new instance each time it's opened
      in order to reset state inside the component */}
      {isOpen && (
        <AvatarEditPopup
          errorMessage={errorMessage}
          setIsEditorOpen={setIsEditorOpen}
          setErrorMessage={setErrorMessage}
          uploadAvatar={uploadAvatar}
          avatarWidth={isGroup ? GROUP_AVATAR_WIDTH : AVATAR_WIDTH}
          avatarHeight={isGroup ? GROUP_AVATAR_HEIGHT : AVATAR_HEIGHT}
        />
      )}
    </div>
  );
};
AvatarEdit.displayName = 'AvatarEdit';
export default AvatarEdit;
