import { PapiGallection } from '@wix/da-papi-types';
import { ALL_FOLDER_ID } from '@wix/da-url';
import { isSpecialDefaultGallection } from '@wix/da-shared-react/pkg/helpers/gallections';
import stickyBarStyles from './StickyBar/StickyBar.scss';

export function getGallectionsStickyBarHeight(isMobile): number {
  return parseInt(
    isMobile
      ? stickyBarStyles['folder-bar-mobile-height']
      : stickyBarStyles['folder-bar-height']
  );
}

export function getCommentsBlockAnchor(folderId) {
  return `folder-comments-${folderId}`;
}

export const getAllFolderFromFolders = (folders: PapiGallection[]) =>
  folders.find(({ folderId }) => folderId === ALL_FOLDER_ID);

export const getSpecialDefaultFolderFromFolders = (folders: PapiGallection[]) =>
  folders.find(folder => isSpecialDefaultGallection(folder));
