import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import {
  getIsLoggedIn,
  getIsVerified,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import { restrictLoggedOut } from '@wix/da-shared-react/pkg/redux/loggedout';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import {
  isCurrentUserProfileOwner,
  getProfileOwnerUser,
} from '../../../selectors/users';
import { AppState } from '../../../../types/store';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { Props } from './ShopSection';
import { hasSingularContentType } from '../../../utils/emptyState';
import { getPageInfoStats } from '../../../selectors/pageInfo';

type StateProps = Pick<
  Props,
  | 'profileOwnerUser'
  | 'isOwner'
  | 'isLoggedIn'
  | 'isVerified'
  | 'total'
  | 'hasSingularContentType'
>;
type DispatchProps = Pick<Props, 'openCommissionModal' | 'restrictLoggedOut'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const stats = getPageInfoStats(state);
  const commissionsTotal = stats['commissions'] || 0;
  const premiumContentTotal = stats['pcp'] || 0;
  const premiumFoldersTotal = stats['premiumGalleries'] || 0;
  const adoptablesTotal = stats['adoptables'] || 0;

  const allTotal =
    commissionsTotal +
    premiumContentTotal +
    premiumFoldersTotal +
    adoptablesTotal;

  return {
    profileOwnerUser: getProfileOwnerUser(state),
    isOwner: isCurrentUserProfileOwner(state),
    isLoggedIn: getIsLoggedIn(state),
    isVerified: getIsVerified(state),
    hasSingularContentType: hasSingularContentType(state),
    total: {
      all: isNaN(allTotal) ? 0 : allTotal,
      adoptables: adoptablesTotal,
      commissions: commissionsTotal,
      premiumContent: premiumContentTotal,
      premiumFolders: premiumFoldersTotal,
    },
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  openCommissionModal: () =>
    modalActions.pushModal(ModalType.COMMISSION, {
      wrapInModal: false,
    }),
  restrictLoggedOut: () =>
    restrictLoggedOut({ restrictedActionName: ReferralType.CREATE_COMMISSION }),
};

export default connect(mapStateToProps, mapDispatchToProps);
