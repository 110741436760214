import { useCallback } from 'react';
import copy from 'copy-to-clipboard';
import { Url } from '@wix/da-url';
import { PapiUser } from '@wix/da-papi-types';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import LinkIcon from '@wix/da-ds/pkg/Icons/24x24/Link';
import { addNotification } from '@wix/da-shared-react/pkg/Notifications/redux/actionCreators';
import { NotificationType } from '@wix/da-shared-react/pkg/Notifications';

export function useCopyProfileLinkClick(targetUser: PapiUser) {
  const dispatchAddNotification = useAction(addNotification);

  const onCopyLinkClick = useCallback(() => {
    copy(Url.userLink(targetUser.username));
    dispatchAddNotification(NotificationType.Generic, {
      messageLocaleKey: 'share.linkCopied',
      cardProps: {
        iconComponent: LinkIcon,
      },
    });
  }, [dispatchAddNotification, targetUser]);

  return { onCopyLinkClick };
}
