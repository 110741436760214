import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { messageAdmins } from '@wix/da-shared-react/pkg/redux/groups/actions';
import { AppState } from '../../../../../../types/store';
import {
  unarchiveNotes,
  archiveNotes,
} from '../../../../../actions/backroomSection';
import { getSelectedFolder } from '../../../../../selectors/backroomSection';
import { Props } from './GroupNotesToolbar';

type StateProps = Pick<Props, 'folder'>;
type DispatchProps = Pick<
  Props,
  'archiveNotes' | 'unarchiveNotes' | 'messageAdmins'
>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  folder: getSelectedFolder(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  unarchiveNotes,
  archiveNotes,
  messageAdmins,
};

export default connect(mapStateToProps, mapDispatchToProps);
