import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { getShopItemsStreamId } from '../streams/shop';
import { ShopItemType } from '../../types/shop';
import { getPageInfoStats } from '../selectors/pageInfo';

export function hasSingularContentType(state) {
  return (
    Object.values(getEmptySections(state)).filter(empty => empty === false)
      .length === 1
  );
}

export function isEmpty(state) {
  return (
    Object.values(getEmptySections(state)).filter(empty => empty === true)
      .length === 4
  );
}

export function getEmptySections(state): object {
  const stats = getPageInfoStats(state);

  const hasNoCommissionItems =
    !streamSelectors.getTotal(
      state,
      getShopItemsStreamId(ShopItemType.COMMISSIONS)
    ) && !stats['commissions'];

  const hasNoPremiumContentItems =
    !streamSelectors.getTotal(
      state,
      getShopItemsStreamId(ShopItemType.PREMIUM_CONTENT)
    ) && !stats['pcp'];

  const hasNoPremiumFoldersItems =
    !streamSelectors.getTotal(
      state,
      getShopItemsStreamId(ShopItemType.PREMIUM_FOLDERS)
    ) && !stats['premiumGalleries'];

  const hasNoAdoptableItems =
    !streamSelectors.getTotal(
      state,
      getShopItemsStreamId(ShopItemType.ADOPTABLES)
    ) && !stats['adoptables'];

  return {
    [ShopItemType.COMMISSIONS]: hasNoCommissionItems,
    [ShopItemType.PREMIUM_CONTENT]: hasNoPremiumContentItems,
    [ShopItemType.PREMIUM_FOLDERS]: hasNoPremiumFoldersItems,
    [ShopItemType.ADOPTABLES]: hasNoAdoptableItems,
  };
}
