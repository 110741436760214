import { connect, MapDispatchToProps } from 'react-redux';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { Props } from './ShopPremiumFolders';

export type DispatchProps = Pick<Props, 'onCreate'>;
type OwnProps = Omit<Props, keyof DispatchProps>;

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => {
  return {
    onCreate: () =>
      dispatch(
        pushModal(ModalType.CREATE_PREMIUM_FOLDER, {
          wrapInModal: false,
        })
      ),
  };
};

export default connect(null, mapDispatchToProps);
