import { handleActions } from 'redux-actions';
import StatsSection, {
  StatsTimePeriod,
  MySubmissionsSortBy,
  BenchmarkSortBy,
} from '../../types/statsSection';
import {
  setStats,
  setTimePeriod,
  setMySubmissionsSortBy,
  setMySubmissionsIsLoading,
  setBenchmarkSortBy,
  fetchRecentVisitors,
  resultUserQuery,
  addBenchmarkUser,
  addBenchmarkUserSuccess,
  fetchRecentVisitorsSuccess,
} from '../actions/statsSection';

const defaultState: StatsSection = {
  timePeriod: StatsTimePeriod.Week,
  mySubmissionsSortBy: MySubmissionsSortBy.PublishDate,
  mySubmissionsIsLoading: false,
  benchmarkSortBy: BenchmarkSortBy.ProfileVisitors,
  benchmarkIsLoading: false,
  visitorsIsLoading: false,
  matchingUsers: [],
};

export default handleActions<StatsSection, any>(
  {
    [setStats.toString()]: (state, action: ReturnType<typeof setStats>) => ({
      ...state,
      stats: action.payload || {},
    }),
    [setTimePeriod.toString()]: (
      state,
      action: ReturnType<typeof setTimePeriod>
    ) => ({
      ...state,
      timePeriod: action.payload,
    }),
    [setMySubmissionsSortBy.toString()]: (
      state,
      action: ReturnType<typeof setMySubmissionsSortBy>
    ) => ({
      ...state,
      mySubmissionsSortBy: action.payload,
    }),
    [setMySubmissionsIsLoading.toString()]: (
      state,
      action: ReturnType<typeof setMySubmissionsIsLoading>
    ) => ({
      ...state,
      mySubmissionsIsLoading: action.payload,
    }),
    [setBenchmarkSortBy.toString()]: (
      state,
      action: ReturnType<typeof setBenchmarkSortBy>
    ) => ({
      ...state,
      benchmarkSortBy: action.payload,
    }),
    [addBenchmarkUser.toString()]: state => ({
      ...state,
      matchingUsers: [],
      benchmarkIsLoading: true,
    }),
    [addBenchmarkUserSuccess.toString()]: state => ({
      ...state,
      matchingUsers: [],
      benchmarkIsLoading: false,
    }),
    [resultUserQuery.toString()]: (
      state,
      action: ReturnType<typeof resultUserQuery>
    ) => ({
      ...state,
      matchingUsers: action.payload,
    }),
    [fetchRecentVisitors.toString()]: state => ({
      ...state,
      visitorsIsLoading: true,
    }),
    [fetchRecentVisitorsSuccess.toString()]: state => ({
      ...state,
      visitorsIsLoading: false,
    }),
  },
  defaultState
);
