import React, { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PapiGallection,
  PapiGruserModulePremiumFolders,
} from '@wix/da-papi-types';
import { USER_PROFILE_SHOP_SUB_PATHS } from '@wix/da-url';
import { MeasuredSlider as Slider } from '@wix/da-shared-react/pkg/Slider';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import {
  BiContextProvidedValue,
  BiData,
  DefaultBiEvent,
} from '@wix/da-bi/pkg/events';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import AddItemCard from '../../_partials/AddItemCard';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import { useSelector, useDispatch } from 'react-redux';
import PremiumFoldersEmptyState from '../../_partials/PremiumFoldersEmptyState';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import FolderShopCard from '@wix/da-shared-react/pkg/ShopCard/FolderShopCard';
import PremiumFolderSliderMobile from './PremiumFolderSliderMobile';
import {
  getProfileOwner,
  isCurrentUserProfileOwner,
} from '../../../../../selectors/users';
import { ShopItemType } from '../../../../../../types/shop';
import { getShopItemsStreamId } from '../../../../../streams/shop';
import { getFoldersByIds } from '../../../../../selectors/folders';
import { GallectionType } from '../../../../../../types/gallection';
import { useShopContentUrl } from '../hooks';

export interface Props {
  moduleId: number;
  widgetBoxProps: Partial<WidgetBoxProps>;
}

export const PremiumFolderSlider: React.FC<Props & WidgetBoxProps> = ({
  moduleId,
  widgetBoxProps,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const { moduleData } =
    useModuleData<PapiGruserModulePremiumFolders>(moduleId);

  const streamId = getShopItemsStreamId(ShopItemType.PREMIUM_FOLDERS);
  const folders = useSelector<any>(state =>
    getFoldersByIds(
      state,
      GallectionType.GALLERY,
      streamSelectors.getItems(state, streamId)
    )
  ) as PapiGallection[];

  const isEmpty = !folders.length;
  const dispatch = useDispatch();
  const showMediaManager = useCallback(
    () =>
      dispatch(
        pushModal(ModalType.CREATE_PREMIUM_FOLDER, {
          wrapInModal: false,
        })
      ),
    [dispatch]
  );

  const showAddItemCard = isOwner;

  const profileOwner = useSelector(getProfileOwner);
  const seeAllUrl = useShopContentUrl(
    profileOwner.user.username,
    USER_PROFILE_SHOP_SUB_PATHS.PREMIUM_FOLDERS
  );

  const renderWidgetContent = () =>
    isEmpty ? (
      <PremiumFoldersEmptyState view={'wide'} />
    ) : (
      <BiLoggerContextProvider value={{ sectionname: 'premium_folders' }}>
        {isMobile ? (
          <PremiumFolderSliderMobile
            folders={folders}
            showAddItemCard={showAddItemCard}
          />
        ) : (
          <Slider
            renderSliderItems={elementSize => (
              <>
                {showAddItemCard && (
                  <AddItemCard
                    title={t('pages.shop.premiumFolders.addItem.title')}
                    subtitle={t('pages.shop.premiumFolders.addItem.subtitle')}
                    onClick={showMediaManager}
                    nonCoreMembershipView={
                      <PremiumFoldersEmptyState view={'small'} />
                    }
                    biData={BiData<DefaultBiEvent>({
                      evid: 2,
                      click_info: 'create_premium_gallery',
                      sectionname: BiContextProvidedValue,
                    })}
                    view={'small'}
                  />
                )}
                {folders.map(folder => (
                  <FolderShopCard
                    key={folder.folderId}
                    folder={folder}
                    width={elementSize.width}
                    withAuthorDetails={false}
                    withIndicators={false}
                  />
                ))}
              </>
            )}
          />
        )}
      </BiLoggerContextProvider>
    );

  if (isEmpty && !isOwner) {
    return null;
  }

  return (
    <WidgetBox
      header={t('common.premiumFolders')}
      count={moduleData?.total}
      moduleId={moduleId}
      seeAllUrl={seeAllUrl}
      {...widgetBoxProps}
    >
      {renderWidgetContent()}
    </WidgetBox>
  );
};
PremiumFolderSlider.displayName = 'PremiumFolderSlider';

export default PremiumFolderSlider;
