import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';
import { PapiGruserModuleAdoptables } from '@wix/da-papi-types';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import AdoptableShopCard from '@wix/da-shared-react/pkg/ShopCard/AdoptableShopCard';
import { MeasuredSlider as Slider } from '@wix/da-shared-react/pkg/Slider';
import { AllowedIndicators } from '@wix/da-shared-react/pkg/DeviationViews/Thumb/legos/DeviationThumbIndicators';

import { Url, USER_PROFILE_SHOP_SUB_PATHS } from '@wix/da-url';
import DuperbrowseContext from '../../../../../contexts/DuperbrowseContext';
import AddItemCard from '../../_partials/AddItemCard';
import AdoptablesEmptyState from '../../_partials/AdoptablesEmptyState';
import AdoptableSliderMobile from './AdoptableSliderMobile';
import { getShopItemsStreamId } from '../../../../../streams/shop';
import { ShopItemType } from '../../../../../../types/shop';
import {
  getProfileOwner,
  isCurrentUserProfileOwner,
} from '../../../../../selectors/users';
import { useShopContentUrl } from '../hooks';

export interface Props {
  moduleId: number;
  widgetBoxProps: Partial<WidgetBoxProps>;
}

const AdoptableSlider: React.FC<Props & WidgetBoxProps> = ({
  moduleId,
  widgetBoxProps,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const { moduleData } = useModuleData<PapiGruserModuleAdoptables>(moduleId);

  const streamId = getShopItemsStreamId(ShopItemType.ADOPTABLES);
  const deviations = useSelector(state =>
    getDeviationsByIds(state, streamSelectors.getItems(state, streamId))
  );

  const profileOwner = useSelector(getProfileOwner);
  const seeAllUrl = useShopContentUrl(
    profileOwner.user.username,
    USER_PROFILE_SHOP_SUB_PATHS.EXCLUSIVES
  );

  const isEmpty = !deviations.length;
  const showAddItemCard = isOwner;

  if (isEmpty && !isOwner) {
    return null;
  }

  const renderWidgetContent = () =>
    isEmpty ? (
      isOwner ? (
        <AdoptablesEmptyState view={'wide'} />
      ) : null
    ) : (
      <DuperbrowseContext
        streamId={streamId}
        deviations={deviations}
        parent={{ title: t('duperbrowse.backButton.profile.shop') }}
      >
        {isMobile ? (
          <AdoptableSliderMobile
            showAddItemCard={showAddItemCard}
            deviations={deviations}
          />
        ) : (
          <Slider
            renderSliderItems={elementSize => (
              <>
                {showAddItemCard && (
                  <AddItemCard
                    title={t('pages.shop.uploadMore.var.adoptables.title')}
                    subtitle={t(
                      'pages.shop.uploadMore.var.adoptables.subtitle'
                    )}
                    onClick={() =>
                      window.location.replace(
                        Url.studio({ openSubmitForm: true })
                      )
                    }
                    nonCoreMembershipView={
                      <AdoptablesEmptyState view={'small'} />
                    }
                    biData={BiData<DefaultBiEvent>({
                      evid: 2,
                      click_info: 'create_adoptable',
                      sectionname: 'adoptable',
                    })}
                    view={'small'}
                  />
                )}
                {deviations.map(deviation => (
                  <AdoptableShopCard
                    key={deviation.deviationId}
                    width={elementSize.width}
                    deviation={deviation}
                    withAuthorDetails={false}
                    allowedIndicators={AllowedIndicators.None}
                    withOwnerDetails
                  />
                ))}
              </>
            )}
          />
        )}
      </DuperbrowseContext>
    );

  return (
    <WidgetBox
      header={t('common.adoptables')}
      count={moduleData?.total}
      moduleId={moduleId}
      seeAllUrl={seeAllUrl}
      {...widgetBoxProps}
    >
      {renderWidgetContent()}
    </WidgetBox>
  );
};

export default AdoptableSlider;
