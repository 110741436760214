import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getCsrfToken } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { isApiError, requestPuppy } from '@wix/da-http-client';
import {
  setErrorMessage,
  setIsEditorOpen,
  uploadAvatar,
} from '../actions/avatar';
import logger from '../utils/logger';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { userSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/user';
import { updateAvatar } from '@wix/da-shared-react/pkg/publicSession/actions';

import {
  MAXIMUM_GIF_AVATAR_FILESIZE,
  MAXIMUM_AVATAR_FILESIZE,
  IMAGE_QUALITY,
  AVATAR_WIDTH,
  AVATAR_HEIGHT,
  GROUP_AVATAR_WIDTH,
  GROUP_AVATAR_HEIGHT,
} from '../components/UserWidgets/ProfileIdentity/_partials/AvatarEdit/constants';
import { getIsGroupProfile, getProfileOwnerUser } from '../selectors/users';

function* updateUserAvatar(usericon) {
  const profileOwner = yield select(getProfileOwnerUser);
  const normalizedData = normalize({ ...profileOwner, usericon }, userSchema);
  yield put(updateAvatar(usericon));
  yield put({ type: 'TRIGGER.NORMALIZR.UPDATE', ...normalizedData });
}

function* toBlob({ file, image, pixelCrop }) {
  if (!file) {
    throw new Error('widgets.avatarEdit.error.noFileChosen');
  }
  if (!image) {
    throw new Error('widgets.avatarEdit.error.cannotProcessFile');
  }

  const cropImageModule = yield import(
    /* webpackChunkName: "avatarEdit" */ '../components/UserWidgets/ProfileIdentity/_partials/AvatarEdit/ImageCrop/cropImage'
  );

  const isGroup = yield select(getIsGroupProfile);

  const [width, height] = isGroup
    ? [GROUP_AVATAR_WIDTH, GROUP_AVATAR_HEIGHT]
    : [AVATAR_WIDTH, AVATAR_HEIGHT];
  const blob = yield call(
    cropImageModule.cropImage,
    file,
    image,
    pixelCrop,
    IMAGE_QUALITY,
    width,
    height
  );

  switch (blob.type) {
    case 'image/gif':
      if (blob.size > MAXIMUM_GIF_AVATAR_FILESIZE) {
        throw new Error('widgets.avatarEdit.error.maximumGifFileSizeExceeded');
      }
      break;
    case 'image/jpeg':
    case 'image/png':
      if (blob.size > MAXIMUM_AVATAR_FILESIZE) {
        throw new Error('widgets.avatarEdit.error.maximumFileSizeExceeded');
      }
      break;
    default:
      // unknown type
      throw new Error('widgets.avatarEdit.error.unexpectedErrorOccurred');
  }
  return blob;
}

export function* uploadAvatarSaga(action) {
  const { payload } = action;
  try {
    const { file, image, pixelCrop } = payload;
    const isGroup = yield select(getIsGroupProfile);
    const profileOwner = yield select(getProfileOwnerUser);
    const blob = yield call(toBlob, { file, image, pixelCrop });

    const formData = new FormData();
    formData.append('avatar_file', blob, file.name);
    formData.append('csrf_token', yield select(getCsrfToken));
    if (isGroup) {
      formData.append('groupname', profileOwner.username);
    }
    const response = yield call(
      requestPuppy,
      {
        url: 'user/set_avatar',
        method: 'post',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      () => ({
        usericon: 'https://a.deviantart.net/avatars-big/c/h/chris.jpg?4',
      })
    );

    if (response && !isApiError(response)) {
      const usericon = URL.createObjectURL(blob);
      yield call(updateUserAvatar, usericon);
      yield put(setIsEditorOpen(false));
    }
  } catch (err) {
    logger.error('uploadAvatarSaga', err);
    yield put(setErrorMessage(err.message));
  }
}

export default function* rootAvatarSaga() {
  yield all([takeEvery(uploadAvatar, uploadAvatarSaga)]);
}
