import React, { useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiGallection } from '@wix/da-papi-types';
import Flex from '@wix/da-ds/pkg/Flex';
import CollapsibleBlock from '@wix/da-shared-react/pkg/CollapsibleBlock';
import Button from '@wix/da-shared-react/pkg/Button';
import StickyFolderBar from '../../FolderBar/StickyFolderBar';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { normalizePremiumFolderData } from '@wix/da-shared-react/pkg/utils/normalizePremiumFolderData';
import ExcerptRenderer from '@wix/da-shared-react/pkg/TextRenderer/ExcerptRenderer';
import TextRenderer from '@wix/da-shared-react/pkg/TextRenderer';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import GallectionTiptapViewer from '@wix/da-editor-tiptap/pkg/components/Viewer/presets/Gallection';
import SubfolderCards from '../../SubfolderCards';
import { isSpecialFullyLockedFolder } from '@wix/da-shared-react/pkg/MediaPopup/helpers';

import s from './FolderDescription.scss';

export interface Props {
  folder: PapiGallection;
  className?: string;
}

const COLLAPSE_WHEN_THIS_TALL = 70;

export const FolderDescription: React.FC<Props> = ({ folder, className }) => {
  const isMobile = useContext(MobileContext);

  const { isPremiumLocked } = normalizePremiumFolderData(
    folder.premiumFolderData
  );
  const { t } = useTranslation();

  if (!folder) {
    return null;
  }
  const isPesudoFolder = isSpecialFullyLockedFolder(folder);

  if (isMobile && (!folder.hasSubfolders || isPesudoFolder)) {
    return null;
  }

  const renderDescription = () => {
    if (isPesudoFolder) {
      return null;
    }

    return (
      <CollapsibleBlock
        maxHeight={COLLAPSE_WHEN_THIS_TALL}
        className={s['collapsible-block']}
        showFade
        fadeClassName={s['collapsible-fade']}
        renderToggleButton={({ collapsed, onClick }) => (
          <Button
            view="b3"
            className={s['collapsible-button']}
            onClick={onClick as any}
          >
            {t(
              collapsed
                ? 'pages.gallections.folderDescription.readMore'
                : 'common.showLess'
            )}
          </Button>
        )}
      >
        <div className={s['description']}>
          {folder?.richDescription?.html.type === 'writer' && (
            <ExcerptRenderer
              htmlExcerptLegacy
              legacyTheme={'comments'}
              textContent={folder.richDescription}
            />
          )}
          {folder?.richDescription?.html.type === 'tiptap' && (
            <TextRenderer
              tipTapPresetId="gallection"
              tipTapViewer={GallectionTiptapViewer}
              cookieType={MeasuredCookieType.GALLECTION_FOLDER}
              textContent={folder.richDescription}
            />
          )}
        </div>
      </CollapsibleBlock>
    );
  };

  return (
    <div className={classnames(s['root'], className)}>
      <Flex direction="column">
        {!isMobile && <StickyFolderBar currentFolder={folder} />}
        {!isPremiumLocked && renderDescription()}
        {<SubfolderCards folder={folder} />}
      </Flex>
    </div>
  );
};
FolderDescription.displayName = 'FolderDescription';

export default FolderDescription;
