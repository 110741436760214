import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { PapiModuleGallectionFolders } from '@wix/da-papi-types';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { GALLECTION_FOLDERS_PER_FETCH } from '../../../../../../streams';
import {
  getStreamIdForGallectionType,
  normalizeGallectionList,
} from '../../../../../../utils/gallection';
import { GallectionSourceType } from '../../../../../../../types/gallection';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleGallectionFolders,
  requestParams: any
) => {
  const gallectionType =
    requestParams.gallectionType ??
    (moduleData?.results?.[0]?.type as GallectionSourceType);

  if (!gallectionType || !moduleData) {
    return [];
  }

  const { results: folders = [] } = moduleData;

  const { result, entities } = normalizeGallectionList(folders, gallectionType);

  return [
    withOffset.actions.initialize({
      streamId: getStreamIdForGallectionType(gallectionType),
      streamParams: {
        moduleId: module.id,
        gallectionType,
      },
      itemsPerFetch: GALLECTION_FOLDERS_PER_FETCH,
      items: result,
      entities,
      hasMore: moduleData.hasMore,
      nextOffset: moduleData.nextOffset ?? undefined,
      hasLess: moduleData.hasLess,
      prevOffset: moduleData.prevOffset,
    }),
    updateModule(gruser, {
      id: moduleId,
      moduleMeta: {
        isInitialized: true,
      },
    }),
  ] as any;
};
