import React, { useState, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import Modal from '@wix/da-ds/pkg/modals/dsPresets/Modal';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { REPORT_REASONS, ReportStep } from './types';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  reportProfileSubmit,
  resetReportProfile,
} from '../../actions/reportProfile';
import {
  selectIsSubmitting,
  selectReportSubmitted,
} from '../../selectors/reportProfile';
import { getUserById } from '@wix/da-shared-react/pkg/redux/users/selectors';

import s from './ReportProfileModal.scss';

export interface Props {
  requestClose: () => void;
  userId: number;
  initialStep?: ReportStep;
}

interface FormData {
  reason: string;
  details: string;
}

const ReportProfileModal: React.FC<Props> = ({
  requestClose,
  userId,
  initialStep = ReportStep.StepOne,
}) => {
  const reportSubmitted = useSelector(selectReportSubmitted);
  const isSubmitting = useSelector(selectIsSubmitting);
  const user = useSelector(state => getUserById(state, userId));
  const [currentStep, setCurrentStep] = useState<ReportStep>(initialStep);

  const { t } = useTranslation();

  const methods = useForm<FormData>({
    mode: 'onChange',
    shouldUnregister: false,
  });

  const { handleSubmit, watch, reset } = methods;
  const selectedReason = watch('reason');
  const details = watch('details');

  const dispatch = useDispatch();

  // Watch for reportSubmitted changes
  useEffect(() => {
    if (reportSubmitted) {
      setCurrentStep(ReportStep.StepThree);
    }
  }, [reportSubmitted]);

  const handleNext = () => {
    if (currentStep === ReportStep.StepOne) {
      setCurrentStep(ReportStep.StepTwo);
    }
  };

  const handleBack = () => {
    if (currentStep === ReportStep.StepTwo) {
      setCurrentStep(ReportStep.StepOne);
    }
  };

  const onSubmitForm = async (data: FormData) => {
    try {
      dispatch(
        reportProfileSubmit({
          userId,
          reason: data.reason,
          details: data.details,
        })
      );
    } catch (err) {}
  };

  const handleClose = () => {
    dispatch(resetReportProfile());
    // we need to reset state when modal is closed
    setTimeout(() => {
      setCurrentStep(ReportStep.StepOne);
      reset();
    }, 300);

    requestClose();
  };

  const renderTitle = () => {
    const selectedReasonData = REPORT_REASONS.find(
      r => r.id === selectedReason
    );
    switch (currentStep) {
      case ReportStep.StepOne:
      default:
        return t('report.title');
      case ReportStep.StepTwo:
        return selectedReasonData ? t(selectedReasonData.label) : '';
      case ReportStep.StepThree:
        return t('report.success.title');
    }
  };

  const renderContent = () => {
    switch (currentStep) {
      default:
      case ReportStep.StepOne:
        return <StepOne />;
      case ReportStep.StepTwo:
        return <StepTwo />;
      case ReportStep.StepThree:
        return <StepThree user={user} />;
    }
  };

  const renderFooter = (): ReactNode[] => {
    switch (currentStep) {
      case ReportStep.StepOne:
        return [
          <TextButton
            key="cancel"
            onClick={handleClose}
            size="small"
            variant="main"
          >
            {t('common.cancel')}
          </TextButton>,
          <SolidButton
            key="next"
            onClick={handleNext}
            disabled={!selectedReason}
            size="large"
            variant="approval-green"
          >
            {t('pagination.next')}
          </SolidButton>,
        ];
      case ReportStep.StepTwo:
        return [
          <TextButton
            key="back"
            onClick={handleBack}
            disabled={isSubmitting}
            size="small"
            variant="main"
          >
            {t('common.back')}
          </TextButton>,
          <SolidButton
            key="submit"
            onClick={handleSubmit(onSubmitForm)}
            variant="approval-green"
            loading={isSubmitting}
            disabled={isSubmitting || !details}
            size="large"
          >
            {t('common.submit')}
          </SolidButton>,
        ];
      case ReportStep.StepThree:
        return [
          <SolidButton
            key="close"
            onClick={handleClose}
            size="large"
            variant="approval-green"
          >
            {t('common.close')}
          </SolidButton>,
        ];
      default:
        return [];
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={handleClose}
      heading={renderTitle()}
      showFooterBorder
      showHeaderBorder
      footerRight={renderFooter()}
      className={s['root']}
    >
      <section className={s['content']}>
        <FormProvider {...methods}>{renderContent()}</FormProvider>
      </section>
    </Modal>
  );
};
ReportProfileModal.displayName = 'ReportProfileModal';
export default ReportProfileModal;
