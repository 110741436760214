import {
  getCustomInitActionsForWidgets,
  getPageInfoInitActions,
} from './common';
import { getModuleDataFromPapiResponse } from '@wix/da-gruser-shared/pkg/helpers/modules';
import {
  setGallectionSectionType,
  setSelectedFolderId,
  setSelectedSubfolderId,
} from '../../actions/gallectionSection';
import { getGallectionTypeFromSection } from '../../utils/gallection';
import { getGallectionWidgetConfigs } from '../../components/Sections/GallectionsSection/gallectionWidgetConfigs';

export const setSelectedFolderAndSubfolder = responseData => {
  const moduleData = getModuleDataFromPapiResponse(responseData);
  const folders = (moduleData['folders']['results'] || []).flatMap(folder => [
    folder,
    ...(folder.subfolders ?? []),
  ]);
  const selectedFolderId = moduleData['folderDeviations'].folderId;
  const selectedFolder = folders.find(
    folder => folder.folderId === selectedFolderId
  );
  if (!selectedFolder) {
    return [];
  } else if (selectedFolder.parentId) {
    return [
      setSelectedFolderId(selectedFolder.parentId),
      setSelectedSubfolderId(selectedFolder.folderId),
    ];
  } else {
    return [setSelectedFolderId(selectedFolder.folderId)];
  }
};

export const getCustomInitActionsForGallectionWidgets = (
  section: 'gallery' | 'favourites' | 'private_collections',
  responseData,
  requestParams
) => [
  ...getCustomInitActionsForWidgets(
    responseData,
    requestParams,
    getGallectionWidgetConfigs({ t: () => null, dispatch: () => null })
  ),
  setGallectionSectionType(getGallectionTypeFromSection(section)),
  ...getPageInfoInitActions(responseData, requestParams),
  ...setSelectedFolderAndSubfolder(responseData),
];
