import React, { useCallback, useContext } from 'react';
import c from 'classnames';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import { PapiGallection, PapiPremiumFolderData } from '@wix/da-papi-types';
import { normalizePremiumFolderData } from '@wix/da-shared-react/pkg/utils/normalizePremiumFolderData';
import DeviationDumbView from '@wix/da-shared-react/pkg/DeviationViews/Dumbo';
import ShareIcon from '@wix/da-ds/pkg/Icons/24x24/Share';
import LinkIcon from '@wix/da-ds/pkg/Icons/24x24/Link';
import { ScreenSizeContext } from '@wix/da-hooks/pkg/useMeasureElement/ScreenSizeContext';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import IconButton from '@wix/da-ds/pkg/Buttons/IconButton';
import FolderInfo from './FolderInfo';
import ExcerptRenderer from '@wix/da-shared-react/pkg/TextRenderer/ExcerptRenderer';
import { addNotification } from '@wix/da-shared-react/pkg/Notifications/redux/actionCreators';
import { NotificationType } from '@wix/da-shared-react/pkg/Notifications';
import TextRenderer from '@wix/da-shared-react/pkg/TextRenderer';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import GallectionTiptapViewer from '@wix/da-editor-tiptap/pkg/components/Viewer/presets/Gallection';

import s from './FolderPaywallLayout.scss';

export interface Props {
  className?: string;
  icon: React.ReactNode;
  button: React.ReactNode;
  nonClickable?: boolean;
  folder: PapiGallection;
  premiumFolderData: PapiPremiumFolderData;
}

const FolderPaywallLayout: React.FC<Props> = ({
  icon,
  button,
  nonClickable = false,
  folder,
  premiumFolderData,
}) => {
  const { t } = useTranslation();
  const {
    formattedDollarPrice,
    numSubscribers,
    isPaidFolder,
    isWatchToAccess,
  } = normalizePremiumFolderData(folder.premiumFolderData);
  const { width: screenWidth } = useContext(ScreenSizeContext);
  const isMobile = useContext(MobileContext);
  const dispatchAddNotification = useAction(addNotification);
  const onCopyLinkClick = useCallback(() => {
    if (!isMobile) {
      copy(premiumFolderData.galleryUrl || '');
      dispatchAddNotification(NotificationType.Generic, {
        messageLocaleKey: 'share.linkCopied',
        cardProps: {
          iconComponent: LinkIcon,
        },
      });
    }
  }, [
    isMobile,
    premiumFolderData,
    dispatchAddNotification,
  ]);

  const itemCount = folder.totalItemCount ?? folder.size;
  const mobileSectionMargin = parseInt(s['mobile-section-margin'], 10);
  const thumb = folder.thumb ?? folder?.additionalThumbs?.[0];

  return (
    <OverlayContextProvider
      value={{
        showPremiumFolderOverlay: false,
        showPremiumFolderIcon: false,
        showThumbStatusIndicators: false,
      }}
    >
      <div className={s['root']}>
        <div className={s['cover']}>
          {thumb && (
            <>
              <div className={s['icon']}>{icon}</div>
              <DeviationDumbView
                className={s['cover-image']}
                deviation={thumb}
                width={isMobile ? screenWidth - 2 * mobileSectionMargin : 319}
              />
            </>
          )}
        </div>
        <div className={s['info-wrap']}>
          {(isPaidFolder || isWatchToAccess) && (
            <div className={s['folder-type']}>
              {t(
                isPaidFolder
                  ? 'paywall.premium.folder.title'
                  : 'paywall.watchersOnly.folder.title'
              )}
            </div>
          )}
          <div className={s['title-wrap']}>
            <div className={s['title']}>
              <div className={s['folder-name']}>{folder.name}</div>
              {!isMobile && (
                <FolderInfo
                  className={s['sub']}
                  deviationCount={itemCount}
                  numSubscribers={numSubscribers}
                />
              )}
            </div>
            {isPaidFolder && (
              <div className={s['title-price']}>{formattedDollarPrice}</div>
            )}
          </div>
          {isMobile && (
            <FolderInfo
              className={s['sub']}
              deviationCount={itemCount}
              numSubscribers={numSubscribers}
            />
          )}
          {folder.richDescription && (
            <div className={s['text']}>
              {folder?.richDescription?.html.type === 'writer' && (
                <ExcerptRenderer
                  htmlExcerptLegacy
                  legacyTheme={'comments'}
                  textContent={folder.richDescription}
                />
              )}
              {folder?.richDescription?.html.type === 'tiptap' && (
                <TextRenderer
                  tipTapPresetId="gallection"
                  tipTapViewer={GallectionTiptapViewer}
                  cookieType={MeasuredCookieType.GALLECTION_FOLDER}
                  textContent={folder.richDescription}
                />
              )}
            </div>
          )}
          {(isPaidFolder || isWatchToAccess) && (
            <div className={s['tiny-text']}>
              {t(
                isPaidFolder
                  ? 'paywall.gallery.purchaseInfo'
                  : 'paywall.gallery.watchInfo'
              )}
            </div>
          )}
          <div className={s['actions-wrapper']}>
            <span
              className={c(
                s['button'],
                nonClickable && s['non-clickable-button']
              )}
            >
              {button}
            </span>
            <div className={s['actions']}>
              <IconButton
                size="medium"
                icon={ShareIcon}
                iconClassName={s['action-icon']}
                tooltipText={t('share.copyLink')}
                aria-label={t('share.copyLink')}
                onClick={onCopyLinkClick}
              />
            </div>
          </div>
        </div>
      </div>
    </OverlayContextProvider>
  );
};
FolderPaywallLayout.displayName = 'BasePaywall';

export default FolderPaywallLayout;
