import get from 'lodash/get';
import { AppState } from '../../types/store';
import {
  MySubmissionsSortBy,
  StatsTimePeriod,
  MySubmissionItem,
  GeneralStats,
  DeviceStats,
  CountryStats,
  BenchmarkItem,
  BenchmarkSortBy,
} from '../../types/statsSection';
import { PapiUser, PapiModuleVisitor } from '@wix/da-papi-types';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { getProfileGruser, getProfileOwnerUser } from './users';
import { getModuleDataByModuleName } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';

export const getHasCalculatedStats = (state: AppState) =>
  !!getDetailedStats(state).hasCalculatedStats;

export const getDetailedStats = state => {
  const gruser = getProfileGruser(state);
  return getModuleDataByModuleName(state, gruser, 'userstatsDetailed') || {};
};

export const getTimePeriod = (state: AppState): StatsTimePeriod =>
  state.statsSection.timePeriod;

export const getStatsOverviewData = (state: AppState) => {
  return {
    overviewProfile: getDataForCurrentTimePeriod(state, 'overviewProfile', []),
    overviewDeviations: getDataForCurrentTimePeriod(
      state,
      'overviewDeviations',
      []
    ),
    overviewWatchers: getDataForCurrentTimePeriod(
      state,
      'overviewWatchers',
      []
    ),
    overviewFaved: getDataForCurrentTimePeriod(state, 'overviewFaved', []),
  };
};

export const currentOverviewTotals = (state: AppState, fallback) =>
  getDataForCurrentTimePeriod(state, 'overviewTotal', fallback);

export const getMySubmissionsSortBy = (state: AppState): MySubmissionsSortBy =>
  state.statsSection.mySubmissionsSortBy;

export const getMySubmissionsIsLoading = (state: AppState) =>
  state.statsSection.mySubmissionsIsLoading;

export const getMySubmissionsItems = (state: AppState): MySubmissionItem[] =>
  getDataForCurrentTimePeriod(state, 'myContent', []);

export const getBenchmarkSortBy = (state: AppState): BenchmarkSortBy =>
  state.statsSection.benchmarkSortBy;

export const getBenchmarkIsLoading = (state: AppState) =>
  state.statsSection.benchmarkIsLoading;

export const getBenchmarkItems = (state: AppState): BenchmarkItem[] => {
  const profileOwner = getProfileOwnerUser(state);
  const hasCore = getCurrentUserHasCore(state);
  const data = getDataForCurrentTimePeriod(state, 'compareYourself', []);
  const sortByField = state.statsSection.benchmarkSortBy;

  const sortByFunc = hasCore
    ? (a, b) => b[sortByField] - a[sortByField]
    : (a, b) => (b.user.userId === profileOwner.userId ? 1 : -1);

  const sortedData = [...data].sort(sortByFunc);

  return sortedData;
};

export const getBenchmarkUserIds = (state: AppState): number[] =>
  getBenchmarkItems(state).map(benchmarkItem => benchmarkItem.user.userId);

export const getMatchingUsers = (state: AppState): PapiUser[] => {
  const matchingUsers: PapiUser[] = get(
    state,
    'statsSection.matchingUsers',
    []
  );
  const benchmarkUserIds = getBenchmarkUserIds(state);
  return matchingUsers.filter(
    matchingUser => !benchmarkUserIds.includes(matchingUser.userId)
  );
};

export const getBoostDeviationStats = (state: AppState) => {
  const stats = getDetailedStats(state);
  return stats.boostDeviations?.timePeriods?.all || [];
};

export const getBoostTierStats = (state: AppState) => {
  const stats = getDetailedStats(state);
  return stats.boostTiers?.timePeriods?.all || [];
};

export const getGeneralStats = (state: AppState): GeneralStats =>
  getDataForCurrentTimePeriod(state, 'general', {});

export const getRecentVisitors = (state: AppState): PapiModuleVisitor =>
  getDataForCurrentTimePeriod(state, 'recentVisitors', []);

export const getVisitorsIsLoading = (state: AppState) =>
  state.statsSection.visitorsIsLoading;

export const getWatchers = (state: AppState): PapiUser[] =>
  getDataForCurrentTimePeriod(state, 'recentWatchers', {});

export const getWatching = (state: AppState): PapiUser[] =>
  getDataForCurrentTimePeriod(state, 'recentWatching', {});

export const getDeviceStats = (state: AppState): DeviceStats =>
  getDataForCurrentTimePeriod(state, 'trafficByDevice', []);

export const getCountryStats = (state: AppState): CountryStats =>
  getDataForCurrentTimePeriod(state, 'trafficByCountry', []);

const getDataForCurrentTimePeriod = (state, key, fallback) => {
  const timePeriod = getTimePeriod(state);
  const stats = getDetailedStats(state);
  return get(stats, `${key}.timePeriods[${timePeriod}]`, fallback);
};
