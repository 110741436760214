import { getCustomInitActionsForAboutWidgets } from '../../../store/initActions/about';
import { getCustomInitActionsForAdoptabobblesWidgets } from '../../../store/initActions/adoptabobbles';
import { getCustomInitActionsForBackroomWidgets } from '../../../store/initActions/backroom';
import { getCustomInitActionsForGallectionWidgets } from '../../../store/initActions/gallections';
import { getCustomInitActionsForHomeWidgets } from '../../../store/initActions/home';
import { getCustomInitActionsForPostWidgets } from '../../../store/initActions/posts';
import { getCustomInitActionsForShopWidgets } from '../../../store/initActions/shop';
import { getCustomInitActionsForSubscriptionWidgets } from '../../../store/initActions/subscriptions';

export const getCustomInitActionsForWidgets = ({
  requestParams,
  initData,
  section,
}) => {
  let customInitActionsForWidgets = [] as any[];

  switch (section) {
    case 'about':
      customInitActionsForWidgets = getCustomInitActionsForAboutWidgets(
        initData,
        requestParams
      );

      break;
    case 'home':
      customInitActionsForWidgets = getCustomInitActionsForHomeWidgets(
        initData,
        requestParams
      );
      break;
    case 'gallery':
    case 'favourites':
    case 'private_collections':
      customInitActionsForWidgets = getCustomInitActionsForGallectionWidgets(
        section,
        initData,
        { ...requestParams, section }
      );
      break;
    case 'posts':
      customInitActionsForWidgets = getCustomInitActionsForPostWidgets(
        initData,
        requestParams
      );
      break;
    case 'shop':
      customInitActionsForWidgets = getCustomInitActionsForShopWidgets(
        initData,
        requestParams
      );
      break;
    case 'subscriptions':
      customInitActionsForWidgets = getCustomInitActionsForSubscriptionWidgets(
        initData,
        requestParams
      );
      break;
    case 'backroom':
      customInitActionsForWidgets = getCustomInitActionsForBackroomWidgets(
        initData,
        requestParams
      );
      break;
    case 'adoptabobbles':
      customInitActionsForWidgets = getCustomInitActionsForAdoptabobblesWidgets(
        initData,
        requestParams
      );
      break;
    default:
      customInitActionsForWidgets = [];
  }
  return customInitActionsForWidgets.filter(action => action);
};
