import React from 'react';
import { Route as RouteDefinition } from '../../../types/route';
import { ProfileOwnerGiveState } from '../../../types/profileOwner';
import SubNavBarDesktop from './Desktop';
import SubNavBarMobile from './Mobile';
import { PapiUser } from '@wix/da-papi-types';
import { withMobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { RestrictLoggedOutPayload } from '@wix/da-shared-react/pkg/redux/loggedout/actionCreators';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';

export interface SubNavBarProps {
  routes: RouteDefinition[];
  profileOwnerUser: PapiUser;
  giveState?: ProfileOwnerGiveState;
  isProfileOwnerWatchingCurrentUser: boolean;
  isOwner: boolean;
  isLoggedIn: boolean;
  isMobile: boolean;
  pushBadgeModal: typeof modalActions.pushBadgeModal;
  pushPointsModal: (username: string, title: string) => void;
  initGiveState: () => void;
  onLlamaBadgeSent: () => void;
  restrictLoggedOut: (payload: RestrictLoggedOutPayload) => void;
  biClickProfileNavBar: (linkName: string) => void;
  id?: string;
  children?: any;
}

class SubNavBar extends React.Component<SubNavBarProps> {
  render() {
    return this.props.isMobile ? (
      <SubNavBarMobile {...this.props} />
    ) : (
      <SubNavBarDesktop {...this.props} />
    );
  }
}

export default withMobileContext(SubNavBar);
