import React, { useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { PapiGallection } from '@wix/da-papi-types';
import { useEffectOnceOnMount } from '@wix/da-hooks/pkg/useEffectOnceOnMount';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import DesktopGallectionSimpleView from './DesktopGallectionSimpleView';
import MobileGallectionSimpleView from './MobileGallectionSimpleView';
import { useNonEmptyGallectionFolders } from '../hooks/useGallectionFolders';

export type Props = {
  currentFolder?: PapiGallection;
  hideFolderSlider?: boolean;
};

const GallectionSimpleView: React.FC<
  Props & RouteComponentProps<{ folderId?: string }>
> = ({
  currentFolder,
  hideFolderSlider,
  match: {
    params: { folderId },
  },
}) => {
  const isMobile = useContext(MobileContext);
  const { folders } = useNonEmptyGallectionFolders();

  useEffectOnceOnMount(() => {
    if (!folderId) {
      return;
    }

    const intFolderId = +folderId;
    const parentId = folders.find(
      item => item.folderId === intFolderId
    )?.parentId;
    const anchor = document.getElementById('sub-folder-gallery');

    if (!parentId || !anchor) {
      return;
    }

    anchor.scrollIntoView();
  });

  if (isMobile) {
    return <MobileGallectionSimpleView currentFolder={currentFolder} />;
  }
  return (
    <DesktopGallectionSimpleView
      currentFolder={currentFolder}
      hideFolderSlider={hideFolderSlider}
    />
  );
};
GallectionSimpleView.displayName = 'GallectionSimpleView';

export default withRouter(GallectionSimpleView) as React.ComponentType<Props>;
