import React, { useRef, RefObject } from 'react';
import classnames from 'classnames';
import EllipsisIcon from '@wix/da-ds/pkg/Icons/Ellipsis';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import useMoreActionItems from './useMoreActionItems';

import s from './MoreActionsDropdownMobile.scss';

export interface Props {
  className?: string;
  iconClassName?: string;
  initGiveState: () => void;
}

export const MoreActionsDropdownMobile: React.FC<Props> = ({
  className,
  initGiveState,
  iconClassName,
}) => {
  const items = useMoreActionItems();
  const dropdownRef: RefObject<any> = useRef(undefined);
  const logEvent = useBiLogger();

  const handleClick = () => {
    const clickedIndex = dropdownRef.current.value;
    const selectedItem = items[clickedIndex];
    selectedItem.biData && logEvent(selectedItem.biData);
    selectedItem.onClick();
    dropdownRef.current.value = '-1';
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={classnames(s['root'], className)}>
      <div className={s['open-btn']}>
        <EllipsisIcon className={iconClassName} />
      </div>
      <select
        ref={dropdownRef}
        onClick={initGiveState}
        onChange={handleClick}
        defaultValue="-1"
      >
        <option hidden disabled value="-1" />
        {items.map((item, i) => (
          <option key={i} value={i} disabled={item.disabled}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MoreActionsDropdownMobile;
