import React from 'react';
import { Url } from '@wix/da-url';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';
import { useMeasureElementCookie } from '@wix/da-hooks/pkg/useMeasureElement/useMeasureElementCookie';
import { PapiDeviation } from '@wix/da-papi-types';
import AdoptableShopCard from '@wix/da-shared-react/pkg/ShopCard/AdoptableShopCard';
import { AllowedIndicators } from '@wix/da-shared-react/pkg/DeviationViews/Thumb/legos/DeviationThumbIndicators';
import SwipeSlider from '@wix/da-shared-react/pkg/SwipeSlider.v2';
import { ShopItemType } from '../../../../../../../types/shop';
import UploadMore from '../../../_partials/UploadMore';
import { useSubmitUrl } from '@wix/da-shared-react/pkg/utils/hooks/useSubmitUrl';

interface Props {
  showAddItemCard?: boolean;
  deviations: PapiDeviation[];
}

const AdoptableSliderMobile: React.FC<Props> = ({
  showAddItemCard,
  deviations,
}) => {
  const { ref, dimensions: measuredContainer } = useMeasureElementCookie(
    undefined,
    {
      cookieDim: 'width',
    }
  );
  const SUBMIT_URL = useSubmitUrl(true);

  const DEFAULT_CARD_WIDTH = 320;
  const DEFAULT_CARD_HEIGHT = 292;

  const cardWidthMobile = measuredContainer?.width ?? DEFAULT_CARD_WIDTH;

  return (
    <div ref={ref}>
      <SwipeSlider startSlide={showAddItemCard ? 1 : 0}>
        {showAddItemCard && (
          <UploadMore
            itemType={ShopItemType.ADOPTABLES}
            height={DEFAULT_CARD_HEIGHT}
            biData={BiData<DefaultBiEvent>({
              evid: 2,
              click_info: 'create_adoptable',
              sectionname: 'adoptable',
            })}
            onCreate={() => window.location.replace(Url.to(SUBMIT_URL))}
          />
        )}
        {deviations.map(deviation => (
          <AdoptableShopCard
            key={deviation.deviationId}
            width={cardWidthMobile}
            deviation={deviation}
            withAuthorDetails={false}
            allowedIndicators={AllowedIndicators.None}
            withOwnerDetails
          />
        ))}
      </SwipeSlider>
    </div>
  );
};
AdoptableSliderMobile.displayName = 'AdoptableSliderMobile';

export default AdoptableSliderMobile;
