import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { PapiUser } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import s from './StepThree.scss';

interface Props {
  user: PapiUser;
}

const StepThree: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleBlockUser = () => {
    dispatch(
      pushModal(ModalType.USER_BLOCK, {
        params: {
          user,
          redirectLocation: Url.browseHomeLink(),
        },
      })
    );
  };

  return (
    <section className={s['container']}>
      <main>
        <p className={s['message']}>
          {t('report.success.message', {
            postProcess: ['reactPostprocessor', 'featureBrancher'],
            br: <br />,
          })}
        </p>
        <GhostButton variant="main" size="large" onClick={handleBlockUser}>
          {t('report.success.blockDeviant')}
        </GhostButton>
      </main>
    </section>
  );
};

export default StepThree;
