import React from 'react';
import { compose } from 'redux';
import {
  BiContextProvidedValue,
  BiData,
  DefaultBiEvent,
} from '@wix/da-bi/pkg/events';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { ShopItemType } from '../../../../../types/shop';
import withSectionFetchSpinner from '../../../../decorators/withSectionFetchSpinner';
import ShopGrid from '../ShopGrid';
import PremiumFoldersEmptyState from '../_partials/PremiumFoldersEmptyState';
import UploadMore from '../_partials/UploadMore';

import commonStyles from '../ShopSection.scss';

export interface Props {
  className?: string;
  onCreate: () => void;
}

const enhance = compose(withSectionFetchSpinner);

export const ShopPremiumFolders: React.FC<Props> = ({
  className,
  onCreate,
}) => {
  return (
    <div className={commonStyles['section-with-no-header']}>
      <BiLoggerContextProvider value={{ sectionname: 'premium_folders' }}>
        <ShopGrid
          className={className}
          itemType={ShopItemType.PREMIUM_FOLDERS}
          emptyState={<PremiumFoldersEmptyState view={'large'} />}
          uploadMore={
            <UploadMore
              itemType={ShopItemType.PREMIUM_FOLDERS}
              onCreate={onCreate}
              view="small"
              biData={BiData<DefaultBiEvent>({
                evid: 2,
                click_info: 'create_premium_gallery',
                sectionname: BiContextProvidedValue,
              })}
            />
          }
        />
      </BiLoggerContextProvider>
    </div>
  );
};
ShopPremiumFolders.displayName = 'ShopPremiumFolders';

export default enhance(ShopPremiumFolders) as React.ComponentType<Props>;
