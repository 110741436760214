import { handleActions } from 'redux-actions';
import {
  setSelectedFolderId,
  setSearchInputValue,
  setSearchQuery,
  setSortOrder,
  setGallectionSectionType,
  unsetSelectedFolderId,
  setSelectedSubfolderId,
  unsetSelectedSubfolderId,
  fetchSubFolders,
  fetchSubFoldersDone,
  fetchSubFoldersError,
  fetchSubFoldersStart,
} from '../actions/gallectionSection';
import GallectionSection from '../../types/gallectionSection';
import { GallectionSortOrder } from '../../types/gallection';
import { GallectionType } from '@wix/da-shared-react/pkg/types/gallection';

const defaultState: GallectionSection = {
  type: GallectionType.GALLERY,
  sortOrder: GallectionSortOrder.NEWEST,
  searchInputValue: '',
  searchQuery: '',
  currentPage: 1,
  totalPages: 1,
  subFolderFetchState: {},
};

export default handleActions<GallectionSection, any>(
  {
    [setGallectionSectionType.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setGallectionSectionType>
    ) => ({
      ...state,
      type: action.payload,
    }),
    [setSelectedFolderId.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setSelectedFolderId>
    ) => ({
      ...state,
      selectedFolderId: action.payload,
      currentPage: 1,
    }),
    [unsetSelectedFolderId.toString()]: (state: GallectionSection) => ({
      ...state,
      selectedFolderId: null,
    }),
    [setSelectedSubfolderId.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setSelectedSubfolderId>
    ) => ({
      ...state,
      selectedSubfolderId: action.payload,
      currentPage: 1,
    }),
    [unsetSelectedSubfolderId.toString()]: (state: GallectionSection) => ({
      ...state,
      selectedSubfolderId: null,
    }),
    [setSortOrder.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setSortOrder>
    ) => ({
      ...state,
      sortOrder: action.payload || state.sortOrder,
    }),
    [setSearchInputValue.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setSearchInputValue>
    ) => ({
      ...state,
      searchInputValue: action.payload,
    }),
    [setSearchQuery.toString()]: (
      state: GallectionSection,
      action: ReturnType<typeof setSearchQuery>
    ) => ({
      ...state,
      searchQuery: action.payload || '',
    }),
    [fetchSubFoldersStart.toString()]: (
      state: GallectionSection,
      { payload: { type, parentId } }: ReturnType<typeof fetchSubFolders>
    ) => ({
      ...state,
      subFolderFetchState: {
        ...state.subFolderFetchState,
        [`${type}-${parentId}`]: {
          isFetching: true,
          isError: false,
          isDone: false,
        },
      },
    }),
    [fetchSubFoldersDone.toString()]: (
      state: GallectionSection,
      { payload: { type, parentId } }: ReturnType<typeof fetchSubFoldersDone>
    ) => ({
      ...state,
      subFolderFetchState: {
        ...state.subFolderFetchState,
        [`${type}-${parentId}`]: {
          isFetching: false,
          isDone: false,
          isError: false,
        },
      },
    }),
    [fetchSubFoldersError.toString()]: (
      state: GallectionSection,
      { payload: { type, parentId } }: ReturnType<typeof fetchSubFoldersError>
    ) => ({
      ...state,
      subFolderFetchState: {
        ...state.subFolderFetchState,
        [`${type}-${parentId}`]: {
          isError: true,
          isFetching: false,
          isDone: false,
        },
      },
    }),
  },
  defaultState
);
