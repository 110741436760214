import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { PapiUser } from '@wix/da-papi-types';
import {
  requestJoinGroup,
  messageAdmins,
} from '@wix/da-shared-react/pkg/redux/groups/actions';
import { AppState } from '../../../../types/store';
import {
  getProfileOwnerUser,
  getProfileOwnerStat,
  getProfileOwnerExtendedGroup,
} from '../../../selectors/users';
import { getCanInviteToGroup, getCanJoinGroup } from '../../../selectors/privs';

import { inviteToGroup } from '../../../actions/groups';
import { Props } from './GroupAbout';

type StateProps = Pick<
  Props,
  | 'profileOwnerUser'
  | 'admins'
  | 'pageviews'
  | 'members'
  | 'canJoin'
  | 'canInvite'
>;
type DispatchProps = Pick<
  Props,
  'messageAdmins' | 'inviteToGroup' | 'requestToJoin'
>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps
) => {
  const extendedGroup = getProfileOwnerExtendedGroup(state);
  return {
    profileOwnerUser: getProfileOwnerUser(state),
    admins: extendedGroup?.adminCount || 0,
    pageviews: getProfileOwnerStat(state, 'pageviews'),
    members: extendedGroup?.membersCount || 0,
    canJoin: getCanJoinGroup(state),
    canInvite: getCanInviteToGroup(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps
) => ({
  messageAdmins: (group: PapiUser) => dispatch(messageAdmins(group)),
  requestToJoin: (group: PapiUser) => dispatch(requestJoinGroup(group)),
  inviteToGroup: (group: PapiUser) => dispatch(inviteToGroup(group)),
});

export default connect(mapStateToProps, mapDispatchToProps);
