import React from 'react';
import { withRouter } from 'react-router-dom';
import { Route, RouteComponentProps, matchPath } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { setRef } from '@wix/da-ds/pkg/utils/refHelpers';
import UnderlinedTabs from '@wix/da-ds/pkg/Tabs/dsPresets/UnderlinedTabs';
import { Nav } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import classnames from 'classnames';
import HiddenIcon from '@wix/da-ds/pkg/Icons/Hidden';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { Route as RouteDefinition } from '../../../../types/route';
import NavLink from './NavLink';

import s from './SubNavBarMobile.scss';

export interface SubNavBarProps {
  routes: RouteDefinition[];
  isSticky: boolean;
  biClickProfileNavBar: (linkName: string) => void;
}

class SubNavBarMobileInner extends React.PureComponent<
  SubNavBarProps & WithTranslation & RouteComponentProps<any>
> {
  navRef: React.RefObject<HTMLElement | null> = React.createRef();
  navListRef: React.RefObject<HTMLElement | null> = React.createRef();
  activeRouteRef: React.RefObject<any> = React.createRef();

  componentDidMount() {
    this.scrollToActive();
  }

  render() {
    const { isSticky, routes, biClickProfileNavBar, t } = this.props;
    return (
      <Nav
        className={classnames(
          s['navbar'],
          s['design-update'],
          isSticky && s['sticky']
        )}
        ref={this.navRef as any}
      >
        <VisuallyHidden>
          <Heading>{t('navigation.subnavigation.heading')}</Heading>
        </VisuallyHidden>

        <div className={s['nav-list']} ref={this.navListRef as any}>
          <Route
            key="nav-user"
            path="/:username"
            render={({ match, location }) => (
              <UnderlinedTabs
                className={s['nav-list']}
                activeItemRef={node => setRef(this.activeRouteRef, node)}
                activeIndex={routes.findIndex(
                  route =>
                    // react-router is having trouble letting us know which route is the active one outside
                    !!matchPath(location.pathname, {
                      path: route.path,
                      exact: route.exact,
                    })
                )}
                items={routes.map(
                  (route, index) =>
                    ({
                      prefix: route.isPrivate && (
                        <HiddenIcon
                          size={IconSize.TINY}
                          aria-label={t('navigation.route.a11y.private')}
                        />
                      ),
                      label: (
                        <NavLink
                          key={route.key}
                          to={(route.path || '')
                            .replace(/:username/, match.params.username)
                            .replace(/\/:[a-z0-9_()\\+]+\?/gi, '')}
                          exact={route.exact}
                          onClick={() => biClickProfileNavBar(route.key)}
                        >
                          {t(`navigation.route.var.${route.key}`)}
                        </NavLink>
                      ),
                      labelExtras: route.isBeta && (
                        <span className={s['beta']}>beta</span>
                      ),
                      index,
                    } as any)
                )}
              />
            )}
          />
        </div>
      </Nav>
    );
  }

  scrollToActive = () => {
    if (
      !this.navRef.current ||
      !this.navListRef.current ||
      !this.activeRouteRef.current
    ) {
      return;
    }

    this.navRef.current.scrollLeft =
      this.activeRouteRef.current.offsetLeft -
      this.navRef.current.getBoundingClientRect().width / 2;
  };
}

export default withRouter(withTranslation()(SubNavBarMobileInner));
