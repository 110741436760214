import React, { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import TextInput from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import SearchIcon from '@wix/da-ds/pkg/Icons/24x24/Search';
import CloseBold from '@wix/da-ds/pkg/Icons/CloseBold';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import IconButton from '@wix/da-shared-react/pkg/Button/IconButton';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import { BiData, GallerySearchClickBiEvent } from '@wix/da-bi/pkg/events';

import s from './SearchField.scss';

export interface Props {
  className?: string;
  searchInputValue: string;
  searchQuery: string;
  setSearchInputValue: (value: string) => void;
  setSearchQuery: (value: string) => void;
  clearSearch: () => void;
}

export const SearchField: React.FC<Props> = ({
  searchQuery,
  clearSearch,
  setSearchQuery,
  searchInputValue,
  className,
  setSearchInputValue,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const biLogger = useBiLogger();

  if (searchQuery && !isOpen) {
    setIsOpen(true);
  }

  const onSearchIconClick = () => {
    biLogger(
      BiData<GallerySearchClickBiEvent>({
        evid: 289,
      })
    );

    setIsOpen(true);
  };

  // Note: The close button below doesn't actually trigger this on pages without a searchQuery
  // because the onInputBlur function unmounts the button before it can launch this function.
  // We could use a ref to compare to the event, but the button below already has a BI related ref
  // passed to it inside the IconButton render function.
  const closeSearch = () => {
    clearSearch();
    setIsOpen(false);
  };

  const onInputBlur = () => {
    if (!searchQuery) {
      // Clear search here since anytime we close the search field we likely want it cleared.
      clearSearch();
      setIsOpen(false);
    }
  };

  const onInputEnter = () => {
    setSearchQuery(searchInputValue);
  };

  return (
    <div className={classnames(s['root'], isOpen && s['is-open'], className)}>
      {!isOpen && (
        <IconButton
          tooltipText={t('pages.gallections.search.searchAll')}
          onClick={onSearchIconClick}
        >
          <SearchIcon />
        </IconButton>
      )}
      {isOpen && (
        <TextInput
          autoFocus
          id="gallery-search-input"
          label={t('pages.gallections.search.searchAll')}
          isLabelHidden={true}
          value={searchInputValue}
          placeholder={t('pages.gallections.search.whatAreYouLookingFor')}
          onChange={e => setSearchInputValue(e.target.value)}
          onBlur={onInputBlur}
          postfix={
            <IconButton aria-label={t('common.close')} onClick={closeSearch}>
              <CloseBold size={IconSize.SMALL} />
            </IconButton>
          }
          onKeyDown={e => {
            switch (e.key) {
              case 'Enter':
                onInputEnter();
                break;
              case 'Escape':
                closeSearch();
                break;
              default:
                break;
            }
          }}
          noBottomSpacing
        />
      )}
    </div>
  );
};
SearchField.displayName = 'SearchField';

export default SearchField;
