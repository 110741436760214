import React from 'react';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import MailIcon from '@wix/da-ds/pkg/Icons/24x24/Mail';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { useRestrictToLoggedInAndVerified } from '@wix/da-shared-react/pkg/utils/hooks/useRestrictToLoggedInAndVerified';

export interface Props {
  className?: string;
  iconOnly?: boolean;
  user: PapiUser;
}
export const NotesButton: React.FC<Props> = ({ className, user, iconOnly }) => {
  const { restrictToLoggedInAndVerified, isLoggedIn, isVerificationNeeded } =
    useRestrictToLoggedInAndVerified();
  const { t } = useTranslation();
  const handleClick = restrictToLoggedInAndVerified(
    ReferralType.NOTE,
    'notes',
    undefined
  );
  const shouldBlockAction = isVerificationNeeded || !isLoggedIn;
  return (
    <GhostButton
      size="medium"
      className={className}
      variant="main"
      onClick={shouldBlockAction && handleClick}
      href={!shouldBlockAction ? Url.userNoteLink(user.username) : undefined}
      aria-label={t('common.message')}
    >
      {iconOnly ? <MailIcon /> : t(`common.message`)}
    </GhostButton>
  );
};
NotesButton.displayName = 'NotesButton';

export default NotesButton;
