import React from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { PapiUser } from '@wix/da-papi-types';
import EllipsisIcon from '@wix/da-ds/pkg/Icons/Ellipsis';
import {
  DropdownOption,
  NullableDropdownGroup,
} from '@wix/da-ds/pkg/Dropdown/types';
import IconButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/IconButtonWithContextMenu';

export interface Props {
  profileOwnerUser: PapiUser;
  isGroupMember: boolean;
  isWatching: boolean;
  canInviteToGroup: boolean;
  canChangeFounder: boolean;
  canLeaveGroup: boolean;
  canCloseGroup: boolean;
  canMessageAdmins: boolean;
  canRespondToGroupNotifications: boolean;
  requestChangeFounder: (group: PapiUser) => void;
  requestLeaveGroup: (group: PapiUser) => void;
  requestCloseGroup: (group: PapiUser) => void;
  toggleWatchGroup: (group: PapiUser, isWatching: boolean) => void;
  inviteToGroup: (group: PapiUser) => void;
  messageAdmins: (group: PapiUser) => void;
}

const GroupMoreMenu: React.FC<Props> = ({
  canRespondToGroupNotifications,
  profileOwnerUser,
  isGroupMember,
  isWatching,
  canMessageAdmins,
  canInviteToGroup,
  canChangeFounder,
  canLeaveGroup,
  canCloseGroup,
  requestLeaveGroup,
  requestCloseGroup,
  toggleWatchGroup,
  inviteToGroup,
  messageAdmins,
}) => {
  const { t } = useTranslation();
  const menuItems: NullableDropdownGroup[] = [];

  const communicationMenuGroup = {
    items: [] as DropdownOption[],
  };
  const farewellMenuGroup = {
    items: [] as DropdownOption[],
  };

  if (canMessageAdmins) {
    communicationMenuGroup.items.push({
      label: t('navigation.subnavigation.messageAdmins'),
      onClick: () => messageAdmins(profileOwnerUser),
    });
  }

  if (canRespondToGroupNotifications) {
    communicationMenuGroup.items.push({
      label: t('groups.admin.groupNotifications'),
      link: Url.groupNotificationsLink(profileOwnerUser.userId),
    });
  }

  if (canInviteToGroup) {
    communicationMenuGroup.items.push({
      label: t('groups.sendInvite'),
      onClick: () => inviteToGroup(profileOwnerUser),
    });
  }

  if (canLeaveGroup || canChangeFounder) {
    farewellMenuGroup.items.push({
      label: t('groups.leaveGroup'),
      onClick: () => requestLeaveGroup(profileOwnerUser),
    });
  }

  farewellMenuGroup.items.push({
    label: t(
      `navigation.subnavigation.${isWatching ? 'unwatchGroup' : 'watchGroup'}`
    ),
    onClick: () => toggleWatchGroup(profileOwnerUser, isWatching),
  });

  if (canCloseGroup) {
    farewellMenuGroup.items.push({
      label: t('groups.admin.closeGroup'),
      onClick: () => requestCloseGroup(profileOwnerUser),
    });
  }

  const isEmpty =
    communicationMenuGroup.items.length + farewellMenuGroup.items.length === 0;
  if (isEmpty) {
    return null;
  }
  menuItems.push(communicationMenuGroup);
  menuItems.push(farewellMenuGroup);

  return (
    <IconButtonWithContextMenu
      size="large"
      items={menuItems}
      icon={EllipsisIcon}
      placement="bottom-end"
      aria-label={t('common.moreActions')}
    />
  );
};
GroupMoreMenu.displayName = 'GroupMoreMenu';

export default GroupMoreMenu;
