import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import AddIcon16 from '@wix/da-ds/pkg/Icons/16x16/Add';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { useDispatch, useSelector } from 'react-redux';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import { getDeviationEntityId } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { getDeviationResourceType } from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import { createTipJarTier } from '@wix/da-shared-react/pkg/Tier/redux/creator/actions';
import TierCreatorReduxModule from '@wix/da-shared-react/pkg/Tier/redux/creator/ModuleLoadable';
import { useVerification } from '@wix/da-shared-react/pkg/VerificationPopup/withVerification';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import useModulePrivileges from '@wix/da-gruser-shared/pkg/hooks/useModulePrivileges';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import Tiers from '../Tiers';
import { MAX_TIERS_COUNT } from './constants';
import { PapiModuleTiers } from '@wix/da-papi-types';
import { getSelectedTier } from '../../../../../selectors/subscriptionsSection';
import { AppState } from '../../../../../../types/store';
import { setSelectedTierEntityId } from '../../../../../actions/subscriptionsSection';
import {
  BiData,
  TierAddNewBiEvent,
  TierNavigationSelectBiEvent,
} from '@wix/da-bi/pkg/events';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const TiersBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const logBiEvent = useBiLogger();
  const {
    moduleData: { tiers = [] },
  } = useModuleData<PapiModuleTiers>(moduleId);
  const count = tiers.length;
  const dispatch = useDispatch();
  const isEmpty = count === 0;
  const selectedTier = useSelector<AppState, any>(state =>
    getSelectedTier(state)
  );
  const modulePrivs = useModulePrivileges(moduleId);
  const isOwner = modulePrivs?.edit == true;
  const selectTier = useCallback(
    tier => {
      if (tier.deviationId !== selectedTier?.deviationId) {
        logBiEvent(
          BiData<TierNavigationSelectBiEvent>({
            evid: 543,
            typeid: getDeviationResourceType(tier),
            itemid: tier.deviationId,
            authorid: tier?.author?.useridUuid,
          })
        );
      }
      dispatch(setSelectedTierEntityId(getDeviationEntityId(tier)));
    },
    [logBiEvent, dispatch, selectedTier]
  );

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const selectedTierId = urlParams.get('tier');
    if (selectedTierId) {
      dispatch(setSelectedTierEntityId(selectedTierId));
    }
    // run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTipJar = useCallback(() => {
    dispatch(createTipJarTier());
  }, [dispatch]);
  // users who are gifted core can see these buttons even before they're verified
  const { restrictToVerified } = useVerification();
  const restrictedCreateTipJar = restrictToVerified(
    'createsubscription',
    createTipJar
  );

  return (
    <BiLoggerContextProvider
      value={{
        referral_info: ReferralType.SUBSCRIPTIONS_WIDGET,
        widgetname: 'tiers_product',
      }}
    >
      <WidgetBox
        moduleId={moduleId}
        header={t('widgets.tiers.header', { count })}
        headerExtras={
          isOwner && (
            <>
              <TextButton
                icon={AddIcon16}
                size={'medium'}
                variant="main"
                onClick={restrictedCreateTipJar}
                disabled={count >= MAX_TIERS_COUNT}
                biData={BiData<TierAddNewBiEvent>({
                  evid: 530,
                  is_tip_jar: 1,
                  is_empty_state_view: isEmpty ? 1 : 0,
                  widgetname: 'tiers_product',
                })}
              >
                {t('widgets.tiers.header.button.createTipJar')}
              </TextButton>
              <TierCreatorReduxModule />
            </>
          )
        }
        count={count > 1 ? count : undefined}
      >
        <Tiers
          moduleId={moduleId}
          view="product"
          withExpandButton={true}
          onClick={selectTier}
          onSelect={selectTier}
          selectedTierId={selectedTier && selectedTier.deviationId}
        />
      </WidgetBox>
    </BiLoggerContextProvider>
  );
};

TiersBox.displayName = 'TiersBox';
export default TiersBox;
