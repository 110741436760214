import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { getIsLoggedIn } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { hasSubscriptions } from '../../../../../selectors/pageLayout';
import { restrictLoggedOut } from '@wix/da-shared-react/pkg/redux/loggedout/actionCreators';
import { AppState } from '../../../../../../types/store';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';
import {
  getProfileOwnerIsWatcher,
  getProfileOwnerUser,
  isCurrentUserProfileOwner,
  getProfileOwner,
} from '../../../../../selectors/users';
import { getCanCustomizePage } from '../../../../../selectors/privs';
import {
  blockProfileOwner,
  fetchProfileOwnerGiveState,
  fetchProfileOwnerGiveStateSuccess,
} from '../../../../../actions/profileOwner';
import { Props } from './UserProfileSubNavDesktopInner';
import { pushPointsModal } from '../../../../../actions/gifts';

type StateProps = Pick<
  Props,
  | 'isOwner'
  | 'profileOwnerUser'
  | 'isAuthorizedToCustomize'
  | 'isProfileOwnerWatchingCurrentUser'
  | 'isLoggedIn'
  | 'hasSubscriptions'
  | 'giveState'
>;
type DispatchProps = Pick<
  Props,
  | 'restrictLoggedOut'
  | 'blockProfileOwner'
  | 'pushBadgeModal'
  | 'onLlamaBadgeSent'
  | 'pushPointsModal'
  | 'initGiveState'
>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  isOwner: isCurrentUserProfileOwner(state),
  profileOwnerUser: getProfileOwnerUser(state),
  giveState: getProfileOwner(state).giveState,
  isAuthorizedToCustomize: getCanCustomizePage(state),
  isProfileOwnerWatchingCurrentUser: getProfileOwnerIsWatcher(state),
  isLoggedIn: getIsLoggedIn(state),
  hasSubscriptions: hasSubscriptions(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps
) => ({
  restrictLoggedOut: payload => dispatch(restrictLoggedOut(payload)),
  pushPointsModal: (username, title) =>
    dispatch(pushPointsModal(username, title)),
  blockProfileOwner: () => dispatch(blockProfileOwner()),
  pushBadgeModal: (username: string, onBadgeSent?: () => void) =>
    dispatch(modalActions.pushBadgeModal(username, onBadgeSent)),
  onLlamaBadgeSent: () =>
    dispatch(fetchProfileOwnerGiveStateSuccess({ canBeGivenLlama: false })),
  initGiveState: () => dispatch(fetchProfileOwnerGiveState()),
});

export default connect(mapStateToProps, mapDispatchToProps);
