import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormProvider,
  useForm,
  Controller,
  useFormContext,
} from 'react-hook-form';
import * as yup from 'yup';
import { Url } from '@wix/da-url';
import { SessionContext } from '@wix/da-react-context/pkg/publicSession';
import yupResolver from '@wix/da-shared-react/pkg/utils/yupResolver';
import BuyCoreBrandedButton from '@wix/da-shared-react/pkg/Button/core/dsPresets/BuyCoreBrandedButton';
import CoreSymbol from '@wix/da-ds/pkg/Indicators/CoreSymbol';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import Close from '@wix/da-ds/pkg/Icons/24x24/Close';
import ModalLayout from '@wix/da-ds/pkg/modals/dsPresets/Modal/ModalLayout';
import ModalHeader from '@wix/da-ds/pkg/modals/dsPresets/Modal/ModalHeader';
import ModalFooter from '@wix/da-ds/pkg/modals/dsPresets/Modal/ModalFooter';
import TextInput, {
  type TextInputProps,
} from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import TextArea, {
  TextAreaProps,
} from '@wix/da-ds/pkg/formControls/dsPresets/TextArea';
import {
  ThemeOverride,
  ThemeSurface,
} from '@wix/da-react-context/pkg/ThemeContext';
import IconButton from '@wix/da-ds/pkg/Buttons/IconButton';
import { CreateGroupPayload } from '../../../actions/groupCreation';
import GroupLimitWarning from './GroupLimitWarning';

import s from './GroupCreation.scss';

export interface Props {
  canCreate?: boolean;
  isLoading?: boolean;
  errors?: any;
  createGroup: (payload: CreateGroupPayload) => void;
}

const DEFAULT_VALUES = {
  group_name: '',
  group_description: '',
};
const MAX_DESC_LEN = 450;
const MIN_DESC_LEN = 50;

const MIN_USERNAME_LEN = 3;
const MAX_USERNAME_LEN = 20;

const GroupCreation: React.FC<Props> = ({
  canCreate,
  isLoading,
  errors: backendErrors,
  createGroup,
}) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    group_name: yup
      .string()
      .required(t('common.formError.required'))
      .min(MIN_USERNAME_LEN)
      .max(MAX_USERNAME_LEN)
      .matches(
        /^[A-Za-z0-9][A-Za-z0-9-]{1,18}[A-Za-z0-9]$/,
        t('common.formError.validation.username')
      ),
    group_description: yup
      .string()
      .required(t('common.formError.required'))
      .min(MIN_DESC_LEN, t('common.formError.validation.group_description'))
      .max(MAX_DESC_LEN),
  });

  const formContext = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema),
  });
  const {
    formState: { isDirty, isValid },
    handleSubmit,
    setError,
  } = formContext;

  // convert errors returned from server into form errors
  useEffect(() => {
    for (const error of backendErrors || []) {
      setError(error?.field, {
        type: 'custom',
        message: error?.errorMessage,
      });
    }
  }, [backendErrors, setError]);

  const { hasCoreMembership } = useContext(SessionContext);
  const creationIsBlocked = !canCreate;
  const hasMaxGroupsForNonCore = creationIsBlocked && !hasCoreMembership;
  const hasMaxGroupsForCore = creationIsBlocked && hasCoreMembership;

  // show a special modal and message if they've hit the cap on groups for a core user
  if (hasMaxGroupsForCore) {
    return (
      <div className={s['root']}>
        <GroupLimitWarning />
      </div>
    );
  }

  // this modal handles both the case where:
  // - the user hit the group limits for non-core users (3?) and needs to upgrade to create
  // - and where the user hasn't hit any group limits and can use the form to create a new group
  return (
    <ThemeOverride themeSurface={ThemeSurface.TERTIARY} className={s['root']}>
      <ModalLayout
        className={s['modal']}
        header={
          <ModalHeader
            className={s['modal-header']}
            heading={t('groupCreation.modal.heading')}
            right={
              <IconButton
                className={s['close-button']}
                size="large"
                icon={Close}
                href={Url.buildFromPath('/groups')}
                aria-label={t('groupCreation.modal.closeButton.a11y')}
              />
            }
          />
        }
        headerBorder
        contentClassName={s['modal-content']}
        content={
          <>
            {/* if they've hit the max groups for and don't have core, just put a message
            in the form and add an upgrade button below */}
            {hasMaxGroupsForNonCore && (
              <div className={s['noncore-limit-error']}>
                <CoreSymbol
                  className={s['core-symbol']}
                  size={IconSize.MEDIUM}
                />
                <p>{t('groupCreation.cantCreate.nonCoreLimitError')}</p>
              </div>
            )}
            <FormProvider {...formContext}>
              <TextInputField
                className={s['group-name-input']}
                name="group_name"
                label={t('groupCreation.form.group_name.label')}
                placeholder={t('groupCreation.form.group_name.placeholder')}
                autoComplete="off"
                isRequired
                autoFocus
                disabled={creationIsBlocked}
              />
              <TextAreaField
                className={s['group-description-textarea']}
                name="group_description"
                label={t('groupCreation.form.group_description.label')}
                placeholder={t(
                  'groupCreation.form.group_description.placeholder'
                )}
                details={t('groupCreation.form.group_description.details')}
                maxLength={MAX_DESC_LEN}
                isRequired
                disabled={creationIsBlocked}
              />
            </FormProvider>
          </>
        }
        footerBorder
        footer={
          <ModalFooter
            right={
              hasMaxGroupsForNonCore
                ? [
                    <TextButton
                      key="learn-more"
                      size="large"
                      variant="main"
                      href={t(
                        'groupCreation.cantCreate.nonCoreLimitError.learnMore.url'
                      )}
                    >
                      {t('common.learnMore')}
                    </TextButton>,
                    <BuyCoreBrandedButton
                      key="buy-core"
                      size="large"
                      withSaleSparkles
                    >
                      {t(
                        'groupCreation.cantCreate.nonCoreLimitError.upgradeButton'
                      )}
                    </BuyCoreBrandedButton>,
                  ]
                : [
                    <BrandedButton
                      key="create"
                      size="large"
                      variant="brand-green"
                      type="submit"
                      disabled={!isDirty || !isValid}
                      loading={isLoading}
                      onClick={handleSubmit(formValues => {
                        return createGroup(formValues);
                      })}
                    >
                      {t('groupCreation.modal.createButton')}
                    </BrandedButton>,
                  ]
            }
          />
        }
      />
    </ThemeOverride>
  );
};

export default GroupCreation;

// ----
interface FieldProps {
  name: string;
  label: string;
  errorMessage?: string;
  isRequired?: boolean;
}

function TextInputField({
  name,
  label,
  details,
  isRequired: required,
  ...props
}: Omit<TextInputProps, 'id' | 'value'> & FieldProps) {
  const { control, errors } = useFormContext();
  const error = errors[name];
  const errorDisplayed = error?.message;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ onChange, onBlur, value }) => (
        <TextInput
          id={`group-creation-${name}`}
          label={label}
          validationResult={error ? 'error' : undefined}
          details={error ? errorDisplayed : details}
          isRequired={required}
          onChange={ev => onChange(ev.target.value)}
          onBlur={onBlur}
          value={value}
          {...props}
        />
      )}
    />
  );
}

// ----
function TextAreaField({
  name,
  label,
  details,
  isRequired: required,
  ...props
}: Omit<TextAreaProps, 'id' | 'onChange'> & FieldProps) {
  const { control, errors } = useFormContext();
  const error = errors[name];
  const errorDisplayed = error?.message;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ onChange, onBlur, value }) => (
        <TextArea
          id={`group-creation-${name}`}
          label={label}
          validationResult={error ? 'error' : undefined}
          details={error ? errorDisplayed : details}
          isRequired={required}
          onChange={ev => onChange(ev.target.value)}
          onBlur={onBlur}
          value={value}
          {...props}
        />
      )}
    />
  );
}
