import React from 'react';
import { compose } from 'redux';
import {
  PapiGallection,
  PapiDeviation,
  PapiPremiumFolderData,
} from '@wix/da-papi-types';
import { Url } from '@wix/da-url';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import { scrollIntoViewWithHeader } from '@wix/da-shared-react/pkg/utils/scrollIntoViewWithHeader';
import FolderDescription from '../FolderDescription';
import { GallectionType } from '../../../../../../types/gallection';
import { getGallectionsStickyBarHeight } from '../../helpers';
import Pagination from '@wix/da-shared-react/pkg/Pagination';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import { withMobileContext } from '@wix/da-react-context/pkg/MobileContext';
import GallectionDeviationsTorpedo from '../../GallectionDeviationsTorpedo';
import FolderPaywall from '../../FolderPaywall';
import { gallectionTypeToResourceType } from '@wix/da-shared-react/pkg/types/gallection';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { normalizePremiumFolderData } from '@wix/da-shared-react/pkg/utils/normalizePremiumFolderData';
import withFeatureFlags, {
  WithFeatureFlagsProps,
} from '@wix/da-react-context/pkg/flags/withFeatureFlags';
import FolderBreadcrumbs from '../FolderBreadcrumbs/';
import generatePaginationUrl from '../../../../../utils/generatePaginationUrl';
import { BiData, GalleryPurchaseBiEvent } from '@wix/da-bi/pkg/events';

import s from './FolderPagedContent.scss';

const FOLDER_BAR_DISTANCE_FROM_TOP = 400;

export interface Props {
  streamId: string;
  folderId: number;
  subfolderId?: number;
  currentPage: number;
  totalPages: number;
  requestUrl: string;
  folder: PapiGallection;
  subfolder?: PapiGallection;
  gallectionType: GallectionType;
  deviations: PapiDeviation[];
  isInitialized: boolean;
  hasMore: boolean;
  isSelected: boolean;
  isFetching: boolean;
  initFetch: () => void;
  isOwner: boolean;
  className?: string;
  isMobile?: boolean;
  scrollIntoViewWhenLoaded?: boolean;
  justExitedDuperbrowse?: boolean;
}

class FolderPagedContent extends React.Component<
  Props & WithFeatureFlagsProps
> {
  static displayName = 'FolderPagedContent';

  folderRef: React.RefObject<HTMLElement | null> = React.createRef();
  torpedoRef: React.RefObject<HTMLElement | null> = React.createRef();

  UNSAFE_componentWillMount() {
    this.initFetchIfNeeded(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.initFetchIfNeeded(nextProps);
  }

  initFetchIfNeeded = props => {
    const { deviations, isInitialized, hasMore, isFetching, initFetch } = props;

    if (!deviations.length && (hasMore || !isInitialized) && !isFetching) {
      initFetch();
    }
  };

  render() {
    const {
      subfolder,
      currentPage,
      totalPages,
      hasMore,
      requestUrl,
      deviations,
      isFetching,
      folder,
      className,
      gallectionType,
      streamId,
      isOwner,
    } = this.props;

    const { pointPrice, isPremiumLocked } = normalizePremiumFolderData(
      folder.premiumFolderData
    );
    const isCollection = gallectionType === GallectionType.COLLECTION;
    // If the server does not return totalPages we cannot show page numbers in
    // the Pagination component, but we can still show prev/next buttons if the
    // stream hasMore. To do that we fake the totalPage to be one higher than
    // the currentPage.
    const nextPage = currentPage + 1;
    const fakeTotalPages = hasMore ? nextPage : currentPage;
    const showPageNumbers = !!totalPages;
    const showPaywall = isPremiumLocked && !isOwner;
    return (
      <OverlayContextProvider
        value={{
          showAuthorTooltip: isCollection,
          showThumbStatusIndicators: false,
        }}
      >
        <div className={className} ref={this.folderRef as any}>
          <ErrorBoundary debugComponent="FolderPagedContent">
            <FolderDescription
              folder={folder}
              className={s['folder-description']}
            />
            {subfolder && <FolderBreadcrumbs />}
            {!showPaywall ? (
              deviations.length > 0 && (
                <div ref={this.torpedoRef as any}>
                  <BiLoggerContextProvider
                    value={{
                      section_typeid:
                        gallectionTypeToResourceType(gallectionType),
                      sectionid: folder.folderId,
                      authorid: folder.owner.useridUuid,
                    }}
                  >
                    <GallectionDeviationsTorpedo
                      streamId={streamId}
                      gallectionType={gallectionType}
                      deviations={deviations}
                    />
                  </BiLoggerContextProvider>
                </div>
              )
            ) : (
              <FolderPaywall
                premiumFolderData={{
                  ...(folder.premiumFolderData as PapiPremiumFolderData),
                  galleryUrl: Url.userGalleryLink({
                    username: folder.owner.username,
                    gallectionType: 'gallery',
                    folderId: folder.folderId,
                    folderName: folder.name,
                  }),
                }}
                folder={folder}
                biData={BiData<GalleryPurchaseBiEvent>({
                  evid: 361,
                  points: pointPrice,
                  typeid: gallectionTypeToResourceType(gallectionType) ?? 0,
                  itemid: folder.folderId,
                  galleryid: folder.folderId,
                  authorid: folder.owner.useridUuid,
                })}
              />
            )}

            {!isPremiumLocked && totalPages > 1 && (
              <div className={s['button-wrapper']}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages ? totalPages : fakeTotalPages}
                  showPageNumbers={showPageNumbers}
                  getPageUrl={page => generatePaginationUrl(page, requestUrl)}
                />
              </div>
            )}
            {isFetching && <LoadingIndicator />}
          </ErrorBoundary>
        </div>
      </OverlayContextProvider>
    );
  }

  scrollToThisFolder(isInitialLoad = true) {
    const thisFolder = this.folderRef.current;
    const { isMobile } = this.props;
    if (!thisFolder) {
      return;
    }

    const folderBarHeightAndPadding =
      getGallectionsStickyBarHeight(isMobile) + 15;
    scrollIntoViewWithHeader(
      thisFolder,
      isMobile,
      // on the initial page load for some reason, we need to move
      // 400px further down in order to get the top of the folder
      // To add this with scrollIntoViewWithHeader, we need to subtract it
      // from the header height that will be subtracted from the folder's
      // bounds.top
      isInitialLoad
        ? folderBarHeightAndPadding - FOLDER_BAR_DISTANCE_FROM_TOP
        : folderBarHeightAndPadding
    );
  }
}

const enhance = compose(withMobileContext, withFeatureFlags);

export default enhance(FolderPagedContent);
