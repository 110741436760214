import React from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from '@wix/da-ds/pkg/formControls/dsPresets/TextArea';
import { useController, useFormContext } from 'react-hook-form';
import { REPORT_REASONS } from '../types';
import InfoIcon from '@wix/da-ds/pkg/Icons/24x24/Info';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import s from './StepTwo.scss';

const StepTwo: React.FC = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const selectedReason = watch('reason');
  const selectedReasonData = REPORT_REASONS.find(r => r.id === selectedReason);

  const { field } = useController({
    name: 'details',
    rules: {
      required: false,
      maxLength: 1000,
    },
  });

  return (
    <section>
      <p className={s['instructions']}>
        {selectedReasonData?.instructions &&
          t(selectedReasonData.instructions, {
            postProcess: ['reactPostprocessor', 'featureBrancher'],
            br: <br />,
          })}
      </p>
      <TextArea
        id="details"
        label={''}
        isLabelHidden
        className={s['textarea']}
        maxLength={1000}
        {...field}
      />
      {selectedReasonData?.note && (
        <p className={s['note']}>
          <InfoIcon
            className={s['info-icon']}
            disableDefaultColors
            size={IconSize.SMALLER}
          />
          {t(selectedReasonData.note, {
            postProcess: ['reactPostprocessor', 'featureBrancher'],
            br: <br />,
          })}
        </p>
      )}
    </section>
  );
};

export default StepTwo;
