import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { USER_PROFILE_SECTION_PATHS, Url, CHECKOUT_IDS } from '@wix/da-url';
import { PapiUser } from '@wix/da-papi-types';
import MailIcon from '@wix/da-ds/pkg/Icons/24x24/Mail';
import { useDispatch } from 'react-redux';
import {
  withVerification,
  WithVerificationProps,
} from '@wix/da-shared-react/pkg/VerificationPopup/withVerification';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import ShareIcon from '@wix/da-ds/pkg/Icons/24x24/Share';
import CakeIcon from '@wix/da-ds/pkg/Icons/16x16/BirthdayCake';
import LlamaIcon from '@wix/da-ds/pkg/Icons/Llama';
import InfoIcon from '@wix/da-ds/pkg/Icons/24x24/Info';
import PointsIcon from '@wix/da-ds/pkg/Icons/Points';
import QuillIcon from '@wix/da-ds/pkg/Icons/24x24/Quill';
import CoreSymbol from '@wix/da-ds/pkg/Indicators/CoreSymbol';
import { NullableDropdownItem } from '@wix/da-ds/pkg/Dropdown/types';
import BlockIcon from '@wix/da-ds/pkg/Icons/24x24/Block';
import TierIcon from '@wix/da-ds/pkg/Icons/24x24/Tier';
import EllipsisIcon from '@wix/da-ds/pkg/Icons/Ellipsis';
import ErrorIcon from '@wix/da-ds/pkg/Icons/24x24/Error';
import {
  ProfileOwnerWatchButton,
  ProfileOwnerSubscribeButton,
} from '@wix/da-shared-react/pkg/Button';
import IconButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/IconButtonWithContextMenu';
import { RestrictLoggedOutPayload } from '@wix/da-shared-react/pkg/redux/loggedout/actionCreators';
import BaseSubNavBarDesktop, {
  Props as BaseProps,
} from '../../BaseSubNavBarDesktopInner';
import WatchButtonConnectContainer from '../../../WatchButton.container';
import RightSideButtonWrapper from '../../BaseSubNavBarDesktopInner/legos/RightSideButtonWrapper';
import LargeButtonsWrapper from '../../BaseSubNavBarDesktopInner/legos/LargeButtonsWrapper';
import { ProfileOwnerGiveState } from '../../../../../../types/profileOwner';
import ProfileSkinsButton from '../_partials/ProfileSkinsButton';
import GiveButton from './_partials/GiveButton';
import { useCollapsingRoutes } from '../../useCollapsingRoutes';
import { CollapsingElementsContainer } from '../../CollapsingElements';
import { CollapsingElementId } from '../../CollapsingElements/types';
import NoteButton from './_partials/NoteButton';
import { BiData, GiftSelectClickBiEvent } from '@wix/da-bi/pkg/events';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { ModalType } from '../../../../../../types/modals';
import { useCopyProfileLinkClick } from '../../../../../utils/useCopyProfileLinkClick';
import s from './UserProfileSubNavDesktopInner.scss';

const WatchButton = WatchButtonConnectContainer(ProfileOwnerWatchButton);

export interface Props extends BaseProps {
  profileOwnerUser: PapiUser;
  isOwner: boolean;
  isLoggedIn: boolean;
  hasSubscriptions: boolean;
  isAuthorizedToCustomize: boolean;
  isProfileOwnerWatchingCurrentUser: boolean;
  giveState?: ProfileOwnerGiveState;
  initGiveState: () => void;
  blockProfileOwner: () => void;
  restrictLoggedOut: (payload: RestrictLoggedOutPayload) => void;
  pushBadgeModal: typeof modalActions.pushBadgeModal;
  onLlamaBadgeSent: () => void;
  pushPointsModal: (username: string, title: string) => void;
}

enum COLLAPSING_BUTTON {
  MESSAGE = 'message',
  SHARE = 'share',
  GIVE = 'give',
  SUBSCRIPTIONS = 'subscriptions',
}

const UserProfileSubNavDesktopInner: React.FC<
  Props & WithVerificationProps
> = ({
  isSticky,
  profileOwnerUser,
  isOwner,
  isLoggedIn,
  isAuthorizedToCustomize,
  isProfileOwnerWatchingCurrentUser,
  isVerificationNeeded,
  pushVerificationPopup,
  blockProfileOwner,
  restrictLoggedOut,
  hasSubscriptions,
  pushBadgeModal,
  onLlamaBadgeSent,
  pushPointsModal,
  giveState,
  initGiveState,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const report_user = useFlag('report_user');
  const { routesToCollapse } = useCollapsingRoutes();
  const [collapsedIds, setCollapsedIds] = useState<CollapsingElementId[]>([]);
  const messageButtonRef = useRef<HTMLDivElement | null>(null);
  const shareButtonRef = useRef<HTMLDivElement | null>(null);
  const giveButtonRef = useRef<HTMLDivElement | null>(null);
  const subscriptionsButtonRef = useRef<HTMLDivElement | null>(null);

  const { onCopyLinkClick } = useCopyProfileLinkClick(profileOwnerUser);

  const showNewSubscribeButton = !isOwner && hasSubscriptions;

  const hideWatchButton = profileOwnerUser.isSubscribed;
  const hideGiveButton = isSticky && hasSubscriptions;

  const collapsingButtons = [
    {
      id: COLLAPSING_BUTTON.MESSAGE,
      ref: messageButtonRef,
    },
    {
      id: COLLAPSING_BUTTON.SHARE,
      ref: shareButtonRef,
    },
    {
      id: COLLAPSING_BUTTON.GIVE,
      ref: giveButtonRef,
    },
    {
      id: COLLAPSING_BUTTON.SUBSCRIPTIONS,
      ref: subscriptionsButtonRef,
    },
  ];

  const collapsedButtonsAsMenuItems = {
    [COLLAPSING_BUTTON.MESSAGE]: {
      icon: <MailIcon />,
      label: t(`navigation.subnavigation.message`),
      onClick: isVerificationNeeded
        ? () => pushVerificationPopup('notes')
        : undefined,
      link: isVerificationNeeded
        ? undefined
        : Url.userNoteLink(profileOwnerUser.username),
    },
    [COLLAPSING_BUTTON.SHARE]: {
      icon: <ShareIcon />,
      label: t(`navigation.subnavigation.copyLink`),
      onClick: onCopyLinkClick,
    },
    [COLLAPSING_BUTTON.SUBSCRIPTIONS]: {
      icon: <TierIcon />,
      label: t(`navigation.route.var.subscriptions`),
      link: Url.userLink(
        profileOwnerUser.username,
        `${USER_PROFILE_SECTION_PATHS.SUBSCRIPTIONS}#tiers`
      ),
    },
  };

  const renderWatchButton = () => {
    return (
      <WatchButton
        className={s['watch-button']}
        isWatchingBack={isProfileOwnerWatchingCurrentUser}
      />
    );
  };

  const getMoreActionsMenuItems = () => {
    const menuItems: Array<NullableDropdownItem> = [];

    if (collapsedIds.length) {
      const collapsedIntoMenuItems = collapsedIds.map(
        id => collapsedButtonsAsMenuItems[id]
      );
      menuItems.push(...collapsedIntoMenuItems);
    }

    const giveMenuGroup: NullableDropdownItem = { items: [] };

    const coreLabel = giveState?.canBeGivenCore
      ? t('navigation.subnavigation.giveCore')
      : t('navigation.subnavigation.hasCore');
    const giveCoreUrl = Url.giveCoreMembership(profileOwnerUser.username);
    const biData = BiData<GiftSelectClickBiEvent>({
      evid: 275,
      recipientid: profileOwnerUser.useridUuid,
    } as any);

    giveMenuGroup.items.push({
      icon: <CoreSymbol corePackage="core_access" size={IconSize.MEDIUM} />,
      label: giveState?.isLoading
        ? t('navigation.subnavigation.checkingCoreStatus')
        : coreLabel,
      biData: {
        ...biData,
        menu_item_name: coreLabel,
        link_url: giveCoreUrl,
      },
      onClick: () => window.location.assign(giveCoreUrl),
      disabled: !giveState?.canBeGivenCore,
    });

    const pointsLabel = t('navigation.subnavigation.givePoints');
    giveMenuGroup.items.push({
      icon: <PointsIcon />,
      label: pointsLabel,
      biData: { ...biData, menu_item_name: pointsLabel },
      onClick: () => {
        pushPointsModal(
          profileOwnerUser.username,
          t(`navigation.subnavigation.givePoints`)
        );
      },
    });

    const llamaLabel = giveState?.canBeGivenLlama
      ? t('navigation.subnavigation.giveLlamaBadge')
      : t('navigation.subnavigation.hasGotLlamaBadge');

    giveMenuGroup.items.push({
      icon: <LlamaIcon />,
      label: llamaLabel,
      biData: { ...biData, menu_item_name: llamaLabel },
      onClick: () => {
        pushBadgeModal(profileOwnerUser.username, onLlamaBadgeSent);
      },
      disabled: !giveState?.canBeGivenCore,
    });

    const cakeLabel = giveState?.canBeGivenCake
      ? t('navigation.subnavigation.giveCakeBadge')
      : t('navigation.subnavigation.hasGotCakeBadge');

    giveMenuGroup.items.push({
      icon: <CakeIcon />,
      label: cakeLabel,
      biData: { ...biData, menu_item_name: cakeLabel },
      onClick: () => {
        window.location.assign(
          `${Url.checkoutLink(CHECKOUT_IDS.CAKE)}&foruserid=${
            profileOwnerUser.userId
          }`
        );
      },
    });

    // add Give items to dropdown menu if:
    // 1) Give was collapsed due to window width
    // 2) give button is being hidden (due to having subscription), go ahead and add it to the dropdown menu
    if (collapsedIds.includes(COLLAPSING_BUTTON.GIVE) || hideGiveButton) {
      menuItems.push(giveMenuGroup);
    }

    // Any collapsed routes are in a group
    if (routesToCollapse.length) {
      const routesMenuGroup: NullableDropdownItem = { items: [] };

      if (routesToCollapse.includes('posts')) {
        routesMenuGroup.items.push({
          icon: <QuillIcon />,
          label: t('navigation.subnavigation.posts'),
          link: Url.userLink(profileOwnerUser.username, 'posts'),
        });
      }

      if (routesToCollapse.includes('about')) {
        routesMenuGroup.items.push({
          icon: <InfoIcon />,
          label: t('navigation.subnavigation.about'),
          link: Url.userLink(profileOwnerUser.username, 'about'),
        });
      }

      menuItems.push(routesMenuGroup);
    }

    // Share and Block are in the same menu group
    const shareBlockMenuGroup: NullableDropdownItem = { items: [] };

    shareBlockMenuGroup.items.push({
      icon: <ShareIcon />,
      label: t(`navigation.subnavigation.copyLink`),
      onClick: onCopyLinkClick,
    });

    if (isLoggedIn && !isOwner) {
      shareBlockMenuGroup.items.push({
        icon: <BlockIcon />,
        label: t('navigation.subnavigation.blockUser'),
        onClick: blockProfileOwner,
      });
    }

    if (report_user) {
      shareBlockMenuGroup.items.push({
        icon: <ErrorIcon />,
        label: t('navigation.subnavigation.reportUser'),
        onClick: () => {
          dispatch(
            pushModal(ModalType.REPORT_PROFILE, {
              wrapInModal: false,
              params: { userId: profileOwnerUser.userId },
            })
          );
        },
      });
    }

    menuItems.push(shareBlockMenuGroup);

    return menuItems;
  };

  const renderMoreActionsIconDropdown = () => {
    const menuItems = getMoreActionsMenuItems();
    if (!menuItems.length) {
      return null;
    }

    return (
      <RightSideButtonWrapper>
        <IconButtonWithContextMenu
          items={menuItems}
          icon={EllipsisIcon}
          size="large"
          popperManagerProps={{ onOpen: initGiveState }}
          aria-label={t('common.more')}
        />
      </RightSideButtonWrapper>
    );
  };

  const rightSideContent = isOwner ? (
    isAuthorizedToCustomize && (
      <RightSideButtonWrapper>
        <ProfileSkinsButton />
      </RightSideButtonWrapper>
    )
  ) : (
    <>
      {renderMoreActionsIconDropdown()}

      {!hideGiveButton && (
        <RightSideButtonWrapper
          innerRef={giveButtonRef}
          isCollapsed={collapsedIds.includes(COLLAPSING_BUTTON.GIVE)}
        >
          <GiveButton />
        </RightSideButtonWrapper>
      )}
      <RightSideButtonWrapper
        innerRef={messageButtonRef}
        isCollapsed={collapsedIds.includes(COLLAPSING_BUTTON.MESSAGE)}
      >
        <NoteButton profileOwner={profileOwnerUser} />
      </RightSideButtonWrapper>

      <LargeButtonsWrapper>
        {!hideWatchButton && (
          <RightSideButtonWrapper>{renderWatchButton()}</RightSideButtonWrapper>
        )}
        {showNewSubscribeButton && (
          <RightSideButtonWrapper
            innerRef={subscriptionsButtonRef}
            isCollapsed={collapsedIds.includes(COLLAPSING_BUTTON.SUBSCRIPTIONS)}
          >
            <ProfileOwnerSubscribeButton user={profileOwnerUser} />
          </RightSideButtonWrapper>
        )}
      </LargeButtonsWrapper>
    </>
  );
  return (
    <BaseSubNavBarDesktop
      isSticky={isSticky}
      rightSideContent={
        <CollapsingElementsContainer
          collapsingElements={collapsingButtons}
          onCollapsedIdsChange={setCollapsedIds}
          justifyContent="flex-end"
        >
          {rightSideContent}
        </CollapsingElementsContainer>
      }
    />
  );
};
UserProfileSubNavDesktopInner.displayName = 'UserProfileSubNavDesktopInner';

export default withVerification(UserProfileSubNavDesktopInner);
