import { PapiModuleUserstatsDetailed, PapiUser } from '@wix/da-papi-types';

export enum StatsTimePeriod {
  Week = 'P7D',
  Month = 'P28D',
  Year = 'P365D',
}

export enum MySubmissionsSortBy {
  PublishDate = 'publishDate',
  PageViews = 'pageViews',
  Faves = 'faves',
  Comments = 'comments',
}

export type DetailedStats = PapiModuleUserstatsDetailed;

export type MySubmissionItem = NonNullable<
  NonNullable<DetailedStats['myContent']>['timePeriods']['P7D']
>[0];

export type GeneralStats = NonNullable<
  NonNullable<DetailedStats['general']>['timePeriods']['P7D']
>;

export type DeviceStats = NonNullable<
  NonNullable<DetailedStats['trafficByDevice']>['timePeriods']['P7D']
>;

export type CountryStats = NonNullable<
  NonNullable<DetailedStats['trafficByCountry']>['timePeriods']['P7D']
>;

export type BenchmarkItem = NonNullable<
  NonNullable<
    PapiModuleUserstatsDetailed['compareYourself']
  >['timePeriods']['P7D']
>[0];

export enum BenchmarkSortBy {
  ProfileVisitors = 'profileVisitors',
  DeviationViews = 'deviationsViews',
  Faves = 'faved',
  Watchers = 'watchers',
}

type StatsSection = {
  timePeriod: StatsTimePeriod;
  mySubmissionsSortBy: MySubmissionsSortBy;
  mySubmissionsIsLoading: boolean;
  benchmarkSortBy: BenchmarkSortBy;
  benchmarkIsLoading: boolean;
  visitorsIsLoading: boolean;
  matchingUsers: PapiUser[];
};

export default StatsSection;
