import React from 'react';
import { PapiGallection } from '@wix/da-papi-types';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import FolderContent from '../FolderContent';
import MobileGallectionHeader from '../MobileGallectionHeader';

import s from './GallectionSimpleView.scss';

export interface Props {
  currentFolder?: PapiGallection;
}

const MobileGallectionSimpleView: React.FC<Props> = ({ currentFolder }) => (
  <div id="content" className={s['root']}>
    <ErrorBoundary debugComponent="MobileGallectionSimpleView">
      <MobileGallectionHeader currentFolder={currentFolder} />
      <div id="sub-folder-gallery">
        {currentFolder && (
          <FolderContent
            key={currentFolder?.folderId}
            folderId={currentFolder?.folderId || 0}
          />
        )}
      </div>
    </ErrorBoundary>
  </div>
);
MobileGallectionSimpleView.displayName = 'MobileGallectionSimpleView';

export default MobileGallectionSimpleView;
