import React from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import { Warning } from '@wix/da-ds/pkg/Icons/Confirmations/Warning';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import Close from '@wix/da-ds/pkg/Icons/24x24/Close';
import ModalLayout from '@wix/da-ds/pkg/modals/dsPresets/Modal/ModalLayout';
import ModalHeader from '@wix/da-ds/pkg/modals/dsPresets/Modal/ModalHeader';
import ModalFooter from '@wix/da-ds/pkg/modals/dsPresets/Modal/ModalFooter';
import IconButton from '@wix/da-ds/pkg/Buttons/IconButton';

import s from './GroupLimitWarning.scss';

/** modal that explains the user can't create more groups because they hit the limit */
const GroupLimitWarning: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <ModalLayout
      className={s['root']}
      header={
        <ModalHeader
          className={s['modal-header']}
          heading={null} // ok... ?
          right={
            <IconButton
              className={s['close-button']}
              size="large"
              icon={Close}
              href={Url.buildFromPath('/groups')}
              aria-label={t('groupCreation.modal.closeButton.a11y')}
            />
          }
        />
      }
      contentClassName={s['modal-content']}
      content={
        <>
          <Warning className={s['warning-icon']} />
          <div>
            <Heading className={s['title']}>
              {t('groupCreation.cantCreate.coreLimitError.title')}
            </Heading>
            <p>{t('groupCreation.cantCreate.coreLimitError.text')}</p>
          </div>
        </>
      }
      footerBorder
      footer={
        <ModalFooter
          className={s['footer']}
          right={[
            <BrandedButton
              key="got-it"
              variant="brand-green"
              size="large"
              href={Url.userLink('me')}
            >
              {t('groupCreation.cantCreate.coreLimitError.gotIt')}
            </BrandedButton>,
          ]}
        />
      }
    />
  );
};

export default GroupLimitWarning;
