import { createAction } from 'redux-actions';
import { PapiExtendedGroup } from '@wix/da-papi-types';
import { ProfileOwnerStats } from './../../types/profileOwner';

export type FetchProfileOwnerSuccessPayload = {
  user: { id: number };
  entities: any;
  isWatching: boolean;
  isWatcher: boolean;
  isGroup: boolean;
  extendedGroup?: PapiExtendedGroup;
  showMonetizationPromo?: string;
  stats: ProfileOwnerStats;
  tagline?: string;
};
export const fetchProfileOwnerSuccess = createAction(
  'FETCH.PROFILE_OWNER.SUCCESS',
  (payload: FetchProfileOwnerSuccessPayload) => payload
);

export type FetchProfileOwnerTaglinePayload = {
  tagline?: string;
};
export const fetchProfileOwnerTaglineSuccess = createAction(
  'FETCH.PROFILE_OWNER_TAGLINE.SUCCESS',
  (payload: FetchProfileOwnerTaglinePayload) => payload
);

// those stats that are got from standalone modules, not from 'userstats' module
export type FetchProfileOwnerStatsPayload = Pick<
  ProfileOwnerStats,
  'badgesSent' | 'badgesReceived' | 'groupsAdmin' | 'groupsMember'
>;
export const fetchProfileOwnerStatsSuccess = createAction(
  'FETCH.PROFILE_OWNER_STATS.SUCCESS',
  (payload: FetchProfileOwnerStatsPayload) => payload
);

export type FetchProfileOwnerGiveStatePayload = {
  canBeGivenLlama?: boolean;
  canBeGivenCake?: boolean;
  canBeGivenCore?: boolean;
  isLoading?: boolean;
};
export const fetchProfileOwnerGiveStateSuccess = createAction(
  'FETCH.PROFILE_OWNER_GIVE_STATE.SUCCESS',
  (payload: FetchProfileOwnerGiveStatePayload) => payload
);

export const fetchProfileOwnerGiveState = createAction(
  'FETCH.PROFILE_OWNER_GIVE_STATE',
  () => ({})
);

export const fetchProfileOwnerGiveStateFailure = createAction(
  'FETCH.PROFILE_OWNER_GIVE_STATE.FAILURE',
  () => ({})
);

export const fetchFallbackBadgeCheckSuccess = createAction(
  'FETCH.PROFILE_OWNER.FETCH_FALLBACK_BADGE_CHECK_SUCCESS',
  () => ({})
);

export const fetchFallbackBadgeCheck = createAction(
  'FETCH.PROFILE_OWNER.FETCH_FALLBACK_BADGE_CHECK',
  () => ({})
);

export const blockProfileOwner = createAction(
  'BLOCK_PROFILE_OWNER',
  () => ({})
);
