import React from 'react';
import RadioButton from '@wix/da-ds/pkg/RadioButton';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { REPORT_REASONS } from '../types';
import s from './StepOne.scss';

const StepOne: React.FC = () => {
  const { t } = useTranslation();
  const { field } = useController({
    name: 'reason',
    rules: { required: true },
  });

  return (
    <section className={s['radioGroup']}>
      {REPORT_REASONS.map(reason => (
        <RadioButton
          key={reason.id}
          name="reason"
          value={reason.id.toString()}
          inputClassName={s['radioButton']}
          checked={field.value === reason.id}
          onChange={e => field.onChange(e.target.value)}
        >
          <article className={s['reasonContainer']}>
            <span className={s['reasonLabel']}>{t(reason.label)}</span>
          </article>
        </RadioButton>
      ))}
    </section>
  );
};
StepOne.displayName = 'StepOne';
export default StepOne;
