import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AppState } from '../../../../../../types/store';
import {
  selectors as streamSelectors,
  withOffset as streamWithOffset,
} from '@wix/da-shared-react/pkg/Stream';
import { getFolderById } from '../../../../../selectors/folders';
import {
  DEVIATIONS_PER_FETCH_COUNT,
  FOLDER_DEVIATIONS_STREAM_GROUP_ID,
  getFolderDeviationsStreamId,
} from '../../../../../streams/folderDeviations';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { getLastViewedItem } from '@wix/da-shared-react/pkg/Duperbrowse/redux/selectors';
import { Props } from './FolderInfiniteContent';
import { isCurrentUserProfileOwner } from '../../../../../selectors/users';
import {
  getGallectionSectionType,
  getSelectedFolderId,
  getSelectedSubfolder,
} from '../../../../../selectors/gallectionSection';
import { getCurrentPage } from '../../../../../selectors/pageInfo';
import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';

type StateProps = Pick<
  Props,
  | 'streamId'
  | 'gallectionType'
  | 'deviations'
  | 'folder'
  | 'subfolder'
  | 'subfolderId'
  | 'hasMore'
  | 'justExitedDuperbrowse'
  | 'isSelected'
  | 'isOwner'
  | 'isInitialized'
  | 'isFetching'
  | 'currentPage'
  | 'requestUrl'
>;
type DispatchProps = Pick<Props, 'initFetch' | 'fetchMore'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = (
  state: AppState,
  { folderId }
) => {
  const gallectionType = getGallectionSectionType(state);
  const selectedSubfolder = getSelectedSubfolder(state);
  const folder = getFolderById(state, gallectionType, folderId)!;

  let streamId;
  let subfolder;

  if (selectedSubfolder && selectedSubfolder.parentId === folderId) {
    subfolder = selectedSubfolder;
    streamId = getFolderDeviationsStreamId(gallectionType, subfolder.folderId);
  } else {
    streamId = getFolderDeviationsStreamId(gallectionType, folderId);
  }

  return {
    streamId,
    folder,
    subfolder,
    subfolderId: subfolder && subfolder.folderId,
    isSelected: getSelectedFolderId(state) === folderId,
    deviations: getDeviationsByIds(
      state,
      streamSelectors.getItems(state, streamId) || []
    ),
    isOwner: isCurrentUserProfileOwner(state),
    gallectionType: getGallectionSectionType(state),
    isInitialized: streamSelectors.isInitialized(state, streamId),
    hasMore: streamSelectors.hasMore(state, streamId) !== false,
    isFetching: streamSelectors.isFetching(state, streamId),
    justExitedDuperbrowse: !!getLastViewedItem(state),
    currentPage: getCurrentPage(state),
    requestUrl: getEnv(state).requestUrl,
  };
};

const mapDispatchToProps: MapDispatchToProps<any, OwnProps> = (
  dispatch,
  ownProps: OwnProps & StateProps
) => ({
  initFetch:
    ({ streamId, gallectionType, folderId, subfolderId }) =>
    () => {
      dispatch(
        streamWithOffset.actions.initialize({
          streamId,
          streamParams: {
            gallectionType,
            folderId: subfolderId || folderId,
          },
          hasMore: true,
          groupId: FOLDER_DEVIATIONS_STREAM_GROUP_ID,
          itemsPerFetch: DEVIATIONS_PER_FETCH_COUNT,
        })
      );
      dispatch(streamWithOffset.actions.fetch(streamId));
    },
  fetchMore:
    ({ streamId }) =>
    () =>
      dispatch(streamWithOffset.actions.fetch(streamId)),
});

// We need some state props on the dispatchers, so we return some factory dispatchers
// and then use mergeProps to create the actual dispatchers with all the props
// TODO this is all a bit odd, really this sort of thing should happen in a saga...
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = { ...ownProps, ...stateProps };
  return {
    ...props,
    initFetch: dispatchProps.initFetch(props),
    fetchMore: dispatchProps.fetchMore(props),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
