import { all, select, call, put } from 'redux-saga/effects';
import { PapiRequestDaSharedGallectionContents } from '@wix/da-papi-types';
import { ALL_FOLDER_ID, SCRAPS_FOLDER_ID } from '@wix/da-url';
import { CommentableTypes } from '@wix/da-shared-react/pkg/Comment/redux/types';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import {
  withOffset,
  selectors as streamSelectors,
} from '@wix/da-shared-react/pkg/Stream';
import { actions as threadActions } from '@wix/da-shared-react/pkg/CommentThread';
import { getProfileOwner, getIsPagedMode } from '../../selectors/users';
import { getFeaturedFolder } from '../../selectors/folders';
import { GallectionType } from '../../../types/gallection';
import { biFetchMoreFolderItems } from '../../actions/bi';
import { isApiError, requestPuppy } from '@wix/da-http-client';
import {
  getPageSize,
  getPagingMode,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import { flagEnabled } from '@wix/da-react-context/pkg/flags/redux/selectors';

function* fetchFromApi(params) {
  return yield call(
    requestPuppy,
    {
      method: 'get',
      url: `gallection/contents`,
      params,
    },
    undefined
  );
}

export default function* fetchMoreFolderDeviations(action) {
  const { streamId } = action;
  const foryou_gallery = yield select(state => flagEnabled(state, 'foryou_gallery'));
  const isPaged = yield select(getIsPagedMode);
  if (isPaged) {
    // When pagination is enabled, we need to reload the page after each click
    return;
  }

  yield put(biFetchMoreFolderItems(streamId));
  const [offset, limit, streamParams, profileOwner] = yield all([
    select(state => withOffset.selectors.getNextOffset(state, streamId)),
    select(state =>
      getPagingMode(state) === 'page'
        ? getPageSize(state)
        : streamSelectors.getItemsPerFetch(state, streamId)
    ),
    select(state => streamSelectors.getStreamParamsSafe(state, streamId)),
    select(getProfileOwner),
  ]);

  const queryParams: PapiRequestDaSharedGallectionContents = {
    username: profileOwner.user.username,
    type: streamParams.gallectionType,
    deviationid: streamParams.deviationIdSeed,
    order: foryou_gallery && streamParams.gallectionType === GallectionType.GALLERY ? "personalized" : "default",
    offset,
    limit,
  };

  if (+streamParams.folderId === ALL_FOLDER_ID) {
    queryParams.all_folder = true;
  } else if (+streamParams.folderId === SCRAPS_FOLDER_ID) {
    queryParams.scraps_folder = true;
  } else {
    queryParams.folderid = streamParams.folderId;
  }
  const response = yield call(fetchFromApi, queryParams);

  const featuredFolder = yield select(getFeaturedFolder);

  let commentableTypeId;
  if (streamParams.gallectionType === GallectionType.COLLECTION) {
    commentableTypeId = CommentableTypes.COMMENT_COLLECTION;
  } else {
    commentableTypeId = CommentableTypes.COMMENT_GALLERY;
  }
  const commentableItemId =
    !streamParams.folderId ||
      [ALL_FOLDER_ID, SCRAPS_FOLDER_ID].includes(streamParams.folderId)
      ? featuredFolder.folderId
      : streamParams.folderId;

  if (response && !isApiError(response)) {
    const deviations = response.results;
    const { hasMore, nextOffset, comments } = response;
    const { result, entities } = normalize(deviations, [deviationSchema]);

    yield put(
      withOffset.actions.fetchSuccess({
        streamId,
        hasMore,
        nextOffset,
        items: result,
        entities,
      })
    );
    if (comments) {
      yield put(
        threadActions.initializeThread({
          thread: {
            commentableTypeId,
            commentableItemId,
            items: comments.thread,
            total: comments.total,
            hasMore: comments.hasMore,
            canPostComment: comments.canPostComment,
            cursor: comments.cursor,
          },
        })
      );
    }
  }

  // TODO error catching
}
