import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { GallectionResourceType } from '@wix/da-shared-react/pkg/types/gallection';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import { ALL_FOLDER_ID } from '@wix/da-url';
import { PageViewBiEvent } from '@wix/da-bi/pkg/events';
import { AppState } from '../../types/store';
import { getActiveSection } from './sections';
import { getSelectedFolderId } from './gallectionSection';
import { getProfileOwnerUser } from './users';
import { getTimePeriod, getHasCalculatedStats } from './statsSection';
import { PostsType } from '../../types/posts';
import history from '@wix/da-shared-react/pkg/redux/routing/history';
import { hasFirstTimeFlag } from '@wix/da-shared-react/pkg/redux/firstTimeFlags/selectors';
import { FirstTimeFlag } from '@wix/da-shared-react/pkg/redux/firstTimeFlags';
import { isGroup } from '@wix/da-shared-react/pkg/User/helpers';

export function getBiTypeAndItemId(state: AppState): {
  typeid: number;
  itemid: number;
} {
  const owner = getProfileOwnerUser(state);
  const defaultsForUserProfiles = {
    typeid: isGroup(owner) ? ResourceType.GROUP : ResourceType.USER,
    itemid: owner?.userId,
  };
  const section = getActiveSection(state);

  switch (section) {
    case 'favourites':
    case 'gallery':
      const itemid = getSelectedFolderId(state);

      if (!itemid || itemid === ALL_FOLDER_ID) {
        return defaultsForUserProfiles;
      }

      const typeid: number =
        section === 'gallery'
          ? GallectionResourceType.GALLERY
          : GallectionResourceType.COLLECTION;

      return {
        typeid,
        itemid,
      };
    default:
      return defaultsForUserProfiles;
  }
}

export function getPageViewBiData(
  state: AppState
): Omit<PageViewBiEvent, 'evid'> {
  const owner = getProfileOwnerUser(state);
  const defaultsForUserProfiles = {
    view: isGroup(owner) ? 'group' : 'profile',
    component: getBiComponent(state),
    ...getBiTypeAndItemId(state),
    metadata: state.pageInfo.biMetadata,
  };
  const section = getActiveSection(state);

  switch (section) {
    case 'stats':
      return {
        ...defaultsForUserProfiles,
        time_period: getTimePeriod(state),
        non_core_view: getCurrentUserHasCore(state) ? 0 : 1,
        is_empty_state_view: getHasCalculatedStats(state) ? 0 : 1,
      };
    case 'home':
      let metadata = [
        {
          profile_sections_preopen: hasFirstTimeFlag(
            state,
            FirstTimeFlag.IsCustomboxNotDiscovered
          )
            ? 'yes'
            : 'no',
        },
      ];
      if (defaultsForUserProfiles.metadata) {
        try {
          const decoded = JSON.parse(defaultsForUserProfiles.metadata ?? '[]');
          metadata = [...metadata, ...decoded];
        } catch (e) {}
      }
      return {
        ...defaultsForUserProfiles,
        metadata: JSON.stringify(metadata),
      };
    default:
      return defaultsForUserProfiles;
  }
}

export function getBiComponent(
  state: AppState,
  section = getActiveSection(state)
) {
  if (section === 'posts') {
    const subsection = (history?.location?.pathname || '').split('/').pop();
    switch (subsection) {
      case PostsType.JOURNALS:
      case PostsType.STATUSES:
      case PostsType.POLLS:
      case PostsType.DRAFTS:
        return subsection;
      default:
        return section;
    }
  }
  return section;
}
